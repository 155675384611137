import { AppState } from "../root-reducer";

export const selectUsersAutocomplete = (state: AppState) => state.appReducer.users;

export const selectRegions = (state: AppState) => state.appReducer.regions;
export const selectTypes = (state: AppState) => state.appReducer.types;
export const selectDirectories = (state: AppState) => state.appReducer.directories;
export const selectListingLevels = (state: AppState) => state.appReducer.levels;
export const selectSocialLinks = (state: AppState) => state.appReducer.socialLinks;

export const selectMetadataPublic = (state: AppState) => state.appReducer.metadataPublic;
export const selectMetadataAdmin = (state: AppState) => state.appReducer.metadataAdmin;
export const selectSnackMessage = (state: AppState) => state.appReducer.snackMessage;

export const selectErrors = (state: AppState) => state.appReducer.errors;
export const selectErrorsTitle = (state: AppState) => state.appReducer.errorsTitle;
export const selectErrorsDescription = (state: AppState) => state.appReducer.errorsDescription;





