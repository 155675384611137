import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiListing from "../../../Api/ApiListing";
import { ServerError } from "../../../Api/Api";
import { ListingDTO } from "../../../Api/Model";
import { listingValidationErrorAction } from "../../../Store/App/actions";
import { listingUpdatedAction } from "../../../Store/Dashboard/actions";
import ListingActivatedDialog from "../../UI/ListingActivatedDialog";
import ActionButton, { QuickAction } from "../../UI/ActionButton";
import CopyListingDialog from "../CopyListingDialog";
import DeleteListingDialog from "../DeleteListingDialog";
import InsightsIcon from '@mui/icons-material/Insights';
import { selectUser } from "../../../Store/User/selectors";
import AnalyticsDialog from "../AnalyticsDialog";

interface Props {
    listing: ListingDTO;
    onListingUpdated: (listing: ListingDTO) => void;
    opened?: number;
    onOpen: (pkListing?: number) => void;
}

const ListingsActionButton = (props: Props) => {
    const { listing } = props;
    const { pkListing, isVisible } = listing;

    const dispatch = useDispatch();
    const authUser = useSelector(selectUser);
    const history = useHistory();
    const open = props.opened === listing.pkListing;

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [copyOpen, setCopyOpen] = useState(false);
    const [showActivatedDialog, setShowActivatedDialog] = useState(false);
    const [analyticsOpen, setAnalyticsOpen] = useState(false);

    const onEdit = () => {
        history.push("/wizard/" + pkListing);
    };

    const toggle = () => {
        props.onOpen(open ? undefined : listing.pkListing);
    };

    const onActiveChange = () => {
        ApiListing.updateListingVisibility(pkListing, !!!isVisible)
            .then((res) => {
                let copy = { ...listing };
                copy.isVisible = res.details.isVisible;
                props.onListingUpdated(copy);
                toggle();
                if (copy.isVisible) {
                    setShowActivatedDialog(true);
                }
            })
            .catch((err) => {
                dispatch(listingValidationErrorAction((err as ServerError)?.errors));
            })
            .finally(() => {
                toggle();
            });
    };

    const onConfirmUpdateClick = async () => {
        try {
            let dto = await ApiListing.listingReviewed(pkListing);
            dispatch(listingUpdatedAction(dto));
        } catch (err) {
        } finally {
            toggle();
        }
    };
    
    const onAnalyticsClick = () => {
        setAnalyticsOpen(true);
        toggle();
    };

    return (
        <>
            <ActionButton open={open} onToggle={toggle}>
                <QuickAction
                    label={isVisible ? "Deactivate listing" : "Activate listing"}
                    icon={isVisible ? <CloseIcon /> : <CheckIcon />}
                    onClick={onActiveChange}
                />
                <QuickAction icon={<EditIcon />} label={"Edit listing"} onClick={onEdit} />
                <QuickAction icon={<FileCopyIcon />} label={"Copy listing"} onClick={() => setCopyOpen(true)} />
                <QuickAction icon={<CachedIcon />} label={"Confirm update"} onClick={onConfirmUpdateClick} />
                <QuickAction icon={<DeleteIcon />} label={"Delete listing"} onClick={() => setDeleteOpen(true)} />
                {authUser?.userLevel === 2 && <QuickAction icon={<InsightsIcon />} label={"Analytics"} onClick={onAnalyticsClick} />}
            </ActionButton>

            {deleteOpen && <DeleteListingDialog listing={listing} onClose={() => setDeleteOpen(false)} />}
            {copyOpen && <CopyListingDialog listingId={listing.pkListing} listingTypeId={listing.fkListingType} onClose={() => setCopyOpen(false)} />}
            {showActivatedDialog && <ListingActivatedDialog onClose={() => setShowActivatedDialog(false)} />}
            {analyticsOpen && (
                <AnalyticsDialog
                    listingId={listing.pkListing}
                    onClose={() => setAnalyticsOpen(false)}
                />
            )}
        </>
    );
};

export default ListingsActionButton;
