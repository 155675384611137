import { AppState } from '../root-reducer';

export const selectListings = (state: AppState) => state.dashboardReducer.listings;

export const selectListingsLoading = (state: AppState) => state.dashboardReducer.listingsLoading;

export const selectListingsFilter = (state: AppState) => state.dashboardReducer.listingsFilter;

export const selectListingsTableSetting = (state: AppState) => state.dashboardReducer.listingsTableSetting;


export const selectMyWebsiteSelectedLicense = (state: AppState) => state.dashboardReducer.myWebsiteLicense;

export const selectMyWebsiteListings = (state: AppState) => state.dashboardReducer.myWebsiteListings;

export const selectMyWebsiteListingsLoading = (state: AppState) => state.dashboardReducer.myWebsiteListingsLoading;


export const selectApprovalsSelectedLicense = (state: AppState) => state.dashboardReducer.approvalsLicense;

export const selectApprovalsListings = (state: AppState) => state.dashboardReducer.approvalsListings;

export const selectApprovalsListingsLoading = (state: AppState) => state.dashboardReducer.approvalsListingsLoading;

export const selectApprovalsFilter = (state: AppState) => state.dashboardReducer.approvalsFilter;

export const selectApprovalsExpired = (state: AppState) => state.dashboardReducer.approvalsExpired;

export const selectApprovalsTableSetting = (state: AppState) => state.dashboardReducer.approvalsTableSetting;
