import Axios, { AxiosInstance } from "axios";
import Config from "../config";

export interface ServerError {
    message: string;
    statusCode?: number;
    errors?: string[];
    data?: any;
}

export default class Api {
    static axios: AxiosInstance;

    public static _initialize() {
        this.axios = Axios.create({
            baseURL: Config.API_URL,
        });
        // Add a response interceptor
        this.axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
                let serverError: ServerError = {
                    message: "Unknown error",
                };
                console.error("Interceptor:", error.response);
                if (error.response) {
                    serverError.statusCode = error.response.status;
                    serverError.message = error.response.statusText;
                    serverError.data = error.response.data;
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.data && error.response.data.error) {
                        serverError.errors = [error.response.data.error];
                    } else if (error.response.data.errors) {
                        serverError.errors = error.response.data.errors;
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    serverError.message = "No response from server";
                } else {
                    // Something happened in setting up the request that triggered an Error
                    serverError.message = "Error: " + error.message;
                }

                // Any status codes that falls outside the range of 2xx cause this function to trigger
                return Promise.reject(serverError);
            }
        );
    }
}

Api._initialize();