import { Box, Dialog } from '@mui/material';
import * as React from 'react';
import Loading from './Loading';

interface Props {
    open?: boolean;
    text?: string;
}

const LoadingDialog = (props: Props) => {
    const { open, text } = props;
    return (
        <Dialog
            open={open === undefined ? true : open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box padding="15px">
                <Loading text={text}/>
            </Box>
        </Dialog>
    );
};

export default LoadingDialog;