export interface LoginDTO {
  token: string;
  expiryAt: number;
  user: UserProfileDTO;
}

export interface UserIndustry {
  fkUserID: number;
  fkUserIndustryID: number;
  industryName: string;
  pkUserIndustryLinkID: number;
}

export interface UserDTO {
  id: number;
  userName: string;
  company: string;
  displayName: string;
  userType: string;
  clientName: string;
  active: boolean;
  state: string;
  stateId: number;
  countryId: number;
  created: string;
  lastLogin: string;
  firstName: string;
  listings: number;
  industriesIds: number[];
}

export interface UserProfileDTO extends UserDTO {

  firstName: string;
  lastName: string;
  userLevel: number;
  company: string;
  address: string;
  suburb: string;
  postCode: string;
  country: string;
  telephone: string;
  fax: string;
  userType: UserType;
  clientId: number;
  industries: UserIndustry[];
  contentLicenses: UserClientLicenseDTO[];
  approvalLicenses: UserClientLicenseDTO[];
  notes?: string;
}



export enum ApprovalType {
  All = "All",
  Approved = "Approved",
  NotApproved = "Not Approved",
}

export type ListingApprovalStatus = "all" | "approved" | "unapproved";

export enum Status {
  All = "all",
  Active = "active",
  Inactive = "inactive",
}

export type UserType = "All" |  "Standard" |  "Administrator";

export enum ExclusionType {
  All = "All",
  Included = "Included",
  Excluded = "Excluded",
}

export enum ScoopStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  UNAPPROVED = "Unapproved",
}

export interface ListingWebsiteDTO {
  pkListing: number;
  txtListingTypeName: string;
  txtListingName: string;
  dtCreated: string;
  isVisible: boolean;
  txtListingNameSEO: string;
  dtLastUpdated: string;
  isApproved: boolean;
  excluded: boolean;
  listingURL: string;
  fkListingLevel: number;
  listingLevel: number;
  fkClientListingLevel: number;
  clientListingLevel: number;
  fkListingType: number;
  allowEvents: boolean;
  pkClientListingLevel: number;
  fkRegion: number;
  listingUserDisplayName: string;
  listingPreviewURL: string;
}

export interface ListingApprovalDTO {
  listingID: number;
  listingName: string;
  listingNameSEO: string;
  regionID: number;
  directoryID: number;
  listingTypeID: number;
  listingTypeName: string;
  fkListingLevelDefault: number;
  eventEndDate: string;
  isVisible: boolean;
  dtLastUpdated: string;
  fkListingLevel: number;
  listingUserID: number;
  listingUserFullname: string;
  listingUserDisplayName: string;
  listingPreviewURL: string;
  listingLocalistaURL: string;
 
  isApproved: boolean;
  dtApproved?: string;
  fkUserApprovedBy?: number;
  userApprovedBy?: string;

  allowListingApprove: boolean;
  allowListingEdit: boolean;
  allowPremiumLevelEdit: boolean;
  allowDefaultLevelEdit: boolean;
  defaultLevelEditStart: number;
  defaultLevelEditEnd: number;

  regionName: string;
  directoryName: string;
  allowEvents: boolean;
  dtCreated: string;
  excluded: boolean;
  listingURL: string;
  levelStartDate: string;
  levelEndDate: string;
  fkClientListingLevel: number;
  pkClientListingLevel: number;
  listingUserEmail: string;
  isOwnListing: boolean;
  isGroupUserListing: boolean;
}

export interface CreateImageDTO {
  listing: ListingFullDTO;
  uploadUrl: string;
}

export interface ListingDTO {
  pkListing: number;
  fkListingType: number;
  txtListingTypeName: string;
  isVisible: boolean;
  isApproved: boolean;
  txtListingName: string;
  txtListingNameSEO: string;
  intPriority: number;
  defaultListingLevel: number;
  premiumListingLevel: number;
  defaultDisplayPriority: string;
  premiumDisplayPriority: string;
  dtCreated: string;
  dtApproved?: string;
  dtLastUpdated?: string;
  dtLastChecked?: string;
  dtEventFrom?: string;
  dtEventTo?: string;
  previewUrl?: string;
}

export interface ListingDetailsDTO {
  pkListing: number;
  fkUser: number;
  fkRegion: number;
  fkListingType: number;
  fkState: number;
  txtListingName: string;
  txtCompanyName: string;
  txtAddress: string;
  txtSuburb: string;
  txtCity: string;
  txtPostCode: string;
  txtState: string;
  txtTelephone: string;
  txtFax: string;
  txtCountry: string;
  txtEmail: string;
  txtWeb: string;
  txtDescShort: string;
  txtTeaser: string;
  isVisible: boolean;
  dtCreated: string;
  bitUpdated: boolean;
  dtUpdated?: string;
  dtLastUpdated: string;
  dtLastChecked: string;
  bitApproved: boolean;
  dtApproved?: string;
  txtListingNameSEO: string;
  txtLocation: string;
  txtVenueLocation: string;
  txtBookingLink: string;
  txtAffiliateLink: string;
  txtTypeName: string;
  txtURLAlias: string;
  txtListingTypeSEO: string;
  allowEvents: boolean;
  allowLocations: boolean;
  bookingLinkAllowDisplay: boolean;
  bookingLinkEditAdminOnly: boolean;
  affiliateLinkAllowDisplay: boolean;
  txtRegionName: string;
  intRegionLevel: number;
  txtRegionUrlAlias: string;
  fkTodoCategory: number;
  txtUsername: string;
  userEmail: string;
  userDisplayname: string;
  fkNeedsOrganisation: number;
  pkListingLevel: number;
  txtListingLevel: string;
  intImagesAllowed: number;
  intPriority: number;
  intSubPages: number;
  fkParentListing: number;
  parentListingName: string;
  fkParentListing2: number;
  parentListingName2: string;
  fkParentListing3: number;
  parentListingName3: string;
  fkParentListing4: number;
  parentListingName4: string;
  fkParentListing5: number;
  parentListingName5: string;
  fkSocialLink1: number;
  txtSocialLink1: string;
  socialLinkDescription1: string;
  fkSocialLink2: number;
  txtSocialLink2: string;
  socialLinkDescription2: number;
  fkSocialLink3: number;
  txtSocialLink3: string;
  socialLinkDescription3: string;
  txtGoogleMapURL: string;
  latitude: number;
  longitude: number;
  bitNoMapProfile: boolean;
  bitNoMapSearch: boolean;
  bitShowImagesFirst: boolean;
  fkListingLevelDefault: number;
  defaultListingLevel: string;
  listingTypeGroupName: string;
  listingTypeGroupNameSEO: string;
  listingURL: string;
  userApprovedBy: string;
  userApprovedByDisplayname: string;
  userCheckedBy: string;
  userCheckedByDisplayname: string;
  userUpdatedBy: string;
  userUpdatedByDisplayname: string;
  listingPreviewURL: string;
  fkCountryID: number;
  countryCode: string;

  includeInSearch: boolean;
  includeInSitemaps: boolean;

  txtPageContentPub: string;
  txtAttribution: string;

}

export interface FeatureValueDTO {
  pkListingFeatureLink: number;
  fkListing: number;
  fkFeature: number;
  fkValue: number;
  txtValue: string;
}

export interface ListingFullDTO {
  details: ListingDetailsDTO;
  eventInfo: EventInfoDTO;
  eventDates: [
    {
      pkEventDatesID: number;
      fkListingID: number;
      startDate: string;
    }
  ];
  values: FeatureValueDTO[];
  levels: ListingLevelLinkDTO[];
  videos: VideoDTO[];
  images: ImageDTO[];
  files: FileDTO[];
  approval?: ListingApprovalDTO;
  openingHours?: OpeningHoursDTO;
}

export interface EventInfoDTO {
  pkEventInfo?: number;
  fkEventDateType: number;
  dtEventFrom?: string;
  dtEventTo?: string;
  pkEventDateRecurringID?: number;
  maxOccurences?: number;
  repeatFrequency?: number;
  repeatInterval?: number;
  occurenceDays?: number;
  occurenceMonths?: number;
  monthlyExactDay?: number;
  monthlyInterval?: number;
}

export interface VideoDTO {
  pkListingVideo: number;
  fkListingID: number;
  videoURL: string;
  dtCreated: string;
  dcImageUpdated: string;
}

export interface ImageDTO {
  txtIcon: string;
  txtThumbnail: string;
  txtRegular: string;
  txtLarge: string;
  txtSpecial: string;
  txtHighlight: string;
  txtAlt: string;
  txtCaption: string;
  txtCredit: string;
  isPrimary: boolean;
  imagePosition: number;
  isMap: boolean;
  isSpecial: boolean;
  txtURLAlias: string;
  txtIconURL: string;
  txtThumbnailURL: string;
  txtRegularURL: string;
  txtLargeURL: string;
  txtSpecialURL: string;
  txtHighlightURL: string;
  dtLastUpdated: string;
  pkImage: number;
  txtListingName: string;
  fkListingType: number;
  fkListing: number;
  displayCaption: string;
  displayAlt: string;
}

export interface FileDTO {
  pkListingFile: number;
  fkListing: number;
  txtTitle: string;
  txtFile: string;
  dtCreated: string;
  txtListingName: string;
  txtURLAlias: string;
  txtFileURL: string;
}

export interface MetadataDTO {
  types: ListingTypeDTO[];
  directories: ListingDirectoryDTO[];
  levels: ListingLevelDTO[];
  socialLinks: SocialLinksDTO[];
}

export interface MetadataPublicDTO {
  states: StateDTO[];
  countries: CountryDTO[];
  industries: [
    {
      pkUserIndustryID: number;
      industryName: string;
    }
  ];
}

export interface UserProfileDTO {
  id: number;
  userName: string;
}

export interface FeaturesMetadataDTO {
  features: ListingFeatureDTO[];
  categoryLimit: number;
}

export interface ListingFeatureDTO {
  allowMultiple: boolean;
  pkFeature: number;
  featureName: string;
  isCategory: boolean;
  isSearch: boolean;
  isEnumerated: boolean;
  displayOrder: number;
  helpNotes: string;
  isAdministratorOnly: boolean;
  isDateOnly: boolean;
  isNumeralOnly: boolean;
  prefix: string;
  suffix: string;
  categoryLimit: number;
  isVenueLocation: boolean;
  multipleGoogleValues: boolean;
  addGoogleValues: boolean;
  addManualValues: boolean;
  isGoogle: boolean;
  values: [
    {
      pkValue: number;
      fkFeature: number;
      txtValue: string;
      intSeqNo: number;
    }
  ];
}

export interface CountryDTO {
  countryID: number;
  name: string;
  code: string;
  iso3: string;
  isoNumeric: string;
  includeInDropdown: boolean;
}

export interface StateDTO {
  pkStateID: number;
  stateName: string;
  fkCountryID: number;
  stateAbbr: string;
  countryName: string;
  fkRegionId: number;
}


export interface RegionDTO {
  pkRegion: number;
  txtRegionName: string;
  fkParentRegion: number;
  intLevel: number;
  txtURLAlias: string;
  isSuburb: boolean;
  txtAbbreviation: string;
  latitude: number;
  longitude: number;
}

export interface Region extends RegionDTO {
  parents: number[];
  parentsNames: string[];
  parentsLabel?: string;
}

export interface ListingTypeDTO {
  pkListingType: number;
  txtTypeName: string;
  txtListingTypeSEO: string;
  vertical: string;
  allowEvents: boolean;
  allowLocations: boolean;
  allowOpeningHours: boolean;
  intDisplayOrder: number;
  pkListingTypeGroup: number;
  groupName: string;
  groupNameSEO: string;
  groupNameDisplay: string;
}

export interface ListingLevelDTO {
  pkListingLevel: number;
  txtListingLevel: string;
  intImagesAllowed: number;
  intPriority: number;
  intSubPages: number;
  dtCreated: string;
  dtDeleted: string;
  isVisible: boolean;
  decPrice: number;
  endDate?: string;
  startDate?: string;
  isScheduled?: boolean;
  isDefault?: boolean;
  displayPriority: string;
}

export interface ListingLevelLinkDTO {
  listingID: number;
  linkID?: number;
  levelID: number;
  endDate?: string;
  startDate?: string;
  label?: string;
  isScheduled?: boolean;
  isDefault?: boolean;
  isActive?: boolean;
  isLog?: boolean;
  chargebeeSubscriptionID?: string;
  chargebeeInvoiceID?: string;
  chargebeeStatus?: string;
  dtDeleted?: string;
  dtCreated?: string;
  status: 'active' | 'future' | 'past' | 'refunded' | 'cancelled' | 'nonRenewing' | '';
  endDateFormatted: string;
}

export interface HoursPeriod {
  open: {
    day: number;
    time: string;
  };
  close: {
    day: number;
    time: string;
  };

}

export interface DayHours{
  periods: HoursPeriod[];
  closed: boolean;
  open_24_hours: boolean;
  day_text: string;
  vary?: boolean;
}

export interface OpeningHoursData {
  monday: DayHours;
  tuesday: DayHours;
  wednesday: DayHours;
  thursday: DayHours;
  friday: DayHours;
  saturday: DayHours;
  sunday: DayHours;
  pubhol: DayHours;
}

export interface OpeningHoursDTO {
  id: number;
  openStatusId: number;
  data?: OpeningHoursData;
  additionalInfo: string;
  lastUpdated: string;
}

export interface ListingDirectoryDTO {
  pkListingTypeGroupId: number;
  listingTypeGroupName: string;
}

export interface SocialLinksDTO {
  pkSocialLinkID: number;
  socialLinkName: string;
}

export interface UserClientLicenseDTO {
  pkClient: number;
  pkLicense: number;
  txtClientname: string;
  txtLicenseName: string;
  isDefault: boolean;
  licenseTypeName: string;
}

export interface UserAutocompleteDTO {
  id: number;
  label: string;
}

export interface ClientDTO {
  id: number;
  name: string;
  active: boolean;
  created: string;
}

export interface MetadataAdminDTO {
  clients: ClientDTO[];
}

export interface ClientDetailsDTO {
  id: number;
  licenses: UserClientLicenseDTO[];
}

export interface MetricsDTO {
  listing: number;
  total: number;
  avgFree?: number;
  avgPremium?: number;
}

export interface PerformanceDTO {
  year: number;
  month: number;
  searches: number;
  views: MetricsDTO;
  opens: MetricsDTO;
  clicks: MetricsDTO;
  other: MetricsDTO;
}

export interface AveragesDTO {
  views?: number;
  opens?: number;
  clicks?: number;
  other?: number;
}

export interface AnalyticsDTO {
  listingId: number;
  listingName: string;
  regionId: number;
  regionName: string;
  typeId: number;
  typeName: string;
  performance: PerformanceDTO[];
  avgListing: AveragesDTO;
  avgFree: AveragesDTO;
  avgPremium: AveragesDTO;

}

export interface PremiumPlanDTO {
  itemPriceId: string;
  itemFamilyId: string;
  itemId: string;
  itemType: string;
  name: string;
  externalName: string;
  description: string;
  currencyCode: string;
  price: number;
  pricingModel: string;
  status: string;
  period: string;
  periodUnit: string;

}

export interface PremiumEstimateDTO {
  total: number;
  subTotal: number;
  amountDue: number;
  referralCode?: string;
  referralDiscount?: number;
  localistaDiscount?: number;
  quantity: number;
  tax?: number;
  credits?: number;
  currencyCode: string;
  description: string;
  planId: string;
}

export interface PremiumInvoiceDTO {
  id: string;
  total?: number;
  paid?: number;
  credits?: number;
  created: string;
}

export interface PremiumCreditNoteDTO {
  id: string;
  total?: number;
  allocated?: number;
  refunded?: number;
  available: number;
  created: string;
}

export interface PremiumDetailsDTO {
  invoices: PremiumInvoiceDTO[];
  creditNotes: PremiumCreditNoteDTO[];
}

export interface PremiumDetailsUserDTO {
  invoices: PremiumInvoiceDTO[];
  creditNotes: PremiumCreditNoteDTO[];
}

