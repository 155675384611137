import {
    TableCell,
    TableRow,
    Theme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListingLevelLinkDTO } from "../../../../Api/Model";
import { selectListing } from "../../../../Store/Wizard/selectors";
import DateUtil from "../../../../Util/DateUtil";
import EnhancedTable, { HeadCell, TableSetting } from "../../../UI/EnhancedTable";
import ScoopUtil from "../../../../Util/ScoopUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        activeRow: {
            '& td' : {
                fontWeight: "700"
            }
        },
    }),
);

interface ListingLevelTablePros {
    createActionButton: (ll: ListingLevelLinkDTO) => JSX.Element;
}

const ListingLevelTable = (props: ListingLevelTablePros) => {
    const classes = useStyles();

    const listing = useSelector(selectListing);

    const [levels, setLevels] = useState<ListingLevelLinkDTO[]>([]);

    useEffect(() => {
        setLevels(listing?.levels?.sort((a,b) => ScoopUtil.compareListingLevelLinks(b,a)) || []);
    }, [listing])

    const [tableSetting, setTableSetting] = useState<TableSetting>({
        page: 0,
        pageSize: 5,
        order: "desc",
        orderBy: "",
    });



    const headCells: HeadCell[] = [
        { id: "startDate", disablePadding: true, label: "Start Date" },
        { id: "endDateFormatted", disablePadding: true, label: "End Date" },
        { id: "label", disablePadding: true, label: "Listing Level" },
        { id: "status", disablePadding: true, label: "Status" },
        { id: "createdBy", disablePadding: true, label: "Created By" },
        { id: "actions", disablePadding: true, label: "Actions" },
    ];

    const mapRow = (ll: ListingLevelLinkDTO, index: number): JSX.Element => {
        const isActive = ll.isActive && ll.status === 'active';
        return (
            <TableRow className={isActive ? classes.activeRow : undefined} hover tabIndex={-1} key={ll.linkID}>
                <TableCell>{DateUtil.formatDateFromString(ll.startDate)}</TableCell>
                <TableCell>{ll.endDateFormatted}</TableCell>
                <TableCell>{ll.label}</TableCell>
                <TableCell title={ll.chargebeeStatus}>{ll.status}</TableCell>
                <TableCell>{(ll.chargebeeSubscriptionID || ll.chargebeeInvoiceID) ? 'User' : ll.isDefault ? 'Default' : 'Localista'}</TableCell>
                <TableCell>{props.createActionButton(ll)}</TableCell>
            </TableRow>
        );
    };

    return (
        <EnhancedTable
            headCells={headCells}
            rows={levels}
            mapRow={mapRow}
            tableSetting={tableSetting}
            onTableSettingChange={setTableSetting}
            hidePaginationTop={true}
        />
    );
};

export default ListingLevelTable;