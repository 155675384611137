import { TableCell, TableRow } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import ApiPremium from "../../../Api/ApiPremium";
import { PremiumCreditNoteDTO, PremiumDetailsUserDTO } from "../../../Api/Model";
import DateUtil from "../../../Util/DateUtil";
import ScoopUtil from "../../../Util/ScoopUtil";
import EnhancedTable, { HeadCell, TableSetting } from "../../UI/EnhancedTable";
import CreditNoteLink from "./CreditNoteLink";

interface Props {}

const CREDIT_NOTE_HEAD_CELLS: HeadCell[] = [
    { id: "created", disablePadding: true, label: "Created", align: "left" },
    { id: "total", disablePadding: true, label: "Amount" },
    { id: "available", disablePadding: true, label: "Available" },
    { id: "allocated", disablePadding: true, label: "Allocated" },
    { id: "id", disablePadding: true, label: "Link" },
];

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '10px'
    }
}));

const UserPremiumDetailsDialog = (props: Props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [tableSetting, setTableSetting] = useState<TableSetting>({
        page: 0,
        pageSize: 5,
        order: "asc",
        orderBy: "status",
    });
    const [content, setContent] = useState<PremiumDetailsUserDTO>(undefined);

    useEffect(() => {
        if (open) {
            ApiPremium.getPremiumDetailsUser().then((res) => setContent(res));
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const mapRowCreditNote = (i: PremiumCreditNoteDTO, index: number): JSX.Element => {
      return (
          <TableRow hover tabIndex={-1} key={i.id}>
              <TableCell>{DateUtil.formatDateFromString(i.created)}</TableCell>
              <TableCell align="right">{ScoopUtil.formatPriceInCents(i.total)}</TableCell>
              <TableCell align="right">{ScoopUtil.formatPriceInCents(i.available)}</TableCell>
              <TableCell align="right">{ScoopUtil.formatPriceInCents(i.allocated)}</TableCell>
              <TableCell><CreditNoteLink chargebeeCreditNoteId={i.id}/></TableCell>
          </TableRow>
      );
  };

    return (
        <>
            <Button color="primary" variant="contained" className={classes.button}  onClick={handleOpen}>
                Show Premium Details
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">User Premium Details</DialogTitle>
                <DialogContent>
                <div>Available credits</div>
                    <EnhancedTable
                        headCells={CREDIT_NOTE_HEAD_CELLS}
                        rows={content?.creditNotes || []}
                        mapRow={mapRowCreditNote}
                        tableSetting={tableSetting}
                        onTableSettingChange={setTableSetting}
                        hidePaginationTop={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserPremiumDetailsDialog;
