import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiUsers from "../../../Api/ApiUsers";
import { Status, UserDTO } from "../../../Api/Model";
import { actionFetchUsers, actionUserCreated } from "../../../Store/Users/actions";
import { selectUsers, selectUsersFilter, selectUsersLoading } from "../../../Store/Users/selectors";
import AddUpdateUserDialog from "./AddUpdateUserDialog";
import { UserFormValues } from "../../UI/UserForm";
import HeaderActions from "../HeaderActions";
import HeaderContainer from "../HeaderContainer";
import HeaderTitle from "../HeaderTitle";
import DataTable from "./DataTable";
import Filters from "./Filters";
import TableNoData from "./TableNoData";
import UpdateUserDialog from "./UpdateUserDialog";

const Users = () => {
    const dispatch = useDispatch();
    const [createUser, setCreateUser] = useState(false);

    const users = useSelector(selectUsers);
    const loading = useSelector(selectUsersLoading);
    // const error = useSelector(selectUsersError);
    const filter = useSelector(selectUsersFilter);

    const [filtered, setFiltered] = useState<UserDTO[]>([]);

    useEffect(() => {
        const { username, company, displayName, status, stateId, userType, clientName, industry } = filter;
        let filteredTemp: UserDTO[] = [];
        if (users?.length > 0) {
            filteredTemp = users.filter((u) =>
                status === Status.Active ? u.active : status === Status.Inactive ? !u.active : true
            );
            if (username?.length > 0) {
                let filterKeyword = username.toLocaleLowerCase();
                filteredTemp = filteredTemp.filter((u) => u.userName.toLowerCase().includes(filterKeyword));
            }
            if (company?.length > 0) {
                let filterKeyword = company.toLocaleLowerCase();
                filteredTemp = filteredTemp.filter((u) => u.company.toLowerCase().includes(filterKeyword));
            }
            if (displayName?.length > 0) {
                let filterKeyword = displayName.toLocaleLowerCase();
                filteredTemp = filteredTemp.filter((u) => u.displayName.toLowerCase().includes(filterKeyword));
            }
            if (clientName?.length > 0) {
                filteredTemp = filteredTemp.filter((u) => u.clientName.toLowerCase().includes(clientName));
            }
            if (userType !== "All") {
                filteredTemp = filteredTemp.filter(u => u.userType === userType);
            }
            if(stateId > 0) {
                filteredTemp = filteredTemp.filter(u => u.stateId === stateId);
            }
            if(industry > 0) {
                filteredTemp = filteredTemp.filter(u => u.industriesIds.includes(industry))
            }

        }
        setFiltered(filteredTemp);
    }, [users, filter]);

    const handleClickNewUser = () => setCreateUser(true);

    const handleSave = async (values: UserFormValues) => {
        try {
            const created = await ApiUsers.createUserAdmin(values);
            dispatch(actionUserCreated(created));
            setCreateUser(false);
        } catch (err) {
            //todo
        }
    };

    const refresh = () => {
        dispatch(actionFetchUsers());
    };

    return (
        <React.Fragment>
            <HeaderContainer>
                <HeaderTitle
                    title={"Users"}
                    // markdownHelp={"/assets/help/MyListings.md"}
                />
                <HeaderActions>
                    <Button disabled={loading} variant="contained" color="primary" onClick={handleClickNewUser}>
                        Create user
                    </Button>
                    <Button
                        style={{ marginLeft: "10px" }}
                        variant="contained"
                        color="primary"
                        onClick={refresh}
                        disabled={loading}
                    >
                        <RefreshIcon /> Refresh
                    </Button>
                </HeaderActions>
            </HeaderContainer>

            <Grid container spacing={1}>
                <Filters />
                {loading && (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {!loading && filtered.length > 0 && <DataTable list={filtered} />}
                    {!loading && filtered.length > 0 && filtered.length === 0 && <TableNoData />}
                </Grid>
            </Grid>
            <UpdateUserDialog />
            <AddUpdateUserDialog open={createUser} onClose={() => setCreateUser(false)} onSave={handleSave} />
        </React.Fragment>
    );
};

export default Users;
