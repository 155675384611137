import makeStyles from '@mui/styles/makeStyles';
import React, { CSSProperties } from 'react';


const useStyles = makeStyles((theme) => ({
    tabPanel: {
        width: '100%'
    }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    style?: CSSProperties;
}

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, style, ...other } = props;

    return (
        <div
            style={style}
            className={classes.tabPanel}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

export default TabPanel;