import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import ApiPremium from "../../../../Api/ApiPremium";
import { ListingLevelLinkDTO, PremiumDetailsDTO, PremiumInvoiceDTO } from "../../../../Api/Model";
import DateUtil from "../../../../Util/DateUtil";
import ScoopUtil from "../../../../Util/ScoopUtil";
import EnhancedTable, { HeadCell, TableSetting } from "../../../UI/EnhancedTable";
import InvoiceLink from "./InvoiceLink";

interface Props {
    link: ListingLevelLinkDTO;
    onClose: () => void;
}

const INVOICE_HEAD_CELLS: HeadCell[] = [
    { id: "created", disablePadding: true, label: "Created", align: "left" },
    { id: "total", disablePadding: true, label: "Amount" },
    { id: "credit", disablePadding: true, label: "Credits" },
    { id: "paid", disablePadding: true, label: "Paid" },
    { id: "id", disablePadding: true, label: "Link" },
];

const ListingLevelDetailsDialog = (props: Props) => {
    const { link } = props;
    const [tableSetting, setTableSetting] = useState<TableSetting>({
        page: 0,
        pageSize: 5,
        order: "asc",
        orderBy: "status",
    });

    const [content, setContent] = useState<PremiumDetailsDTO>(undefined);

    useEffect(() => {
        ApiPremium.getPremiumDetails(link.listingID, link.linkID).then((res) => setContent(res));
    }, [link]);

    const handleClose = () => {
        props.onClose();
    };

    const mapRowInvoice = (i: PremiumInvoiceDTO, index: number): JSX.Element => {
        return (
            <TableRow hover tabIndex={-1} key={i.id}>
                <TableCell>{DateUtil.formatDateFromString(i.created)}</TableCell>
                <TableCell align="right">{ScoopUtil.formatPriceInCents(i.total)}</TableCell>
                <TableCell align="right">{ScoopUtil.formatPriceInCents(i.credits)}</TableCell>
                <TableCell align="right">{ScoopUtil.formatPriceInCents(i.paid)}</TableCell>
                <TableCell><InvoiceLink chargebeeInvoiceID={i.id}/></TableCell>
            </TableRow>
        );
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{link.label}</DialogTitle>
                <DialogContent>
                    <div>Related Invoices</div>
                    <EnhancedTable
                        headCells={INVOICE_HEAD_CELLS}
                        rows={content?.invoices || []}
                        mapRow={mapRowInvoice}
                        tableSetting={tableSetting}
                        onTableSettingChange={setTableSetting}
                        hidePaginationTop={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ListingLevelDetailsDialog;
