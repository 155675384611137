import Grid from "@mui/material/Grid";
import { LatLng } from "leaflet";
import React, { useEffect, useState } from "react";
import { ListingFeatureDTO } from "../../../../Api/Model";
import { FormFeaturesValue } from "../form-values";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import FeatureInput from "./FeatureInput";

interface InputTypes {
    google: ListingFeatureDTO[];
    textFields: ListingFeatureDTO[];
    features: ListingFeatureDTO[];
    adminOnly: ListingFeatureDTO[];
}

const DEFAULT_INPUT_TYPES: InputTypes = { textFields: [], features: [], adminOnly: [], google: [] };

interface Props {
    isAdmin: boolean;
    disabled: boolean;
    typeFeatures: ListingFeatureDTO[];
    values: FormFeaturesValue[];
    onChange: (features: FormFeaturesValue[]) => void;
    location?: LatLng;
}

const FeaturesForm = (props: Props) => {
    const { disabled, values, typeFeatures, isAdmin, onChange, location } = props;

    const [inputs, setInputs] = useState<InputTypes>(DEFAULT_INPUT_TYPES);

    useEffect(() => {
        if (typeFeatures) {
            let standardFeatures = typeFeatures.filter(f => !f.isAdministratorOnly && !f.isVenueLocation && !f.isGoogle)
            setInputs({
                textFields: standardFeatures.filter(f => !f.allowMultiple && !f.isEnumerated),
                features: standardFeatures.filter(f => f.allowMultiple || f.isEnumerated),
                adminOnly: typeFeatures.filter((f) => !f.isVenueLocation && f.isAdministratorOnly),
                google: typeFeatures.filter(f => f.isGoogle && !f.isVenueLocation && !f.isAdministratorOnly)
            });
        } else {
            setInputs(DEFAULT_INPUT_TYPES);
        }
    }, [typeFeatures]);

    return (
        <FormGrid>
            {inputs.textFields.length > 0 && (
                <SectionGrid title={"Text fields"}>
                    <Grid container item xs={12} spacing={2}>
                        {inputs.textFields.map((f) => (
                            <FeatureInput
                                disabled={disabled}
                                key={`feature_input_${f.pkFeature}`}
                                feature={f}
                                values={values}
                                onChange={onChange}
                            />
                        ))}
                    </Grid>
                </SectionGrid>
            )}
            {inputs.google.length > 0 && (
                <SectionGrid title={"Google Lookup Features"}>
                    <Grid container item xs={12} spacing={2}>
                        {inputs.google.map((f) => (
                            <FeatureInput
                                disabled={disabled}
                                key={`feature_input_${f.pkFeature}`}
                                feature={f}
                                values={values}
                                onChange={onChange}
                                location={location}
                            />
                        ))}
                    </Grid>
                </SectionGrid>
            )}
            {inputs.features.length > 0 && (
                <SectionGrid title={"Standard Features"}>
                    <Grid container item xs={12} spacing={2}>
                        {inputs.features.map((f) => (
                            <FeatureInput
                                disabled={disabled}
                                key={`feature_input_${f.pkFeature}`}
                                feature={f}
                                values={values}
                                onChange={onChange}
                            />
                        ))}
                    </Grid>
                </SectionGrid>
            )}
            {isAdmin && (
                <SectionGrid title={"Administrator-users only"}>
                    <Grid container item xs={12} spacing={2}>
                        {inputs.adminOnly.map((f) => (
                            <FeatureInput
                                disabled={disabled}
                                key={`feature_input_${f.pkFeature}`}
                                feature={f}
                                values={values}
                                onChange={onChange}
                            />
                        ))}
                    </Grid>
                </SectionGrid>
            )}
        </FormGrid>
    );
};

export default FeaturesForm;
