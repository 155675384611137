import DeleteIcon from '@mui/icons-material/Delete';
import { StaticDatePicker } from '@mui/lab';
import PickersDay from '@mui/lab/PickersDay';
import { Alert, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { addYears } from 'date-fns';
import React from 'react';
import DateUtil from '../../../../Util/DateUtil';
import { FormDatesValues } from '../form-values';
import SectionGrid from '../SectionGrid';

const useStyles = makeStyles((theme) => ({
    calendar: {
    },
    deleteButton: {
        padding: '0px 12px'
    }
}));

const today = new Date();
const maxDate = addYears(today, 2);

interface Props {
    disabled: boolean;
    values: FormDatesValues;
    onChange: (values: FormDatesValues) => void;
}


const PickDatesForm = (props: Props) => {
    const { disabled, values, onChange } = props;
    const classes = useStyles();

    const onDateClick = (date: Date) => {
        const value = DateUtil.formatDateString(date);
        let updated: string[] = [];
        if (values.dates.includes(value)) {
            updated = values.dates.filter(d => d !== value);

        } else {
            updated = [...values.dates, value];
            updated = updated.sort((a, b) => a.localeCompare(b));
        }
        onUpdate(updated);
    }

    const onDeleteClick = (date: string) => {
        let updated = values.dates.filter(d => d !== date);
        onUpdate(updated);
    }

    const onUpdate = (dates: string[]) => {
        let update: FormDatesValues = {
            pickerType: 0,
            fkEventDateType: dates.length === 1 ? 1 : 3,
            dates: dates
        };
        if(dates.length > 0) {
            update.dtEventFrom = `${dates[0]}T00:00:00`;
            update.dtEventTo = `${dates[dates.length - 1]}T00:00:00`;
        }
        onChange(update)
    }

    return <>
        <SectionGrid title='Step 2: Select dates'>
            <StaticDatePicker
                disabled={disabled}
                minDate={today}
                maxDate={maxDate}
                className={classes.calendar}
                disablePast
                allowSameDateSelection
                displayStaticWrapperAs="desktop"
                showDaysOutsideCurrentMonth
                value={null}
                onChange={onDateClick}
                renderInput={(props) => <TextField {...props} />}
                renderDay={(day, value, DayComponentProps) => {
                    DayComponentProps.selected = values.dates.includes(DateUtil.formatDateString(day));
                    return (
                        <PickersDay {...DayComponentProps} />
                    );
                }}
            />
        </SectionGrid>

        <SectionGrid title={'Selected dates'}>
            {values.dates.length === 0 && <Alert severity="info">No date has been selected.</Alert>}
            {values.dates.map(d => <Grid key={`date_${d}`} item xs={4} sm={4} md={4} lg={3}>
                {d}{!disabled && <IconButton
                onClick={() => onDeleteClick(d)}
                className={classes.deleteButton}
                edge="end"
                aria-label="delete"
                size="large">
                    <DeleteIcon />
                </IconButton>}
            </Grid>
            )}

        </SectionGrid>
    </>;
};

export default PickDatesForm;