import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0 0 5px 0"
    }
}));

interface Props {
    children: ReactNode;
}

const HeaderContainer = (props: Props) => {
    const classes = useStyles();

    return (
        <Grid container justifyContent="space-between" className={classes.root}>
          { props.children }
        </Grid>
    );
}

export default HeaderContainer;