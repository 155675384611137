import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useDispatch } from 'react-redux';
import ApiListing from '../../../../Api/ApiListing';
import { ListingLevelLinkDTO } from '../../../../Api/Model';
import { updateListingSuccessAction } from '../../../../Store/Wizard/actions';

interface Props {
  pkListing: number;
  licenseId: number;
  levelLink: ListingLevelLinkDTO;
  onClose: () => void;
}

const DeleteLevelAdminDialog = (props: Props) => {

  const { levelLink, pkListing, licenseId } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    props.onClose();
  }

  const handleDelete = async () => {
    let res = await ApiListing.deleteListingLevel(licenseId, pkListing, levelLink.linkID)
    dispatch(updateListingSuccessAction(res))
    props.onClose();
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete listing</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete <strong>{levelLink.label}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Do Not Delete
          </Button>
        <Button onClick={handleDelete} color="primary" >
          Delete
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteLevelAdminDialog;