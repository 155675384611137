import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApiPremium, { BodyPremiumListing } from "../../../../Api/ApiPremium";
import { snackMessageCaughtErrorAction } from "../../../../Store/App/actions";

interface Props {
    listingId?: number;
    appliedReferralCode?: string;
    constructPremiumListingBody?: (referralCode: string) => BodyPremiumListing;
    onReferralCodeUpdate: (referralCode?: string) => void;
}

const ReferralCodeInput = (props: Props) => {
    const dispatch = useDispatch();

    const { listingId, appliedReferralCode, constructPremiumListingBody, onReferralCodeUpdate } = props;
    const [referralCode, setReferralCode] = useState<string>("");

    const reset = () => {
        onReferralCodeUpdate(undefined);
        setReferralCode("");
    };

    const apply = () => {
        const body = constructPremiumListingBody(referralCode);
        ApiPremium.getEstimate(listingId, body)
            .then((res) => {
                onReferralCodeUpdate(referralCode);
            })
            .catch((err) => {
                if (appliedReferralCode) {
                    onReferralCodeUpdate();
                }
                dispatch(snackMessageCaughtErrorAction(err));
            });
    };

    const clearMode = appliedReferralCode !== undefined;

    return (
        <>
            <Grid item xs={6}>
                <TextField
                    disabled={clearMode}
                    name="referralcode"
                    id="referralcode"
                    label="Referral Code"
                    placeholder="Referral Code"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" fullWidth onClick={clearMode ? reset : apply}>
                    {clearMode ? "Clear" : "Apply"}
                </Button>
            </Grid>
        </>
    );
};

export default ReferralCodeInput;
