import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiListing from '../../Api/ApiListing';
import { snackMessageAction } from '../../Store/App/actions';
import { selectTypes } from '../../Store/App/selectors';
import { actionApprovalsRefresh, fetchMyListingsAction } from '../../Store/Dashboard/actions';
import { selectUser } from '../../Store/User/selectors';
import { updateListingSuccessAction } from '../../Store/Wizard/actions';

interface Props {
  listingId: number;
  listingTypeId: number;
  licenseId?: number;
  onClose: () => void;
}

const CopyListingDialog = (props: Props) => {

  const { listingId, listingTypeId, licenseId } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const types = useSelector(selectTypes);
  const user = useSelector(selectUser)

  const [listingType, setListingType] = useState(listingTypeId);
  const [duplicateImages, setDuplicateImages] = useState(false);

  const handleClose = () => {
    props.onClose();
  }

  const handleCopy = () => {
    ApiListing.copyListing(listingId, listingType, duplicateImages, true, licenseId)
      .then(res => {
        dispatch(updateListingSuccessAction(res));
        if(user?.id === res.details.fkUser) {
          dispatch(fetchMyListingsAction());
        }
        if(licenseId && res.approval) {
          dispatch(actionApprovalsRefresh());
          history.push(`/wizard/${res.details.pkListing}/${licenseId}`)
        } else if(user?.id === res.details.fkUser) {
          history.push(`/wizard/${res.details.pkListing}`)
        } else {
          dispatch(snackMessageAction("Copied listing does not belong to the license", 'warning'))
        }
        props.onClose();
      })
      .catch(err => {
        //todo error handeling
      })
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Copy listing</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Create a copy of an existing listing: this is useful for listings suited for more than one listing-type;
          or when listing multiple events in a single venue and only need to change a few fields.
        </DialogContentText>
        <Grid container>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-type-label">Listing Type</InputLabel>
              <Select
                label="Select type"
                value={listingType}
                onChange={(e) => setListingType(e.target.value as number)}
              >
                {types?.map(type =>
                  <MenuItem key={type.pkListingType + '-' + type.txtTypeName} value={type.pkListingType}>{type.txtTypeName}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={duplicateImages}
                    color="primary"
                    onChange={e => setDuplicateImages(!!!duplicateImages)}
                    name={'duplicateImages'} />
                }
                label={'Duplicate images'}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
          </Button>
        <Button onClick={handleCopy} color="primary" >
          Create copy
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CopyListingDialog;