import React from "react";
import { OpeningHoursData } from "../../../../Api/Model";
import DayHoursInput from "./DayHoursInput";

interface Props {
  disabled: boolean;
  data: OpeningHoursData;
  onChange: (values: OpeningHoursData, valid: boolean) => void;
}

const HoursForm = (props: Props) => {
  const { data, disabled, onChange } = props;

  return (
    <>
      <DayHoursInput
        label="Monday"
        day={1}
        disabled={disabled}
        value={data.monday}
        onChange={(value) => {
          onChange({ ...data, monday: value }, true);
        }}
      />
      <DayHoursInput
        label="Tuesday"
        day={2}
        disabled={disabled}
        value={data.tuesday}
        onChange={(value) => {
          onChange({ ...data, tuesday: value }, true);
        }}
      />
      <DayHoursInput
        label="Wednesday"
        day={3}
        disabled={disabled}
        value={data.wednesday}
        onChange={(value) => {
          onChange({ ...data, wednesday: value }, true);
        }}
      />
      <DayHoursInput
        label="Thursday"
        day={4}
        disabled={disabled}
        value={data.thursday}
        onChange={(value) => {
          onChange({ ...data, thursday: value }, true);
        }}
      />
      <DayHoursInput
        label="Friday"
        day={5}
        disabled={disabled}
        value={data.friday}
        onChange={(value) => {
          onChange({ ...data, friday: value }, true);
        }}
      />
      <DayHoursInput
        label="Saturday"
        day={6}
        disabled={disabled}
        value={data.saturday}
        onChange={(value) => {
          onChange({ ...data, saturday: value }, true);
        }}
      />
      <DayHoursInput
        label="Sunday"
        day={0}
        disabled={disabled}
        value={data.sunday}
        onChange={(value) => {
          onChange({ ...data, sunday: value }, true);
        }}
      />
      <DayHoursInput
        label="Public Holiday"
        day={-1}
        disabled={disabled}
        value={data.pubhol}
        publicHoliday={true}
        onChange={(value) => {
          onChange({ ...data, pubhol: value }, true);
        }}
      />
    </>
  );
};

export default HoursForm;
