import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import ReceiptIcon from "@mui/icons-material/Receipt";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ApiPremium from "../../../../Api/ApiPremium";
import { ListingLevelLinkDTO } from "../../../../Api/Model";
import { snackMessageAction, snackMessageCaughtErrorAction } from "../../../../Store/App/actions";
import { updateListingSuccessAction } from "../../../../Store/Wizard/actions";
import ActionButton, { QuickAction } from "../../../UI/ActionButton";
import AlertDialog from "../../../UI/AlertDialog";

const DIALOG_CANCEL_SUBSCRIPTION =
    "Select CONFIRM to cancel your subscription. No further payments for this subscription will be deducted. The Localista premium listing level will continue until the end of the subscription term. No credits will apply.";
const DIALOG_CANCEL_BOOKING_ACTIVE =
    "Select CONFIRM to cancel your booking. A credit will be applied to your account for the remaining portion of your booking. This credit can be used for future bookings for this listing or any other listing attached to this user account.";
const DIALOG_CANCEL_BOOKING_FUTURE =
    "Select CONFIRM to cancel your booking. Any credits applied to this booking will be re-credited and the remaining payment amount of the booking will be refunded to your nominated payment method.";
const DIALOG_REACTIVATE_SUBSCRIPTION =
    "Select CONFIRM to reactivate your subscription. By doing so, your non-renewing subscription will be reinstated, and you will continue to enjoy all the benefits of the Localista premium listing level.";

interface Props {
    levelLink: ListingLevelLinkDTO;
    opened?: number;
    onOpen: (linkID?: number) => void;
    onShowTransactions: (levelLink: ListingLevelLinkDTO) => void;
}

const UserActionButton = (props: Props) => {
    const dispatch = useDispatch();
    const { levelLink, opened, onOpen, onShowTransactions } = props;
    const [cancelBooking, setCancelBooking] = useState<boolean>(false);
    const [reactivateSubscription, setReactivateSubscription] = useState<boolean>(false);

    const open = opened === levelLink.linkID;

    const toggle = () => {
        onOpen(open ? undefined : levelLink.linkID);
    };

    const updatePaymentClick = () => {
        onOpen(undefined);
        let cbInstance = (window as any).Chargebee.getInstance();
        cbInstance.openCheckout({
            hostedPage: function () {
                return ApiPremium.updatePayment(levelLink.listingID).catch((err) => {
                    dispatch(snackMessageCaughtErrorAction(err));
                    return err;
                });
            },
            error: function (error) {
                dispatch(snackMessageCaughtErrorAction(error));
            },
            success: function (hostedPageId) {
                dispatch(snackMessageAction("Payment source updated"));
            },
        });
    };

    const onCancelClick = () => {
        setCancelBooking(true);
        toggle();
    };

    const handleCancel = async () => {
        try {
            let res = await ApiPremium.cancelPremiumLevel(levelLink.listingID, levelLink.linkID);
            dispatch(updateListingSuccessAction(res));
            dispatch(snackMessageAction("Cancelled"));
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        } finally {
            setCancelBooking(false);
        }
    };

    const onReactivateClick = () => {
        setReactivateSubscription(true);
        toggle();
    };

    const handleReactivate = async () => {
        try {
            let res = await ApiPremium.reactivateSubscription(levelLink.listingID, levelLink.linkID);
            dispatch(updateListingSuccessAction(res));
            dispatch(snackMessageAction("Reactivated"));
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        } finally {
            setCancelBooking(false);
        }
    };

    const isChargebeeSubscription = levelLink.chargebeeSubscriptionID?.length > 0;
    const isChargebeeBooking = levelLink.chargebeeInvoiceID?.length > 0;
    const isActiveSubscription =
        isChargebeeSubscription && !!!levelLink.dtDeleted && levelLink.chargebeeStatus === "active";
    const isNonRenewingSubscription =
        isChargebeeSubscription && !!!levelLink.dtDeleted && levelLink.chargebeeStatus === "nonrenewing";
    const isFutureBooking = isChargebeeBooking && levelLink.isScheduled && levelLink.status === "future";
    const isActiveBooking = isChargebeeBooking && levelLink.isActive && levelLink.status === "active";

    if (!(isChargebeeBooking || isChargebeeSubscription)) return <></>;

    return (
        <>
            <ActionButton open={open} onToggle={toggle} size="small" noPadding>
                {(isChargebeeBooking || isChargebeeSubscription) && (
                    <QuickAction
                        icon={<ReceiptIcon />}
                        label={"Show transactions"}
                        onClick={() => onShowTransactions(levelLink)}
                    />
                )}
                {isActiveSubscription && (
                    <QuickAction icon={<EditIcon />} label={"Update payment details"} onClick={updatePaymentClick} />
                )}
                {isNonRenewingSubscription && (
                    <QuickAction icon={<EditIcon />} label={"Reactivate Subscription"} onClick={onReactivateClick} />
                )}
                {(isFutureBooking || isActiveBooking || isActiveSubscription) && (
                    <QuickAction icon={<CancelIcon />} label={isActiveSubscription ? "Cancel Subscription" : "Cancel Booking"} onClick={onCancelClick} />
                )}
            </ActionButton>
            {cancelBooking && (
                <AlertDialog
                    onClose={() => setCancelBooking(false)}
                    onConfirm={handleCancel}
                    title={isActiveSubscription ? "Cancel Subscription" : "Cancel Booking"}
                    message={
                        isActiveSubscription
                            ? DIALOG_CANCEL_SUBSCRIPTION
                            : isFutureBooking
                            ? DIALOG_CANCEL_BOOKING_FUTURE
                            : DIALOG_CANCEL_BOOKING_ACTIVE
                    }
                    closeText="close"
                />
            )}
            {reactivateSubscription && (
                <AlertDialog
                    onClose={() => setReactivateSubscription(false)}
                    onConfirm={handleReactivate}
                    title={"Reactivate Your Localista Premium Subscription"}
                    message={DIALOG_REACTIVATE_SUBSCRIPTION}
                    closeText="close"
                />
            )}
        </>
    );
};

export default UserActionButton;
