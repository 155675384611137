import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InsightsIcon from '@mui/icons-material/Insights';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ServerError } from "../../../Api/Api";
import ApiListing from "../../../Api/ApiListing";
import { ScoopStatus } from "../../../Api/Model";
import { listingValidationErrorAction } from "../../../Store/App/actions";
import { approvalsListingUpdatedAction } from "../../../Store/Dashboard/actions";
import { selectApprovalsSelectedLicense } from "../../../Store/Dashboard/selectors";
import { selectUser } from "../../../Store/User/selectors";
import ActionButton, { QuickAction } from "../../UI/ActionButton";
import CopyListingDialog from "../CopyListingDialog";
import AnalyticsDialog from "../AnalyticsDialog";
import { ListingApproval } from "./Approvals";
import SendEmailToListingOwnerDialog from "./SendEmailToListingOwnerDialog";

interface Props {
    listing: ListingApproval;
    opened?: number;
    onOpen: (pkListing?: number) => void;
}

const ApprovalActionButton = (props: Props) => {
    const { listing } = props;
    const { listingID, isVisible, scoopStatus } = listing;
    const licenseId = useSelector(selectApprovalsSelectedLicense);
    const authUser = useSelector(selectUser);

    const dispatch = useDispatch();
    const history = useHistory();
    const open = props.opened === listingID;

    const [copyOpen, setCopyOpen] = useState(false);
    const [emailOpen, setEmailOpen] = useState(false);
    const [analyticsOpen, setAnalyticsOpen] = useState(false);

    const onEdit = () => {
        history.push(`/wizard/${listingID}/${licenseId}`);
    };

    const toggle = () => {
        props.onOpen(open ? undefined : listingID);
    };

    const onActiveChange = async () => {
        try {
            let res = await ApiListing.updateListingVisibility(listingID, !!!isVisible, licenseId);
            let copy = { ...listing };
            copy.isVisible = res.details.isVisible;
            dispatch(approvalsListingUpdatedAction(copy));
        } catch (err) {
            dispatch(listingValidationErrorAction((err as ServerError)?.errors));
        } finally {
            toggle();
        }
    };

    const onConfirmUpdateClick = async () => {
        try {
            let dto = await ApiListing.listingReviewed(listingID, licenseId);
            let copy = { ...listing };
            copy.dtLastUpdated = dto.dtLastUpdated;
            dispatch(approvalsListingUpdatedAction(copy));
        } catch (err) {
        } finally {
            toggle();
        }
    };

    // const onCheckedClick = async () => {
    //     try {
    //         let updated = await Api.listingChecked(licenseId, listingID);
    //         dispatch(approvalsListingUpdatedAction(updated));
    //     } catch (err) {
    //     } finally {
    //         toggle();
    //     }
    // };

    const onApproveClick = async () => {
        try {
            let updated = await ApiListing.updateListingApproval(licenseId, listingID, true, false);
            dispatch(approvalsListingUpdatedAction(updated));
        } catch (err) {
            console.error(err);
        } finally {
            toggle();
        }
    };

    const onUnApproveClick = async () => {
        try {
            let updated = await ApiListing.updateListingApproval(licenseId, listingID, false, false);
            dispatch(approvalsListingUpdatedAction(updated));
        } catch (err) {
            console.error(err);
        } finally {
            toggle();
        }
    };

    const onEmailMessageClick = () => {
        setEmailOpen(true);
        toggle();
    }

    const onAnalyticsClick = () => {
        setAnalyticsOpen(true);
        toggle();
    }

    return (
        <>
            <ActionButton open={open} onToggle={toggle}>
                {listing.allowListingApprove && (
                    <>
                        {(scoopStatus === ScoopStatus.PENDING || scoopStatus === ScoopStatus.UNAPPROVED) && (
                            <QuickAction icon={<CheckIcon />} label={"Approve listing"} onClick={onApproveClick} />
                        )}
                        {(scoopStatus === ScoopStatus.PENDING || scoopStatus === ScoopStatus.APPROVED) && (
                            <QuickAction icon={<CloseIcon />} label={"Unapprove listing"} onClick={onUnApproveClick} />
                        )}
                        {/* <QuickAction icon={<CheckIcon />} label={"Listing checked"} onClick={onCheckedClick} /> */}
                    </>
                )}
                <QuickAction
                    icon={<EditIcon />}
                    label={listing.allowListingEdit ? "Edit listing" : "View listing"}
                    onClick={onEdit}
                />
                {listing.allowListingEdit && (
                    <>
                        <QuickAction
                            label={isVisible ? "Deactivate listing" : "Activate listing"}
                            icon={isVisible ? <CloseIcon /> : <CheckIcon />}
                            onClick={onActiveChange}
                        />
                        <QuickAction icon={<FileCopyIcon />} label={"Copy listing"} onClick={() => setCopyOpen(true)} />
                        <QuickAction icon={<CachedIcon />} label={"Confirm update"} onClick={onConfirmUpdateClick} />
                    </>
                )}
                <QuickAction icon={<EmailIcon />} label={"Email listing-owner"} onClick={onEmailMessageClick} />
                {authUser?.userLevel === 2 && <QuickAction icon={<InsightsIcon />} label={"Analytics"} onClick={onAnalyticsClick} />}
            </ActionButton>
            {copyOpen && (
                <CopyListingDialog
                    listingId={listing.listingID}
                    listingTypeId={listing.listingTypeID}
                    licenseId={licenseId}
                    onClose={() => setCopyOpen(false)}
                />
            )}
            {emailOpen && (
                <SendEmailToListingOwnerDialog
                    licenseId={licenseId}
                    listing={listing}
                    onClose={() => setEmailOpen(false)}
                />
            )}
            {analyticsOpen && (
                <AnalyticsDialog
                    licenseId={licenseId}
                    listingId={listing.listingID}
                    onClose={() => setAnalyticsOpen(false)}
                />
            )}
        </>
    );
};

export default ApprovalActionButton;
