import { ServerError } from "../../Api/Api";
import ApiListing from "../../Api/ApiListing";
import ApiPremium from "../../Api/ApiPremium";
import { CreateImageDTO, ListingFullDTO } from "../../Api/Model";
import { WizardConfig } from "../../Components/Wizard/Wizard";
import { WizardStep } from "../../Components/Wizard/WizardSteps";
import DateUtil from "../../Util/DateUtil";
import ScoopUtil from "../../Util/ScoopUtil";
import { listingValidationErrorAction, snackMessageAction, snackMessageCaughtErrorAction } from "../App/actions";
import {
    approvalsListingUpdatedAction,
    listingUpdatedAction,
    listingUpdatedFromWizardAction,
} from "../Dashboard/actions";
import { AppState } from "../root-reducer";
import { actionTypes as at } from "./constants";

const updateListing = async (dispatch: any, promise?: Promise<ListingFullDTO>) => {
    if (promise) {
        dispatch(updateListingStartAction);
        promise
            .then((listing) => {
                dispatch(updateListingSuccessAction(listing));
            })
            .catch((err) => {
                console.log(err);
                dispatch(snackMessageCaughtErrorAction(err));
                dispatch({
                    type: at.UPDATE_LISTING_ERROR,
                });
            });
    }
};

export const updateWizardStepAction = (wizardStep: WizardStep, promise?: Promise<ListingFullDTO>) => {
    return async (dispatch, getState) => {
        await updateListing(dispatch, promise);
        dispatch({
            type: at.WIZARD_STEP_UPDATED,
            payload: {
                wizardStep,
            },
        });
    };
};

export const refreshPremiumPlansAction = (listingTypeId: number) => {
    return async (dispatch, getState) => {
        const plans = await ApiPremium.getListingMetadataPremiumPlans(listingTypeId);
        dispatch({
            type: at.PREMIUM_PLANS_UPDATED,
            payload: plans,
        });
    };
};

export const updateListingStartAction = () => {
    return {
        type: at.UPDATE_LISTING,
        payload: {},
    };
};

export const activateListingAction = (active: boolean, licenseId?: number, promise?: Promise<ListingFullDTO>) => {
    return async (dispatch, getState) => {
        let appState: AppState = getState();
        if (appState.wizardReducer.listing) {
            await updateListing(dispatch, promise);
            let listing: ListingFullDTO = appState.wizardReducer.listing;
            const { pkListing, isVisible } = listing.details;
            if (isVisible === active) {
                return;
            }
            dispatch(updateListingStartAction);
            try {
                let listing = await ApiListing.updateListingVisibility(pkListing, active, licenseId);
                dispatch(updateListingSuccessAction(listing));
                if (active) {
                    dispatch(showActivatedDialogAction(true));
                }
                if (listing.approval) {
                    let copy = { ...listing.approval };
                    copy.isVisible = active;
                    dispatch(approvalsListingUpdatedAction(copy));
                }
            } catch (err) {
                dispatch(listingValidationErrorAction((err as ServerError)?.errors));
            }
        }
    };
};

export const wizardConfigUpdatedAction = (config: WizardConfig) => {
    return {
        type: at.WIZARD_CONFIG_UPDATED,
        payload: {
            config
        },
    };
};

export const showActivatedDialogAction = (showActivatedDialog: boolean) => {
    return {
        type: at.SHOW_ACTIVATED_DIALOG,
        payload: {
            showActivatedDialog,
        },
    };
};

export const fetchListingAction = (listingId: number, licenseId?: number) => {
    return async (dispatch, getState) => {
        let appState: AppState = getState();
        let isDifferent = listingId !== appState.wizardReducer.listing?.details.pkListing;
        try {
            dispatch({
                type: at.FETCH_LISTING,
                payload: {},
            });
            const listing: ListingFullDTO = await ApiListing.getListingDetails(listingId, licenseId);
            calculateStatusAndEndDate(listing);
            dispatch({
                type: at.FETCH_LISTING_SUCCESS,
                payload: {
                    listing: listing,
                    valid: true,
                    allowEdit: ScoopUtil.isEditAllowed(appState.userReducer.user, listing),
                },
            });
            if (isDifferent) {
                dispatch(updateWizardStepAction(WizardStep.Details));
                dispatch(refreshPremiumPlansAction(listing.details.fkListingType));
            }
            if (!!!licenseId) {
                let dto = await ApiListing.listingReviewed(listingId, licenseId);
                //todo this is causing reset of listing in the wizard but not sure why
                dispatch(listingUpdatedAction(dto));
            }
        } catch (err) {
            dispatch({
                type: at.FETCH_LISTING_ERROR,
                payload: {
                    error: err,
                },
            });
        }
    };
};

const calculateStatusAndEndDate = (listing: ListingFullDTO) => {
    listing?.levels.forEach((lll) => {
        // set status
        if (lll.isActive && !!!lll.dtDeleted) lll.status = "active";
        else if (lll.isScheduled) lll.status = "future";
        else if (lll.chargebeeStatus === "refunded") lll.status = "refunded";
        else if (lll.chargebeeStatus === "cancelled") lll.status = "cancelled";
        else lll.status = "past";
        // set end dates for specific use-cases
        if (lll.isActive && !!!lll.dtDeleted && lll.chargebeeStatus === "active" && lll.chargebeeSubscriptionID)
            lll.endDateFormatted = 'open';
        else if (!!!lll.dtDeleted && lll.isDefault && (lll.isActive || lll.isScheduled)) lll.endDateFormatted = 'open';
        else if (lll.status === 'refunded') lll.endDateFormatted = DateUtil.formatDateFromString(lll.endDate);
        else lll.endDateFormatted = DateUtil.formatDateFromString(lll.dtDeleted || lll.endDate);
        return lll;
    });
};

export const updateListingAction = (promise: Promise<ListingFullDTO>) => {
    return async (dispatch, getState) => {
        await updateListing(dispatch, promise);
    };
};

export const updateListingSuccessAction = (listing: ListingFullDTO) => {
    return async (dispatch, getState) => {
        let appState: AppState = getState();
        calculateStatusAndEndDate(listing);
        dispatch({
            type: at.UPDATE_LISTING_SUCCESS,
            payload: {
                listing: listing,
                valid: true,
                allowEdit: ScoopUtil.isEditAllowed(appState.userReducer.user, listing),
            },
        });
        dispatch(listingUpdatedFromWizardAction(listing));
    };
};

export const resetListingAction = () => {
    return {
        type: at.UPDATE_LISTING_SUCCESS,
        payload: {
            listing: undefined,
            valid: true,
            allowEdit: true,
        },
    };
};

export const listingModifiedAction = (modified: boolean) => {
    return {
        type: at.LISTING_MODIFIED,
        payload: {
            modified,
        },
    };
};

export const uploadImageAction = (pkListing: number, caption: string, credit: string, file: File, licenseId?: number) => {
    return async (dispatch, getState) => {
        let res: CreateImageDTO;
        try {
            res = await ApiListing.createImage(pkListing, caption, credit, file, licenseId);
            dispatch(updateListingSuccessAction(res.listing));
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
            return;
        }
        dispatch(snackMessageAction(`Upload of ${file.name} has started`));
        if (res && res.uploadUrl) {
            try {
                dispatch({
                    type: at.UPLOADING_IMAGES_INCREASE,
                    payload: {},
                });
                await fetch(res.uploadUrl, {
                    // Your POST endpoint
                    method: "PUT",
                    headers: {
                        // Content-Type may need to be completely **omitted**
                        // or you may need something
                        "Content-Type": file.type,
                        "x-ms-blob-type": "BlockBlob",
                    },
                    body: file, // This is your file object
                });
                dispatch(snackMessageAction(`Upload of ${file.name} has finished successfully`, "success"));
            } catch (err) {
                //todo show user that some error occured
            } finally {
                dispatch({
                    type: at.UPLOADING_IMAGES_DECREASE,
                    payload: {},
                });
            }
        }
    };
};
