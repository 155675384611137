import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import FloatingCard from './Forms/FloatingCard';

interface Props {
    children: ReactNode;
}

const WizardMain = (props: Props) => {
    return (
        <Grid item sm={9} md={6} xs={12}>
            <FloatingCard>
                {props.children}
            </FloatingCard>
        </Grid >
    )
}

export default WizardMain;