import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { ListingLevelDTO } from "../../../../Api/Model";
import { snackMessageCaughtErrorAction } from "../../../../Store/App/actions";
import { selectListingLevels } from "../../../../Store/App/selectors";
import { approvalsListingUpdatedAction } from "../../../../Store/Dashboard/actions";
import { updateListingSuccessAction } from "../../../../Store/Wizard/actions";
import { selectListing } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import LoadingDialog from "../../../UI/LoadingDialog";
import SectionGrid from "../SectionGrid";

const DefaultListingLevel = () => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();
    const listing = useSelector(selectListing);
    const listingLevels = useSelector(selectListingLevels);

    const [levels, setLevels] = useState<ListingLevelDTO[]>([]);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (listingLevels && listing) {
            setLevels(listingLevels.sort((a, b) => a.intPriority - b.intPriority));
        }
    }, [listingLevels, listing]);

    const onDefaultLevelUpdate = async (selectedLevel: number) => {
        setProcessing(true);
        try {
            let res = await ApiListing.updateDefaultListingLevel(
                params.licenseId,
                listing.details.pkListing,
                selectedLevel
            );
            dispatch(updateListingSuccessAction(res));
            if (res.approval) {
                dispatch(approvalsListingUpdatedAction(res.approval));
            }
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        } finally {
            setProcessing(false);
        }
    };

    return (
        <SectionGrid title={`Default listing level`}>
            {processing && <LoadingDialog text="Processing update" />}
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-type-label">Select default listing level</InputLabel>
                    <Select
                        disabled={!!!listing?.approval?.allowDefaultLevelEdit}
                        label="Select default listing level"
                        value={listing?.details.fkListingLevelDefault || 0}
                        onChange={(e) => onDefaultLevelUpdate(e.target.value as number)}
                    >
                        {listing?.approval?.allowDefaultLevelEdit ? (
                            levels
                                .filter((l) => ScoopUtil.isDefaultLevelAllowed(l, listing.approval))
                                .map((l) => (
                                    <MenuItem key={l.pkListingLevel} value={l.pkListingLevel}>
                                        {l.txtListingLevel}
                                    </MenuItem>
                                ))
                        ) : (
                            <MenuItem value={listing?.details?.fkListingLevelDefault}>
                                {listing?.details?.defaultListingLevel}
                            </MenuItem>
                        )}
                        {}
                    </Select>
                    <FormHelperText>
                        The default listing level will apply whenever a premium listing level is not in effect.
                    </FormHelperText>
                </FormControl>
            </Grid>
        </SectionGrid>
    );
};

export default DefaultListingLevel;
