import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status, UserType } from "../../../Api/Model";
import { selectMetadataPublic } from "../../../Store/App/selectors";
import { actionUsersFilterUpdated } from "../../../Store/Users/actions";
import { selectUsersFilter, selectUsersLoading } from "../../../Store/Users/selectors";

export interface FilterValues {
    username: string;
    company: string;
    displayName: string;
    status: Status;
    userType: UserType;
    clientName: string;
    stateId: number;
    allClientNames: string[];
    industry: number;
}

interface Props {}

const Filters = (props: Props) => {
    const dispatch = useDispatch();
    const metadataPublic = useSelector(selectMetadataPublic);
    const filterValues = useSelector(selectUsersFilter);
    const loading = useSelector(selectUsersLoading);
    const { username, company, displayName, status, userType, clientName, stateId, industry } = filterValues;

    const filterUpdated = (filter: FilterValues) => dispatch(actionUsersFilterUpdated(filter));

    const onUsernameUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterUpdated({ ...filterValues, username: e.target.value });
    };

    const onCompanyUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterUpdated({ ...filterValues, company: e.target.value });
    };

    const onDisplayNameUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterUpdated({ ...filterValues, displayName: e.target.value });
    };

    const onListingStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterUpdated({ ...filterValues, status: e.target.value as Status });
    };

    const onClientNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterUpdated({ ...filterValues, clientName: e.target.value });
    };

    const onUserTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        filterUpdated({ ...filterValues, userType: e.target.value as UserType });
    };

    const onStateChange = (e: SelectChangeEvent<Number>) => {
        filterUpdated({ ...filterValues, stateId: e.target.value as any as number });
    };

    
    const onIndustryChange = (e: SelectChangeEvent<Number>) => {
        filterUpdated({ ...filterValues, industry: e.target.value as any as number });
    };

    return (
        <>
            <Grid item sm={4} xs={12}>
                <TextField
                    variant="outlined"
                    label="Search user login"
                    placeholder="User login"
                    value={username}
                    fullWidth
                    onChange={onUsernameUpdated}
                />
            </Grid>

            <Grid item sm={4} xs={12}>
                <TextField
                    variant="outlined"
                    label="Search display name"
                    placeholder="Display name"
                    value={displayName}
                    fullWidth
                    onChange={onDisplayNameUpdated}
                />
            </Grid>
            <Grid item sm={2} xs={6}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="state">State</InputLabel>
                    <Select name="state" id="state" label="State" value={stateId} onChange={onStateChange}>
                        <MenuItem key={`state_0`} value={0}>
                            All
                        </MenuItem>
                        {metadataPublic?.states.map((s) => (
                            <MenuItem key={`state_${s.pkStateID}`} value={s.pkStateID}>
                                {s.stateAbbr}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={2} xs={4}>
                <FormControl variant="outlined" fullWidth disabled={loading}>
                    <InputLabel id="select-type-label">Activated</InputLabel>
                    <Select label="Activated" value={status} onChange={onListingStatusChange}>
                        <MenuItem key={`all`} value={Status.All}>
                            All
                        </MenuItem>
                        <MenuItem key={`excluded`} value={Status.Active}>
                            Active
                        </MenuItem>
                        <MenuItem key={`included`} value={Status.Inactive}>
                            Inactive
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={4} xs={12}>
                <TextField
                    variant="outlined"
                    label="Search company"
                    placeholder="Company"
                    value={company}
                    fullWidth
                    onChange={onCompanyUpdated}
                />
            </Grid>
            <Grid item sm={4} xs={4}>
                <TextField
                    variant="outlined"
                    label="Search license group"
                    placeholder="License group"
                    value={clientName}
                    fullWidth
                    onChange={onClientNameChange}
                />
            </Grid>
            <Grid item sm={2} xs={4}>
                <FormControl variant="outlined" fullWidth disabled={loading}>
                    <InputLabel id="select-type-label">User type</InputLabel>
                    <Select label="User type" value={userType} onChange={onUserTypeChange}>
                        <MenuItem key={`all`} value={"All"}>
                            All
                        </MenuItem>
                        <MenuItem key={`standard`} value={"Standard"}>
                            Standard
                        </MenuItem>
                        <MenuItem key={`administrator`} value={"Administrator"}>
                            Administrator
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={2} xs={6}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="state">Industry</InputLabel>
                    <Select name="industry" id="industry" label="Industry" value={industry} onChange={onIndustryChange}>
                        <MenuItem key={`industry_0`} value={0}>
                            All
                        </MenuItem>
                        {metadataPublic?.industries.map((s) => (
                            <MenuItem key={`industry_${s.pkUserIndustryID}`} value={s.pkUserIndustryID}>
                                {s.industryName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
};

export default Filters;
