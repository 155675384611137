import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeUser } from '../../Store/LocalStorage/UserStorage';
import { selectUser } from '../../Store/User/selectors';

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: theme.palette.common.black,
        position: 'relative'
    },
    logoContainer: {
        flexGrow: 1
    },
    logo: {
        cursor: 'pointer',
        height: '35px',
        padding: '5px 0px'
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    menuItem: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        cursor: 'pointer'
    },
    hideXS: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }
}));

const NavigationBar = () => {
    const classes = useStyles();
    const user = useSelector(selectUser);

    const handleLogout = () => {
        removeUser();
        window.location.reload();
    };

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <Box className={classes.logoContainer}>
                    <Link to="/">
                        <img alt="Localista logo" className={classes.logo} src="/assets/localista-logo.png" />
                    </Link>
                </Box>
                <Box display="flex">
                    {user && <>
                        <div className={`${classes.menuItem} ${classes.hideXS}`}>{user.userName}</div>
                        <div className={classes.hideXS}>|</div>
                        <div onClick={handleLogout} className={classes.menuItem}>LOGOUT</div>
                    
                    </>}
                </Box>
            </Toolbar>
        </AppBar>
    )
};

export default NavigationBar;