import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const useStyles = makeStyles((theme) => ({
    noDataContainer: {
        minHeight: 'calc(100vh - 348px)'
    },
    thinkingMeme: {
        margin: theme.spacing(2, 'auto', 2, 'auto'),
        display: 'block'
    },
    thinkingMemeText: {
        marginTop: theme.spacing(4),
    }
}));

const TableNoData = () => {
    const classes = useStyles();

    return (
        <Container className={classes.noDataContainer}>
            <Typography className={classes.thinkingMemeText} variant="h6" align="center">No results for that..</Typography>
            <img src="/assets/computing-meme.jpeg" alt="No results" className={classes.thinkingMeme}/>
        </Container>
    )
};

export default TableNoData;