import { Box, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useState } from 'react';
import HelpPopup from './HelpPopup';


const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        paddingLeft: '16px'
    },
    helpButton: {
        padding: '5px'
    },
    helpIcon: {
        color: theme.palette.primary.main
    }
}));

interface Props {
    title: string;
    markdownHelp?: string;
}

const HeaderTitle = (props: Props) => {
    const { title, markdownHelp } = props;
    const classes = useStyles();
    const [helpOpen, setHelpOpen] = useState(false);

    return <>
        <Grid item xs>
            <Box display="flex" alignItems="flex-end" height="50px">
                <Typography component="div" className={classes.root}>
                    {title}
                </Typography>
                {markdownHelp && <IconButton
                    className={classes.helpButton}
                    onClick={() => setHelpOpen(true)}
                    size="large">
                    <HelpOutlineIcon className={classes.helpIcon} />
                </IconButton>}
                    
            </Box>
        </Grid>
        {helpOpen && <HelpPopup open={helpOpen} markdownFile={markdownHelp} onClose={() => setHelpOpen(false)} />}
    </>;

}

export default HeaderTitle;