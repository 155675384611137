import { Store, createStore, applyMiddleware, compose, StoreEnhancer } from 'redux';
import { state } from './root-reducer';
import ReduxThunk from 'redux-thunk';

const enhancer: StoreEnhancer<any> = compose(
  applyMiddleware(ReduxThunk)
);

const store: Store<any> = createStore(
  state,
  undefined,
  enhancer
);

export default store;
