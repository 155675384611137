import { Status, UserDTO } from "../../Api/Model";
import { FilterValues } from "../../Components/Dashboard/Users/Filters";
import { TableSetting } from "../../Components/UI/EnhancedTable";
import { Action } from "../root-reducer";
import { actionTypes as at } from "./constants";

interface UsersState {
    users: UserDTO[];
    loading: boolean;
    error: string | undefined;
    tableSetting: TableSetting;
    filter: FilterValues;
    selected: UserDTO | undefined;
}

const initialState: UsersState = {
    users: [],
    loading: false,
    error: undefined,
    tableSetting: {
        page: 0,
        pageSize: 15,
        order: "desc",
        orderBy: "lastLogin",
    },
    filter: {
        username: "",
        displayName: "",
        company: "",
        status: Status.Active,
        userType: "All",
        clientName: "",
        stateId: 0,
        allClientNames: [],
        industry: 0
    },
    selected: undefined,
};

const reducer = (state = initialState, action: Action): UsersState => {
    switch (action.type) {
        case at.USERS_FETCH:
            return {
                ...state,
                loading: true,
                users: [],
                error: undefined,
            };
        case at.USERS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload,
                filter: {...state.filter,
                    allClientNames: (action.payload as UserDTO[]).filter(u => u.clientName?.length > 0).map(u => u.clientName)},
                error: undefined,
            };
        case at.USERS_FETCH_ERROR:
            return {
                ...state,
                users: [],
                loading: false,
                error: action.payload,
            };

        case at.USERS_USER_SELECTED:
            return {
                ...state,
                selected: action.payload,
            };

        case at.USERS_USER_DESELECTED:
            return {
                ...state,
                selected: undefined,
            };

        case at.USERS_USER_CREATED:
            return {
                ...state,
                users: [...state.users, action.payload]
            };

        case at.USERS_USER_UPDATED:
            return {
                ...state,
                users: state.users.map((u) => (u.id === action.payload.id ? action.payload : u)),
            };

        case at.USERS_USER_DELETED:
            return {
                ...state,
                users: state.users.filter((u) => u.id !== action.payload),
            };
        case at.USERS_FILTER_UPDATED:
            return {
                ...state,
                filter: action.payload,
                tableSetting: { ...state.tableSetting, page: 0 },
            };
        case at.USERS_TABLE_SETTING_UPDATED:
            return {
                ...state,
                tableSetting: action.payload,
            };

        default:
            return state;
    }
};
export default reducer;
