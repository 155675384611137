import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import GooglePlacesAPI, { PlaceDetails, PlacePrediction } from "../../Api/GooglePlacesAPI";
import { LatLng } from "leaflet";

interface Props {
    label: string;
    onChange: (place?: PlaceDetails) => void;
    passResetState?: (resetState: () => void) => void;
    location?: LatLng;
    radius?: number;
    fullWidth?: boolean;
    disabled?: boolean;
}

const AutocompleteGooglePlaces = (props: Props) => {
    const { label, fullWidth, onChange, passResetState, location, radius, disabled } = props;
    const [value, setValue] = React.useState<PlacePrediction | null>(null);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState<readonly PlacePrediction[]>([]);

    const resetState = () => {
        setValue(null);
        setOptions([])
    }

    if(passResetState) passResetState(resetState);

    const fetch = React.useMemo(
        () =>
            throttle((input: string, location: LatLng, radius: number, callback: (results?: readonly PlacePrediction[]) => void) => {
                GooglePlacesAPI.autocomplete(input, location, radius).then(res => {
                    callback(res);
                });
            }, 200),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }
        fetch(inputValue, location, radius, (results?: readonly PlacePrediction[]) => {
            if (active) {
                let newOptions: readonly PlacePrediction[] = [];
                if (value) {
                    newOptions = [value];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, location, radius]);

    const onValueUpdate = async (prediction: PlacePrediction | null) => {
        if(prediction != null) {
            let details = await GooglePlacesAPI.placeDetails(prediction.place_id);
            onChange(details)
        } else {
            onChange()
        }
        setValue(prediction)
    }

    return (
        <Autocomplete
            id="google-places-autocomplete"
            disabled={disabled}
            fullWidth={fullWidth}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event: any, newValue: PlacePrediction | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                onValueUpdate(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label={label} fullWidth />}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length])
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box component={LocationOnIcon} sx={{ color: "text.secondary", mr: 2 }} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}

export default AutocompleteGooglePlaces;
