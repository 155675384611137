import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectTypes } from '../../Store/App/selectors';
import { wizardConfigUpdatedAction, fetchListingAction, showActivatedDialogAction, updateWizardStepAction } from '../../Store/Wizard/actions';
import { selectListing, selectListingLoading, selectListingUpdating, selectShowActivatedDialog, selectWizardStep } from '../../Store/Wizard/selectors';
import ListingActivatedDialog from '../UI/ListingActivatedDialog';
import LoadingDialog from '../UI/LoadingDialog';
import ApprovalStep from './Forms/Approval/ApprovalStep';
import ContactInformationStep from './Forms/Contacts/ContactsStep';
import DatesStep from './Forms/Dates/DatesStep';
import DescriptionStep from './Forms/Description/DescriptionStep';
import DetailsStep from './Forms/Details/DetailsStep';
import FeaturesStep from './Forms/Features/FeaturesStep';
import ImagesStep from './Forms/Images/ImagesStep';
import AdminLevelsStep from './Forms/Levels/AdminLevelsStep';
import PremiumLevelsStep from './Forms/Levels/PremiumLevelsStep';
import LinkedListingsStep from './Forms/LinkedListings/LinkedListingsStep';
import LocationStep from './Forms/Location/LocationStep';
import VideosStep from './Forms/Videos/VideosStep';
import { WizardStep } from './WizardSteps';
import OpeningHoursStep from './Forms/OpenningHours/OpeningHoursStep';

const useStyles = makeStyles((theme) => ({
    listingCreationContainer: {
        background: theme.palette.common.white,
        padding: 0,
        margin: 0,
    },
    floatingCard: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    contentContainer: {
        background: theme.palette.background.default,
        paddingBottom: theme.spacing(2),
    },
    formContainer: {
        background: theme.palette.grey[300],
    },
    guideContainer: {
        background: theme.palette.grey[300]
    }
}));

export interface WizardConfig {
    allowEvents: boolean;
    allowOpeningHours: boolean;
}

interface Props {

}

const Wizard = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const wizardStep = useSelector(selectWizardStep);
    const listingLoading = useSelector(selectListingLoading);
    const updating = useSelector(selectListingUpdating);
    const types = useSelector(selectTypes)
    const listing = useSelector(selectListing);
    const showActivated = useSelector(selectShowActivatedDialog);

    useEffect(() => {
        let allowEvents = false;
        let allowOpeningHours = false;
        if (types && listing) {
            const type = types.find(t => t.pkListingType === listing.details.fkListingType);
            allowEvents = type?.allowEvents;
            allowOpeningHours = type?.allowOpeningHours;
        }
        dispatch(wizardConfigUpdatedAction({allowEvents, allowOpeningHours}));
    }, [dispatch, listing, types])

    let params = useParams<{id: any, licenseId: any}>();

    useEffect(() => {
        let { id, licenseId } = params;
        
        if(id > 0) {
            dispatch(fetchListingAction(Number(id), licenseId));
        } else {
            dispatch(updateWizardStepAction(WizardStep.Details));
        }
    }, [dispatch, params])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [wizardStep]);

    const onActivatedClosed = () => {
        dispatch(showActivatedDialogAction(false));
    }

    return (<>
        <Container maxWidth="xl" className={classes.listingCreationContainer}>
            {(listingLoading || types === undefined) && <LoadingDialog text={'Loading listing details'} />}
            <Grid container>
                <Grid className={classes.contentContainer} item xs={12} container>
                    {wizardStep === WizardStep.Details && <DetailsStep />}
                    {wizardStep === WizardStep.Approval && <ApprovalStep/>}
                    {wizardStep === WizardStep.Levels && <AdminLevelsStep/>}
                    {wizardStep === WizardStep.PremiumLevels && <PremiumLevelsStep/>}
                    {wizardStep === WizardStep.ContactInformation && <ContactInformationStep />}
                    {wizardStep === WizardStep.Location && <LocationStep />}
                    {wizardStep === WizardStep.Description && <DescriptionStep />}
                    {wizardStep === WizardStep.Dates && <DatesStep />}
                    {wizardStep === WizardStep.LinkedListings && <LinkedListingsStep />}
                    {wizardStep === WizardStep.Categories && <FeaturesStep />}
                    {wizardStep === WizardStep.OpeningHours && <OpeningHoursStep/>}
                    {wizardStep === WizardStep.Images && <ImagesStep />}
                    {wizardStep === WizardStep.Videos && <VideosStep />}
                    {/* {wizardStep === WizardStep.Documents && <DocumentsStep {...wizardProps}/>} */}
                </Grid>
            </Grid>
        </Container>
        <LoadingDialog open={updating} text={'Saving changes'}/>
        {showActivated && <ListingActivatedDialog onClose={onActivatedClosed}/>}
    </>
    )
};

export default Wizard;