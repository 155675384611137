import { UserFormValues } from "../Components/UI/UserForm";
import { getValidToken } from "../Store/LocalStorage/UserStorage";
import Api from "./Api";
import {
    ClientDetailsDTO,
    LoginDTO, UserAutocompleteDTO, UserDTO, UserProfileDTO
} from "./Model";

export default class ApiUsers extends Api {

    public static login(email: string, password: string): Promise<LoginDTO> {
        return this.axios
            .post("/admin/login", {
                email: email,
                password: password,
            })
            .then((res) => {
                return res.data;
            });
    }

    public static register(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        company: string,
        displayname: string,
        industries: number[],
        countryId: number,
        stateId: number
    ): Promise<LoginDTO> {
        return this.axios
            .post("/admin/user", {
                email,
                password,
                firstName,
                lastName,
                company,
                displayname,
                industries,
                countryId,
                stateId,
            })
            .then((res) => {
                return res.data;
            });
    }

    public static getUser(): Promise<UserProfileDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios.get("/admin/user", { headers: { Authorization: "Bearer " + token } }).then((res) => res.data);
        } else {
            return Promise.resolve(undefined);
        }
    }

    public static updateUser(
        email: string,
        company: string,
        displayname: string,
        firstName: string,
        lastName: string,
        address: string,
        suburb: string,
        postcode: string,
        countryId: number,
        stateId: number,
        phone: string,
        industries: number[]
    ): Promise<UserProfileDTO> {
        const token = getValidToken();
        if (token) {
            let data = {
                email,
                company,
                displayname,
                firstName,
                lastName,
                address,
                suburb,
                postcode,
                countryId,
                stateId,
                phone,
                industries,
            };
            return this.axios
                .put("/admin/user", data, { headers: { Authorization: "Bearer " + token } })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateUserPassword(password: string, newPassword: string): Promise<UserProfileDTO> {
        const token = getValidToken();
        if (token) {
            let data = {
                password,
                newPassword,
            };
            return this.axios
                .put("/admin/user/password", data, { headers: { Authorization: "Bearer " + token } })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static forgotPassword(email: string): Promise<UserProfileDTO> {
        let data = {
            email,
        };
        return this.axios.post("/admin/user/forgot-password", data).then((res) => res.data);
    }

    public static getUsersAutocomplete(): Promise<UserAutocompleteDTO[]> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .get(`/admin/users/autocomplete?cache=true`, { headers: { Authorization: "Bearer " + token } })
                .then((res: any) => {
                    return res.data.users;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getUsers(): Promise<UserDTO[]> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .get(`/admin/users`, { headers: { Authorization: "Bearer " + token } })
                .then((res: any) => {
                    return res.data.users;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getUserProfile(userId: number): Promise<UserProfileDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .get(`/admin/users/profile?userId=${userId}`, { headers: { Authorization: "Bearer " + token } })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateUserByAdmin(userId: number, f: UserFormValues): Promise<UserProfileDTO> {
        const token = getValidToken();
        if (token) {
            let data = {
                email: f.email,
                company: f.company,
                displayname: f.displayname,
                firstName: f.firstname,
                lastName: f.lastname,
                address: f.address,
                suburb: f.suburb,
                postcode: f.postcode,
                countryId: f.countryId,
                stateId: f.stateId,
                phone: f.phone,
                industries: f.industries,
                isActive: f.active,
                notes: f.notes || '',
                clientId: f.clientId > 0 ? f.clientId : undefined,
                password: f.password?.length > 0 ? f.password : undefined
            };
            return this.axios
                .put(`/admin/users?userId=${userId}`, data, { headers: { Authorization: "Bearer " + token } })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static createUserAdmin(f: UserFormValues): Promise<UserProfileDTO> {
        const token = getValidToken();
        if (token) {
            let data = {
                email: f.email,
                company: f.company,
                displayname: f.displayname,
                firstName: f.firstname,
                lastName: f.lastname,
                address: f.address,
                suburb: f.suburb,
                postcode: f.postcode,
                countryId: f.countryId,
                stateId: f.stateId,
                phone: f.phone,
                industries: f.industries,
                isActive: f.active,
                notes: f.notes || '',
                clientId: f.clientId > 0 ? f.clientId : undefined,
                password: f.password?.length > 0 ? f.password : undefined
            };
            return this.axios
                .post("/admin/users", data, { headers: { Authorization: "Bearer " + token } })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static deleteUserByAdmin(userId: number): Promise<void> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .delete(`/admin/users?userId=${userId}`, { headers: { Authorization: "Bearer " + token } });
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static updateUserStatusByAdmin(userId: number, isActive: boolean): Promise<UserProfileDTO> {
        const token = getValidToken();
        if (token) {
            let data = {
                isActive
            }
            return this.axios
                .put(`/admin/users/status?userId=${userId}`, data, { headers: { Authorization: "Bearer " + token } })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static sendEmailToUser(
        userId: number,
        paragraphs: string[]
    ): Promise<Boolean> {
        const token = getValidToken();
        if (token) {
            const headers = { Authorization: "Bearer " + token };
            const body = {
                paragraphs: paragraphs.map(t => ({ text: t}))
            };
            const parameters = {
                userId
            };
            return this.axios
                .post("/admin/users/notification", body, { params: parameters, headers: headers })
                .then((res) => res.data);
        } else {
            return Promise.reject("Not logged in");
        }
    }

    public static getClientDetails(clientId: number): Promise<ClientDetailsDTO> {
        const token = getValidToken();
        if (token) {
            return this.axios
                .get(`/admin/client?clientId=${clientId}`, { headers: { Authorization: "Bearer " + token } })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }
}
