export const actionTypes = {

    USERS_FETCH: 'USERS/FETCH',
    USERS_FETCH_SUCCESS: 'USERS/FETCH_SUCCESS',
    USERS_FETCH_ERROR: 'USERS/FETCH_ERROR',
    USERS_FILTER_UPDATED: 'USERS/LISTINGS_FILTER_UPDATED',
    USERS_TABLE_SETTING_UPDATED: 'USERS/LISTINGS_TABLE_SETTING_UPDATED',

    USERS_USER_SELECTED: 'USERS/USER_SELECTED',
    USERS_USER_DESELECTED: 'USERS/USER_DESELECTED',
    USERS_USER_DELETED: 'USERS/USER_DELETED',
    USERS_USER_UPDATED: 'USERS/USER_UPDATED',
    USERS_USER_CREATED: 'USERS/USER_CREATED'

};