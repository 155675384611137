import { addMonths } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWizardStepAction } from "../../../../Store/Wizard/actions";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import AddUpdateLevelAdminDialog from "./AddUpdateLevelAdminDialog";

import { Button, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { ListingFullDTO, ListingLevelDTO, ListingLevelLinkDTO } from "../../../../Api/Model";
import { selectListingLevels } from "../../../../Store/App/selectors";
import { selectListing } from "../../../../Store/Wizard/selectors";
import DateUtil from "../../../../Util/DateUtil";
import AdminActionButton from "./AdminActionButton";
import DefaultListingLevel from "./DefaultListingLevel";
import DeleteLevelAdminDialog from "./DeleteLevelAdminDialog";
import ListingLevelDetailsDialog from "./ListingLevelDetailsDialog";
import ListingLevelTable from "./ListingLevelTable";

const AdminLevelsStep = () => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();

    const listingLevels = useSelector(selectListingLevels);
    const [levels, setLevels] = useState<ListingLevelDTO[]>([]);

    const listing = useSelector(selectListing);
    const [opened, setOpened] = useState<number>(undefined);
    const [showTransactions, setShowTransaction] = useState<ListingLevelLinkDTO>(undefined);
    const [levelToUpdate, setLevelToUpdate] = useState<ListingLevelLinkDTO>(undefined);
    const [levelToDelete, setLevelToDelete] = useState<ListingLevelLinkDTO>(undefined);
    const [activeUserSubscription, setActiveUserSubscription] = useState<ListingLevelLinkDTO>(undefined);

    const onWizardStepChange = (step: WizardStep) => {
        dispatch(updateWizardStepAction(step));
    };

    const onSaveHook = (): Promise<any> => {
        return undefined;
    };

    useEffect(() => {
        if (listingLevels && listing) {
            setLevels(listingLevels.sort((a, b) => a.intPriority - b.intPriority));
            setActiveUserSubscription(listing.levels.find(l => l.isActive));
        }
    }, [listingLevels, listing]);

    const createActionButton = (ll: ListingLevelLinkDTO): JSX.Element => {
        return (
            <AdminActionButton
                levelLink={ll}
                onOpen={setOpened}
                opened={opened}
                onCancel={(ll) => {
                    setLevelToDelete(ll);
                    setOpened(undefined);
                }}
                onShowTransactions={(ll) => {
                    setShowTransaction(ll);
                    setOpened(undefined);
                }}
                onEdit={(lll => {
                    setLevelToUpdate(lll);
                    setOpened(undefined);
                })}
            />
        );
    };

    const onNewClick = () => {
        let endDate = DateUtil.formatDateString(addMonths(new Date(), 1));
        let ll: ListingLevelLinkDTO = {
            listingID: listing.details.pkListing,
            levelID: listing.details.fkListingLevelDefault,
            startDate: DateUtil.formatDateString(new Date()),
            endDate: endDate,
            status: "",
            endDateFormatted: endDate
        };
        setLevelToUpdate(ll);
    };

    const formatTitle = (listing?: ListingFullDTO): string => {
        if (!!!listing) return "";
        const { fkListingLevelDefault, pkListingLevel, txtListingLevel } = listing.details;
        if (fkListingLevelDefault === pkListingLevel) {
            return `${txtListingLevel}`;
        } else {
            return `${txtListingLevel} (premium)`;
        }
    };

    return (
        <>
            <WizardHeader backToListingsHook={onSaveHook} />

            <WizardSidebar onSaveHook={onSaveHook} />

            <WizardMain>
                <FormGrid>
                    <SectionGrid title={`Active listing level: ${formatTitle(listing)}`}></SectionGrid>

                    <DefaultListingLevel />

                    <SectionGrid
                        title={"Listing levels"}
                        buttons={
                            listing?.approval?.allowPremiumLevelEdit ? (
                                <Button
                                    onClick={onNewClick}
                                    variant="contained"
                                    color="primary"
                                    disabled={activeUserSubscription?.endDate === null}
                                >
                                    Add
                                </Button>
                            ) : (
                                <></>
                            )
                        }
                    >
                        <Grid item xs={12}>
                            <ListingLevelTable createActionButton={createActionButton} />
                        </Grid>
                    </SectionGrid>
                </FormGrid>
                <BackForwardButtons
                    onClickPrevious={() => onWizardStepChange(WizardStep.Categories)}
                    onClickNext={() => onWizardStepChange(WizardStep.Images)}
                />
            </WizardMain>

            <WizardGuide />

            {showTransactions && (
                <ListingLevelDetailsDialog link={showTransactions} onClose={() => setShowTransaction(undefined)} />
            )}

            {levelToUpdate && (
                <AddUpdateLevelAdminDialog
                    levelLink={levelToUpdate}
                    licenseId={params.licenseId}
                    levels={levels}
                    levelLinks={listing.levels}
                    onClose={() => setLevelToUpdate(undefined)}
                />
            )}
            {levelToDelete && (
                <DeleteLevelAdminDialog
                    pkListing={params.id}
                    levelLink={levelToDelete}
                    licenseId={params.licenseId}
                    onClose={() => setLevelToDelete(undefined)}
                />
            )}
        </>
    );
};

export default AdminLevelsStep;
