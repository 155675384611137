import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { ListingFullDTO } from "../../../../Api/Model";
import { listingModifiedAction, updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectWizardConfig, selectListing } from "../../../../Store/Wizard/selectors";
import { deepEqual } from "../../../../Util/Util";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import LinkedListingsForm from "./LinkedListingsForm";
interface Props {}
const LinkedListingsStep = (props: Props) => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();

    const listing = useSelector(selectListing);
    const { allowEvents } = useSelector(selectWizardConfig);
    const allowEdit = useSelector(selectAllowEdit);
    const [origValues, setOrigValues] = useState<number[]>(undefined);
    const [values, setValues] = useState<number[]>(undefined);

    useEffect(() => {
        if(listing) {
            const { fkParentListing, fkParentListing2, fkParentListing3, fkParentListing4, fkParentListing5 } = listing.details;
            let list: number[] = [];
            if (fkParentListing) list.push(fkParentListing);
            if (fkParentListing2) list.push(fkParentListing2);
            if (fkParentListing3) list.push(fkParentListing3);
            if (fkParentListing4) list.push(fkParentListing4);
            if (fkParentListing5) list.push(fkParentListing5);
            setValues(list);
            setOrigValues([...list]);
        }
    }, [listing])

    const onValuesChanged = (values: number[]) => {
        setValues(values);
    };

    useEffect(() => {
        dispatch(listingModifiedAction(!deepEqual(values, origValues)));
    }, [values, origValues, dispatch]);

    const generatePromise = (): Promise<ListingFullDTO> => {
        let promise: Promise<ListingFullDTO> = undefined;
        if (!deepEqual(origValues, values)) {
            const parent1 = values.length > 0 ? values[0] : undefined;
            const parent2 = values.length > 1 ? values[1] : undefined;
            const parent3 = values.length > 2 ? values[2] : undefined;
            const parent4 = values.length > 3 ? values[3] : undefined;
            const parent5 = values.length > 4 ? values[4] : undefined;

            promise = ApiListing.updateListingParent(
                listing.details.pkListing,
                parent1,
                parent2,
                parent3,
                parent4,
                parent5,
                params.licenseId
            ).then((res) => {
                setOrigValues([...values]);
                return res;
            });
        }
        return promise;
    };

    const onWizardStepChange = (step: WizardStep) => {
        let promise = generatePromise();
        dispatch(updateWizardStepAction(step, promise));
    };

    const onSaveHook = (): Promise<any> => {
        return generatePromise();
    };

    return (
        <>
            <WizardHeader backToListingsHook={onSaveHook} />
            <WizardSidebar onSaveHook={onSaveHook} />
            <WizardMain>
                {values && <LinkedListingsForm disabled={!allowEdit} values={values} onChange={onValuesChanged} />}
                <BackForwardButtons
                    onClickNext={() => onWizardStepChange(WizardStep.Categories)}
                    onClickPrevious={() => onWizardStepChange(allowEvents ? WizardStep.Dates : WizardStep.Description)}
                />
            </WizardMain>
            <WizardGuide step={WizardStep.LinkedListings} />
        </>
    );
};

export default LinkedListingsStep;
