export const actionTypes = {
    WIZARD_STEP_UPDATED: 'WIZARD/STEP_UPDATED',
    WIZARD_CONFIG_UPDATED: 'WIZARD/CONFIG_UPDATED',
    SHOW_ACTIVATED_DIALOG: 'WIZARD/SHOW_ACTIVATED_DIALOG',

    FETCH_LISTING: 'WIZARD/FETCH_LISTING',
    FETCH_LISTING_SUCCESS: 'WIZARD/FETCH_LISTING_SUCCESS',
    FETCH_LISTING_ERROR: 'WIZARD/FETCH_LISTING_ERROR',

    UPDATE_LISTING: 'WIZARD/UPDATE_LISTING',
    UPDATE_LISTING_SUCCESS: 'WIZARD/UPDATE_LISTING_SUCCESS',
    UPDATE_LISTING_ERROR: 'WIZARD/UPDATE_LISTING_ERROR',

    LISTING_MODIFIED: 'WIZARD/LISTING_MODIFIED',

    UPLOADING_IMAGES_INCREASE: 'WIZARD/UPLOADING_IMAGES_INCREASE',
    UPLOADING_IMAGES_DECREASE: 'WIZARD/UPLOADING_IMAGES_DECREASE',

    PREMIUM_PLANS_UPDATED: 'WIZARD/PREMIUM_PLANS_UPDATED',
};