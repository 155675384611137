import { VariantType } from 'notistack';
import { ListingDirectoryDTO, ListingLevelDTO, ListingTypeDTO, MetadataAdminDTO, MetadataPublicDTO, Region, SocialLinksDTO, UserAutocompleteDTO } from '../../Api/Model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

export interface AppReducer {
    metadataPublic?: MetadataPublicDTO;
    types?: ListingTypeDTO[];
    directories?: ListingDirectoryDTO[];
    levels?: ListingLevelDTO[];
    socialLinks?: SocialLinksDTO[];
    regions?: Region[];
    errorsTitle?: string;
    errorsDescription?: string;
    errors?: string[];
    snackMessage?: {
        text: string;
        variant?: VariantType;
        autoHideDuration?: number;
    };
    users?: UserAutocompleteDTO[],
    metadataAdmin?: MetadataAdminDTO
};

const initialState: AppReducer = {
};

const reducer = (state: AppReducer = initialState, action: Action): AppReducer => {
    switch (action.type) {
        case at.SNACK_MESSAGE_UPDATED:
            return {
                ...state,
                snackMessage: action.payload.snackMessage
            }

        case at.FETCH_LISTING_METADATA:
            return state;

        case at.FETCH_LISTING_METADATA_SUCCESS:
            return {
                ...state,
                types: action.payload.types,
                directories: action.payload.directories,
                levels: action.payload.levels,
                socialLinks: action.payload.socialLinks,
            }

        case at.FETCH_LISTING_METADATA_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.payload.regions
            }

        case at.FETCH_LISTING_METADATA_ERROR:
            return {
                ...state,
                types: undefined,
                directories: undefined,
                levels: undefined,
                socialLinks: undefined,
                regions: undefined,
            }

        case at.FETCH_PUBLIC_METADATA:
            return state;

        case at.FETCH_PUBLIC_METADATA_SUCCESS:
            return {
                ...state,
                metadataPublic: action.payload.metadata
            }

        case at.FETCH_PUBLIC_METADATA_ERROR:
            return {
                ...state,
                metadataPublic: undefined
            }
        case at.ERRORS_UPDATED:
            return {
                ...state,
                errors: action.payload.errors,
                errorsTitle: action.payload.errorsTitle,
                errorsDescription: action.payload.errorsDescription
            }
        case at.FETCH_USERS_AUTOCOMPLETE_SUCCESS:
            return {
                ...state,
                users: action.payload.users
            }
        case at.USERS_AUTOCOMPLETE_ADD:
            return {
                ...state,
                users: [...state.users, action.payload]
            }
    
        case at.FETCH_ADMIN_METADATA:
            return state;

        case at.FETCH_ADMIN_METADATA_SUCCESS:
            return {
                ...state,
                metadataAdmin: action.payload.metadata
            }

        case at.FETCH_ADMIN_METADATA_ERROR:
            return {
                ...state,
                metadataAdmin: undefined
            }
        default:
            return state;
    }
}
export default reducer;