import { LoginDTO } from "../../Api/Model";

const USER_TOKEN = 'LOCALISTA_USER_TOKEN';
const USER_TOKEN_EXPIRY = 'LOCALISTA_USER_TOKEN_EXPIRY';


export function setToken(dto: LoginDTO) {
    window.localStorage.setItem(USER_TOKEN_EXPIRY, dto.expiryAt?.toString());
    window.localStorage.setItem(USER_TOKEN, dto.token);
}

export function getValidToken() : string | undefined {
    const token = window.localStorage.getItem(USER_TOKEN);
    const tokenExpiry = window.localStorage.getItem(USER_TOKEN_EXPIRY);
    if(token && tokenExpiry) {
        if((Number(tokenExpiry) * 1000) > new Date().getTime()) {
            return token;
        }
    }
    removeUser();
}

export function removeUser() {
    window.localStorage.removeItem(USER_TOKEN);
    window.localStorage.removeItem(USER_TOKEN_EXPIRY);
}