import { Box } from '@mui/material';
import * as React from 'react';

interface Props {
    text?: string;
    size?: number;
}

const Loading = (props: Props) => {

    let height = `${props.size ? props.size : 40}px`;

    return (
        <Box justifyContent="center" display="flex" alignItems="center">
            {props.text && <Box marginRight={'15px'}>{props.text}</Box>}
            <Box><img height={height} src={'/assets/loading.gif'} alt="Loading gif" /></Box>
        </Box>
    );
};

export default Loading;