import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useDispatch } from 'react-redux';
import ApiListing from '../../Api/ApiListing';
import { ListingDTO } from '../../Api/Model';
import { listingDeletedAction } from '../../Store/Dashboard/actions';

interface Props {
  listing: ListingDTO;
  onClose: () => void;
}

const DeleteListingDialog = (props: Props) => {

  const { listing } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    props.onClose();
  }

  const handleDelete = () => {
    ApiListing.deleteListing(listing.pkListing)
      .then(res => {
        dispatch(listingDeletedAction(listing.pkListing));
        props.onClose();
      })
      .catch(err => {
        //todo error handeling
      })
  }

  return (
    <Dialog
      open={listing !== undefined}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete listing</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete <strong>{listing.txtListingName}</strong>
        </DialogContentText>
        <DialogContentText>
          Caution! This cannot be undone!
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Do Not Delete
          </Button>
        <Button onClick={handleDelete} color="primary" >
          Delete Forever
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteListingDialog;