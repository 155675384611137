import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiPremium, { BodyPremiumListing } from "../../../../Api/ApiPremium";
import { ListingLevelLinkDTO, PremiumEstimateDTO, PremiumPlanDTO } from "../../../../Api/Model";
import { snackMessageCaughtErrorAction, snackMessageErrorAction } from "../../../../Store/App/actions";
import { selectUser } from "../../../../Store/User/selectors";
import { updateListingAction } from "../../../../Store/Wizard/actions";
import { selectListing, selectPremiumPlans } from "../../../../Store/Wizard/selectors";
import DateUtil from "../../../../Util/DateUtil";
import ScoopUtil from "../../../../Util/ScoopUtil";
import LoadingDialog from "../../../UI/LoadingDialog";
import LevelDateRangeInput from "./LevelDateRangeInput";
import ReferralCodeInput from "./ReferralCodeInput";

interface PremiumPlanPros {}

const PremiumFormEvents = (props: PremiumPlanPros) => {
    const authUser = useSelector(selectUser);

    const dispatch = useDispatch();

    const listing = useSelector(selectListing);
    const plans = useSelector(selectPremiumPlans);
    const [selected, setSelected] = useState<PremiumPlanDTO>(undefined);
    const [startDate, setStartDate] = useState<Date>(undefined);
    const [endDate, setEndDate] = useState<Date>(undefined);
    const [activeChargebee, setActiveChargebee] = useState<ListingLevelLinkDTO>(undefined);
    const [appliedReferral, setAppliedReferral] = useState<string>(undefined);

    const [hostedPageId, setHostedPagedId] = useState<string>(undefined);

    const [filteredPlans, setFilteredPlans] = useState<PremiumPlanDTO[]>([]);

    const [estimate, setEstimate] = useState<PremiumEstimateDTO>();

    useEffect(() => {
        if(listing) {
            setActiveChargebee(listing.levels.find((ll) => ll.chargebeeSubscriptionID?.length > 0))
        } else {
            setActiveChargebee(undefined);
        }
    }, [listing])

    useEffect(() => {
        const currencyCode = listing?.details?.countryCode === "NZ" ? "NZD" : "AUD";
        const filtered = plans.filter((p) => p.currencyCode === currencyCode);
        setFilteredPlans(filtered);
    }, [listing, plans, authUser]);

    useEffect(() => {
        if (hostedPageId) {
            dispatch(
                updateListingAction(
                    ApiPremium.processHostedPage(listing.details.pkListing, hostedPageId).then((res) => {
                        setHostedPagedId(undefined);
                        return res;
                    })
                )
            );
        }
    }, [dispatch, hostedPageId, listing]);

    useEffect(() => {
        if (selected && listing && startDate && endDate) {
            const body = constructPremiumListingBody(activeChargebee, selected, startDate, endDate, appliedReferral);
            ApiPremium.getEstimate(listing.details.pkListing, body)
                .then((res) => {
                    setEstimate(res);
                })
                .catch((err) => {
                    setEstimate(undefined);
                    dispatch(snackMessageCaughtErrorAction(err))
                });
        }
    }, [dispatch, selected, listing, activeChargebee, appliedReferral, startDate, endDate]);

    const onPlanChanged = (e: any) => {
        var itemPriceId = e.target.value;
        var product = plans.find((p) => p.itemPriceId === itemPriceId);
        setSelected(product);
    };

    const onConfirmClick = () => {
        const body = constructPremiumListingBody(activeChargebee, selected, startDate, endDate, appliedReferral);
        //todo ask confirmation that user want update existing subscription
        let cbInstance = (window as any).Chargebee.getInstance();
        cbInstance.openCheckout({
            hostedPage: function () {
                return ApiPremium.subscribeToPlan(listing.details.pkListing, body
                ).catch((err) => {
                    dispatch(snackMessageErrorAction(err.toString()));
                    return err;
                });
            },
            error: function (error) {
                console.log("chargebee:error");
                console.error(error);
                // Optional
                // will be called if the promise passed causes an error
            },
            success: function (hostedPageId) {
                console.log("chargebee:success", hostedPageId);
                setHostedPagedId(hostedPageId);
                // cbInstance.closeAll();

                // Optional
                // will be called when a successful checkout happens.
            },
            close: function () {
                console.log("chargebee:close");
                // Optional
                // will be called when the user closes the checkout modal box
            },
        });
    };

    return (
        <>
            <LoadingDialog open={hostedPageId !== undefined} text="Processing subscription" />
            <Grid item xs={12}>
                Create a booking
            </Grid>
            <LevelDateRangeInput
                levels={listing?.levels || []}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
            />

            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-type-label">Select listing level</InputLabel>
                    <Select label="Select listing level" value={selected?.itemPriceId || ""} onChange={onPlanChanged}>
                        {filteredPlans.map((p) => (
                            <MenuItem key={p.itemPriceId} value={p.itemPriceId}>
                                {`${p.externalName} - ${ScoopUtil.formatPriceInCents(p.price)} per day`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <ReferralCodeInput
                listingId={listing?.details?.pkListing}
                appliedReferralCode={appliedReferral}
                constructPremiumListingBody={code => constructPremiumListingBody(activeChargebee, selected, startDate, endDate, code)}
                onReferralCodeUpdate={setAppliedReferral}
            />
            <Grid item xs={12}>
                Booking details
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled
                    label="Booking period"
                    value={`${estimate?.quantity || 0} days`}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled
                    label="Booking price"
                    value={ScoopUtil.formatPriceInCents(estimate?.subTotal)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            {estimate?.localistaDiscount > 0 && <Grid item xs={6}>
                <TextField
                    disabled
                    label="Localista discount"
                    value={ScoopUtil.formatPriceInCents(estimate?.localistaDiscount)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>}


            <Grid item xs={estimate?.localistaDiscount > 0 ? 6 : 12}>
                <TextField
                    disabled
                    label="Referral discount"
                    value={ScoopUtil.formatPriceInCents(estimate?.referralDiscount)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid item xs={estimate?.tax > 0 ? 6 : 12}>
                <TextField
                    disabled
                    label={"Sub total"}
                    value={ScoopUtil.formatPriceInCents(estimate?.subTotal - estimate?.referralDiscount - estimate?.localistaDiscount)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            {estimate?.tax > 0 && <Grid item xs={6}>
                <TextField
                    disabled
                    label="Tax"
                    value={ScoopUtil.formatPriceInCents(estimate?.tax)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>}

            <Grid item xs={12}>
                <TextField
                    disabled
                    label={`Sub total${estimate?.tax > 0 ? " (incl tax)" : ""}`}
                    value={ScoopUtil.formatPriceInCents(estimate?.subTotal - estimate?.referralDiscount - estimate?.localistaDiscount + estimate?.tax)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>


            {estimate?.credits > 0 && <Grid item xs={12}>
                <TextField
                    disabled
                    label="Applied credits"
                    value={ScoopUtil.formatPriceInCents(estimate?.credits)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>}

            <Grid item xs={12}>
                <TextField
                    disabled
                    label={`Total due${estimate?.tax > 0 ? ' (incl tax)' : ''}`}
                    value={ScoopUtil.formatPriceInCents(estimate?.amountDue)}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
                <Button variant="contained" disabled={estimate === undefined} fullWidth onClick={onConfirmClick}>
                    Confirm
                </Button>
            </Grid>
        </>
    );
};

export default PremiumFormEvents;

function constructPremiumListingBody(activeChargebee: ListingLevelLinkDTO, selected: PremiumPlanDTO, startDate: Date, endDate: Date, referralCode?: string): BodyPremiumListing{
    const body: BodyPremiumListing = {
        itemPriceId: selected.itemPriceId,
        itemFamilyId: selected.itemFamilyId,
        itemId: selected.itemId,
        itemType: selected.itemType,
        startDate: startDate ? DateUtil.formatDateString(startDate) : undefined,
        endDate: endDate ? DateUtil.formatDateString(endDate) : undefined,
        currencyCode: selected.currencyCode,
        referralCode: referralCode,
        listingLevelLinkId: activeChargebee?.linkID
    };
    return body;
}
