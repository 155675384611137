import DatePicker from "@mui/lab/DatePicker";
import { Grid, TextField, Typography } from "@mui/material";
import { addDays, areIntervalsOverlapping, isAfter, isBefore, isSameDay, isWithinInterval } from "date-fns";
import React, { useEffect, useState } from "react";
import { ListingLevelLinkDTO } from "../../../../Api/Model";
import ScoopUtil from "../../../../Util/ScoopUtil";

interface Props {
    levels: ListingLevelLinkDTO[];
    onStartDateChange: (startDate: Date) => void;
    onEndDateChange: (endDate: Date) => void;
}

const LevelDateRangeInput = (props: Props) => {
    const { levels, onStartDateChange, onEndDateChange } = props;
    const [startDate, setStartDate] = useState<Date>(null);
    const [endDate, setEndDate] = useState<Date>(null);
    const [disabledDates, setDisabledDates] = useState<Interval[]>([]);
    const [error, setError] = useState<string>(undefined);

    useEffect(() => {
        console.log("LDRI: levels updated", levels);
        let updated = levels
            .filter((lll) => lll.status !== "past")
            .filter((lll) => lll.chargebeeInvoiceID?.length > 0 || lll.chargebeeSubscriptionID?.length > 0)
            .sort((a, b) => {
                var res = a.startDate.localeCompare(b.startDate);
                if (res !== 0) return res;
                return a.endDate.localeCompare(b.endDate);
            })
            .map((lll) => {
                return {
                    start: ScoopUtil.resetHours(new Date(lll.startDate)),
                    end: ScoopUtil.resetHours(new Date(lll.endDate)),
                };
            })
            .filter((i) => !isBefore(i.end, i.start));
        setDisabledDates(updated);
        let start = ScoopUtil.resetHours(new Date());
        let end = ScoopUtil.resetHours(new Date());

        if (updated.length > 0) {
            if(updated.findIndex((dd) => isWithinInterval(start, dd)) >= 0) {
                /* eslint-disable-next-line no-loop-func */
                while (updated.findIndex((dd) => isWithinInterval(start, dd)) >= 0) {
                    start = addDays(start, 1);
                }
                end = start;

            } else {
                end = addDays(updated[0].start, -1);
            }
        } else {
            end = addDays(end, 7);
        }
        setStartDate(start);
        setEndDate(end);
    }, [levels]);

    useEffect(() => {
        if (startDate && endDate) {
            let range = { start: startDate, end: endDate };
            if (isBefore(endDate, startDate)) {
                setError("End Date is before Start Date");
            } else if (disabledDates.findIndex((dd) => areIntervalsOverlapping(range, dd)) >= 0) {
                setError("Selected intervals is in conflict with some existing");
            } else {
                setError(undefined);
            }
        } else {
            setError("Date from or date to is not defined");
        }
    }, [startDate, endDate, disabledDates]);

    const handleStartDateChange = (date: Date) => {
        if(isAfter(date, endDate)){
            setEndDate(date);
        }
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date) => {
        setEndDate(date);
    };

    useEffect(() => {
        onStartDateChange(startDate);
    }, [onStartDateChange, startDate]);

    useEffect(() => {
        onEndDateChange(endDate);
    }, [onEndDateChange, endDate]);

    return (
        <>
            <Grid item xs={6}>
                <DatePicker
                    disablePast
                    label="Start Date"
                    allowSameDateSelection
                    shouldDisableDate={(day) => disabledDates.findIndex((dd) => isWithinInterval(day, dd)) >= 0}
                    value={startDate}
                    onChange={handleStartDateChange}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => <TextField fullWidth {...props} variant={"outlined"} />}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    label="End Date"
                    allowSameDateSelection
                    shouldDisableDate={(day) => {
                        if (disabledDates.findIndex((dd) => isWithinInterval(day, dd)) >= 0) return true;
                        if (isSameDay(day, startDate)) return false;
                        if (isBefore(day, startDate)) return true;
                        let range = { start: startDate, end: day };
                        return disabledDates.findIndex((dd) => areIntervalsOverlapping(range, dd)) >= 0;
                    }}
                    value={endDate}
                    onChange={handleEndDateChange}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => <TextField fullWidth {...props} variant={"outlined"} />}
                />
            </Grid>
            {error && endDate && <Typography color="error">{error}</Typography>}
        </>
    );
};

export default LevelDateRangeInput;
