import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import ApiUsers from '../../Api/ApiUsers';

const useStyles = makeStyles((theme) => ({
    textfield: {
    },
    registerButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        margin: '0 auto',
        display: 'block'
    },
    loginMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loginLink: {
        textDecoration: 'underline',
        marginLeft: theme.spacing(1)
    }
}));

interface ForgotPasswordProps {
    onClickLogin: () => void;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

    const onResetPassword = () => {
        setResetPasswordLoading(true)

        ApiUsers.forgotPassword(email).then(res => {
            alert('Email was sent');
        }).catch(err => console.error(err))
            .finally(() => {
                setResetPasswordLoading(false)
            })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" color="textPrimary" paragraph>Reset Password</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    disabled={resetPasswordLoading}
                    className={classes.textfield}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value as string);
                    }}
                    id="email"
                    label="Email"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    disabled={resetPasswordLoading}
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.registerButton}
                    onClick={onResetPassword}>
                    {resetPasswordLoading ?
                        <CircularProgress size={20} />
                        :
                        <Typography variant="button">Reset Password</Typography>
                    }
                </Button>

                <div className={classes.loginMessage}>
                    <Typography noWrap variant="subtitle2">Already have an account?</Typography>
                    <ButtonBase disableRipple={true} onClick={props.onClickLogin}>
                        <Typography className={classes.loginLink} noWrap variant="subtitle2">
                            Login
                    </Typography>
                    </ButtonBase>
                </div>
            </Grid>

        </Grid>
    )
};

export default ForgotPassword;