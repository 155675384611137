import { Box, Button, Grid, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ListingFullDTO } from '../../Api/Model';
import { activateListingAction, updateListingAction, updateWizardStepAction } from '../../Store/Wizard/actions';
import { selectWizardConfig, selectListing, selectListingModified, selectWizardStep } from '../../Store/Wizard/selectors';
import ScoopUtil from '../../Util/ScoopUtil';
import ReturnButton from './ReturnButton';
import { titleForWizardStep, WizardStep } from './WizardSteps';
import { selectUser } from '../../Store/User/selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    list: {
        paddingTop: 0,
        width: '100%'
    },
    listItem: {
    },
    selectedListItem: {
        fontWeight: 600,
        fontSize: '1.2rem'
    },
    previewButton: {
        width: "100%",
        [theme.breakpoints.up('sm')]: {
            width: "100px",
        }
    },
    uploading: {
        border: '1px solid',
        borderColor: theme.palette.grey[300],
        padding: '5px',
        width: '100%'
    }
}));

interface Props {
    disableAll?: boolean;
    onSaveHook: () => Promise<ListingFullDTO>;
}


const WizardSidebar = (props: Props) => {
    const { disableAll } = props;
    let params = useParams<{ id: any; licenseId: any }>();
    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    const wizardStep = useSelector(selectWizardStep);
    const wizardConfig = useSelector(selectWizardConfig);
    const listing = useSelector(selectListing);
    const modified = useSelector(selectListingModified);
    const user = useSelector(selectUser);

    const dispatch = useDispatch();

    /*
        Force the user to enter a title and type if they are creating it
    */
    const isDisabled = (step: WizardStep): boolean => {
        return disableAll || (wizardStep === WizardStep.Details && step !== WizardStep.Details && !listing)
    }

    const updateListingVisibility = (visibility: boolean) => {
        let promise = props.onSaveHook();
        dispatch(activateListingAction(visibility, params.licenseId, promise));
    };

    const onSaveClick = () => {
        let promise = props.onSaveHook();
        console.log('onSaveClick: ', promise)
        if (promise) {
            dispatch(updateListingAction(promise));
        }
    }

    const onWizardStepSelected = (step: WizardStep) => {
        let promise = props.onSaveHook();
        dispatch(updateWizardStepAction(step, promise));
    }

    return (
        <Grid item md={2} sm={3} xs={12} >
            <Box className={classes.root}>
                <Grid container spacing={1}>
                {!!!widthXS && <Grid item xs={12} >
                <List component="nav" className={classes.list}>
                    {
                        Object.keys(WizardStep)
                            .map((key, index) => WizardStep[key as keyof typeof WizardStep])
                            .filter(step => step !== WizardStep.Dates || wizardConfig.allowEvents)
                            .filter(step => step !== WizardStep.OpeningHours || wizardConfig.allowOpeningHours)
                            .filter(step => step !== WizardStep.Approval || ScoopUtil.allowApprove(listing))
                            .filter(step => step !== WizardStep.Levels || ScoopUtil.allowListingLevels(listing))
                            .filter(step => step !== WizardStep.PremiumLevels || ScoopUtil.allowListingPremiumLevels(user, listing))
                            .map((step, index) => {
                                return (
                                    <ListItem
                                        disabled={isDisabled(step)}
                                        button
                                        key={`step_${step.toString()}`}
                                        className={wizardStep === step ? clsx(classes.listItem, classes.selectedListItem) : classes.listItem}
                                        onClick={() => onWizardStepSelected(step)}>
                                        <ListItemText
                                            id={step}
                                            key={index}
                                            primary={wizardStep === step ?
                                                <Typography className={classes.selectedListItem}>{titleForWizardStep(step)}</Typography>
                                                :
                                                titleForWizardStep(step)
                                            }
                                        />
                                    </ListItem>
                                )

                            })
                    }
                </List>
            </Grid>}

            {widthXS && <Grid item sm={12} xs={6}>
                <ReturnButton
                    onSaveHook={props.onSaveHook}
                    buttonText="Return"
                    disabled={disableAll}
                    fullWidth
                />
            </Grid>}

            <Grid item sm={12} xs={6}>
                <Button disabled={disableAll || !!!modified} onClick={onSaveClick} className={classes.previewButton} variant="contained" color="primary">Save</Button>

            </Grid>
            <Grid item sm={12} xs={6}>
                <Button disabled={disableAll} className={classes.previewButton} href={ScoopUtil.generatePreviewLink(listing?.details?.listingPreviewURL)} target="_blank" variant="contained" color="primary">Preview</Button>

            </Grid>
            <Grid item sm={12} xs={6}>
                <Button
                    className={classes.previewButton}
                    variant="contained"
                    color="primary"
                    disabled={disableAll}
                    onClick={() => updateListingVisibility(!!!listing?.details?.isVisible)}
                >
                    {listing?.details?.isVisible ? 'Deactivate' : 'Activate'}
                </Button>
            </Grid>

            {widthXS && <>
                <Grid item xs={12}>
                    <Select
                        variant='outlined'
                        fullWidth
                        label="Exclusion"
                        value={wizardStep}
                    // onChange={(e) => setExclusion(e.target.value as number)}
                    >
                        {
                            Object.keys(WizardStep)
                                .map((key, index) => WizardStep[key as keyof typeof WizardStep])
                                .filter(step => step !== WizardStep.Dates || wizardConfig)
                                .map((step, index) => {
                                    return (
                                        <MenuItem
                                            value={step}
                                            disabled={isDisabled(step)}
                                            key={`step_${step.toString()}`}
                                            onClick={() => onWizardStepSelected(step)}>
                                            {titleForWizardStep(step)}
                                        </MenuItem>
                                    )

                                })
                        }
                    </Select>
                </Grid>
            </>}





                </Grid>

            </Box>
            
        </Grid>
    )
};

export default WizardSidebar;