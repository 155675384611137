import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useEffect, useState } from "react";
import { Region, RegionDTO } from "../../Api/Model";
import { RegionWithChildren } from "./RegionDropdown";

export const styles = {
    hidden: {
        display: "none",
    },
};

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: "none",
    },
    childTreeList: {
        listStyleType: "none",
        margin: "2.5px 0px",
    },
    child: {
        listStyleType: "bullet",
    },
    toggleButton: {
        width: "25px",
        marginRight: "10px",
        backgroundColor: theme.palette.primary.dark,
    },
    item: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
    },
    selected: {
        padding: "10",
        backgroundColor: theme.palette.primary.dark,
    },
}));

interface Props {
    onlySuburb?: boolean;
    item: Region;
    regionLookup?: Map<number, RegionWithChildren>;
    selectedRegion?: Region;
    expandCallback?: () => void;
    onSelectCallback: (selection: RegionDTO) => void;
}

const RegionItem: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const { item, regionLookup, selectedRegion, expandCallback, onSelectCallback, onlySuburb } = props;
    const [expanded, setExpanded] = useState(false);
    var selected = item.pkRegion === selectedRegion?.pkRegion;
    const ref = React.useRef(null);

    useEffect(() => {
        if (item === selectedRegion) {
            expandSection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ref.current && expanded && item === selectedRegion) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, expanded]);

    const toggleChildren = () => {
        setExpanded(!expanded);
    };

    const expandSection = () => {
        setExpanded(true);
        if (expandCallback) {
            expandCallback();
        }
    };

    var childRegions;
    var children = regionLookup ? regionLookup.get(item.pkRegion).children : [];

    let hasChildern = children.length > 0;

    if (hasChildern) {
        childRegions = (
            <ul className={`${expanded ? "" : classes.hidden}`}>
                {children.map((child) => {
                    return (
                        <RegionItem
                            onlySuburb={onlySuburb}
                            key={child.pkRegion}
                            item={child}
                            regionLookup={regionLookup}
                            selectedRegion={selectedRegion}
                            expandCallback={!selected ? expandSection : null}
                            onSelectCallback={onSelectCallback}
                        />
                    );
                })}
            </ul>
        );
    }
    const canBeSelected = !(onlySuburb && !item.isSuburb);
    return (
        <li ref={ref} className={hasChildern ? classes.childTreeList : classes.child}>
            {hasChildern && (
                <button className={classes.toggleButton} onClick={toggleChildren}>
                    {!expanded ? "+" : "-"}
                </button>
            )}
            <span
                className={selected ? classes.selected : canBeSelected ? classes.item : ""}
                onClick={canBeSelected ? () => onSelectCallback(item) : undefined}
            >
                {item.txtRegionName}
            </span>
            {childRegions}
        </li>
    );
};

export default RegionItem;
