import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Alert } from '@mui/material';
import React, { useState } from 'react';
import ApiListing from '../../../../Api/ApiListing';
import { ListingDTO, Status } from '../../../../Api/Model';
import LoadingDialog from '../../../UI/LoadingDialog';

interface Props {
    open: boolean;
    onSelected: (pkListing: number) => void;
}

const SelectListing = (props: Props) => {
    const { open } = props;
    const [keyword, setKeyword] = useState('');
    const [onlyOwn, setOnlyOwn] = useState(0);
    const [limit, setLimit] = useState(25);
    const [options, setOptions] = useState<ListingDTO[]>([])
    const [loading, setLoading] = useState(false);

    const onSearch = () => {
        setLoading(true);
        setOptions([]);
        ApiListing.getListings(keyword, '', undefined, undefined, undefined, 'approved', Status.Active, false, onlyOwn === 1, limit)
            .then(res => setOptions(res))
            .finally(() => setLoading(false));
    }

    return <>
        {open &&
            <Grid item xs={12} container spacing={1} alignItems="center" justifyContent="center">
                <Grid item sm={8} xs={12}>
                    <TextField
                        variant="outlined"
                        label="Search keyword"
                        placeholder="Keyword"
                        value={keyword}
                        fullWidth
                        onChange={e => setKeyword(e.target.value)}
                    />
                </Grid>
                <Grid item sm={2} xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">Limit</InputLabel>
                        <Select
                            value={limit}
                            label="Limit"
                            onChange={e => setLimit(e.target.value as number)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={250}>250</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={2} xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">Only own</InputLabel>
                        <Select
                            value={onlyOwn}
                            label="Limit"
                            onChange={e => setOnlyOwn(e.target.value as number)}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs>
                    <Button variant="contained" color="primary" onClick={onSearch}>Search</Button>
                </Grid>
                {options.map(l => <Grid item xs={12} container spacing={1}>
                    <Grid item xs={6}>{l.txtListingName}</Grid>
                    <Grid item xs={4}>{l.txtListingTypeName}</Grid>
                    <Grid item xs={2}><Button variant="contained" onClick={() => props.onSelected(l.pkListing)}>Add</Button></Grid>
                     
                </Grid>)}
                {loading && <LoadingDialog text={'Loading listings for selection'} />}
            </Grid>}
        {!!!open && <Alert severity="info">Only 5 listings can be selected</Alert>}
    </>;
};

export default SelectListing;