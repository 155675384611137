import { Autocomplete, Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiAnalytics from "../../../Api/ApiAnalytics";
import { AnalyticsDTO } from "../../../Api/Model";
import { selectListings, selectListingsLoading } from "../../../Store/Dashboard/selectors";
import Loading from "../../UI/Loading";
import HeaderContainer from "../HeaderContainer";
import HeaderTitle from "../HeaderTitle";
import AnalyticsDashboard from "./AnalyticsDashboard";

const useStyles = makeStyles((theme) => ({
    dashboard: {
        backgroundColor: theme.palette.common.white,
        marginTop: "15px",
    },
    section: {
        backgroundColor: theme.palette.grey[400],
    },
    chart: {
        width: "100%",
        height: "400px",
    },
}));

interface ListingOption {
    id: number;
    label: string;
}

interface Props {}

const MyAnalytics = (props: Props) => {
    const classes = useStyles();
    const listings = useSelector(selectListings);
    const loading = useSelector(selectListingsLoading);

    const [selected, setSelected] = useState<ListingOption>(undefined);
    const [analytics, setAnalytics] = useState<AnalyticsDTO>(undefined);
    const [error, setError] = useState<string>(undefined);
    const [options, setOptions] = useState<ListingOption[]>([]);

    useEffect(() => {
        let res: ListingOption[] = [];
        if (listings) {
            res = listings.map((l) => ({ id: l.pkListing, label: l.txtListingName }));
        }
        setOptions(res);
    }, [listings]);

    useEffect(() => {
        if(options?.length > 0) {
            setSelected(options[0]);
        }
    },[options])

    useEffect(() => {
        if (selected?.id > 0) {
            ApiAnalytics.getListingsAnalytics(selected.id)
                .then((res) => {
                    setError(undefined);
                    setAnalytics(res);
                })
                .catch((err) => {
                    setError(err.data || err.message || 'Server error');
                });
        } else {
            setAnalytics(undefined);
        }
    }, [selected]);

    return (
        <>
            <HeaderContainer>
                <HeaderTitle title={"Analytics"} />
            </HeaderContainer>

            <Grid container spacing={1}>
                <Grid item sm={4} xs={8}>
                    {options?.length > 0 &&<FormControl variant="outlined" fullWidth>
                        <Autocomplete
                            disabled={loading}
                            disablePortal
                            id="combo-box-demo"
                            defaultValue={options[0]}
                            value={selected}
                            options={options}
                            getOptionLabel={(o) => o.label}
                            renderInput={(params) => <TextField {...params} label="Listing" />}
                            onChange={(event, value: ListingOption) => setSelected(value)}
                        />
                    </FormControl>}
                </Grid>
            </Grid>

            {analytics && (
                <Box className={classes.dashboard}>
                    <AnalyticsDashboard analytics={analytics} />
                </Box>
            )}
            {!!!analytics && (
                <Box className={classes.dashboard}>
                    {selected && !!!error && <Loading text="Fetching analytics data from server" />}
                    {selected && error && <Typography>{error}</Typography>}
                    {!!!selected && !!!error && <Typography>No listing selected. Please select listing in the box.</Typography>}
                </Box>
            )}
        </>
    );
};

export default MyAnalytics;
