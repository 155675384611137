import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

const HeaderActions = (props: Props) => {
    return (
        <Grid item>
            {props.children}
        </Grid>

    )

}

export default HeaderActions;