import DatePicker from "@mui/lab/DatePicker";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { areIntervalsOverlapping, isBefore, isSameDay, isWithinInterval } from "date-fns";
import { addDays } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ApiListing from "../../../../Api/ApiListing";
import { ListingFullDTO, ListingLevelDTO, ListingLevelLinkDTO } from "../../../../Api/Model";
import { snackMessageCaughtErrorAction } from "../../../../Store/App/actions";
import { approvalsListingUpdatedAction } from "../../../../Store/Dashboard/actions";
import { updateListingSuccessAction } from "../../../../Store/Wizard/actions";
import DateUtil from "../../../../Util/DateUtil";
import LoadingDialog from "../../../UI/LoadingDialog";

interface Props {
    licenseId: number;
    levels: ListingLevelDTO[];
    levelLink: ListingLevelLinkDTO;
    levelLinks: ListingLevelLinkDTO[];
    onClose: () => void;
}

const AddUpdateLevelAdminDialog = (props: Props) => {
    const dispatch = useDispatch();
    const { levelLink, levelLinks, levels, licenseId } = props;
    const [levelId, setLevelId] = useState(levelLink.levelID);
    const [startDate, setStartDate] = useState<Date>(new Date(levelLink.startDate));
    const [endDate, setEndDate] = useState<Date>(new Date(levelLink.endDate));
    const [disabledDates, setDisabledDates] = useState<Interval[]>([]);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<string>(undefined);

    useEffect(() => {
        let mapped = levelLinks
            .filter((lll) => lll.status !== "past" && lll.linkID !== levelLink.linkID)
            .filter((lll) => !(lll.status === "active" && lll.isDefault))
            .map((lll) => {
                return {
                    start: new Date(lll.startDate),
                    end: new Date(lll.endDate),
                };
            })
            .filter((i) => !isBefore(i.end, i.start));
        setDisabledDates(mapped);
    }, [levelLinks, levelLink]);

    useEffect(() => {
        if (levelLink && disabledDates?.length > 0) {
            var updateEnd = false;
            var start = new Date(levelLink.startDate);
            /* eslint-disable-next-line no-loop-func */
            while (disabledDates.findIndex((dd) => isWithinInterval(start, dd)) >= 0) {
                updateEnd = true;
                start = addDays(start, 1);
            }
            setStartDate(start);
            if (updateEnd) setEndDate(start);
        }
    }, [levelLink, disabledDates]);

    const onLevelUpdate = (selectedLevel: number) => {
        setLevelId(selectedLevel);
    };

    useEffect(() => {
        if (startDate && endDate) {
            let range = { start: startDate, end: endDate };
            if (isBefore(endDate, startDate)) {
                setError("End Date is before Start Date");
            } else if (disabledDates.findIndex((dd) => areIntervalsOverlapping(range, dd)) >= 0) {
                setError("Selected intervals is in conflict with some existing");
            } else {
                setError(undefined);
            }
        } else {
            setError("Date from or date to is not defined");
        }
    }, [startDate, endDate, disabledDates]);

    const onSave = async () => {
        setProcessing(true);
        let res: ListingFullDTO;

        try {
            if (levelLink.linkID) {
                res = await ApiListing.updateListingLevel(
                    licenseId,
                    levelLink.linkID,
                    levelLink.listingID,
                    levelId,
                    DateUtil.formatDateString(startDate),
                    DateUtil.formatDateString(endDate)
                );
            } else {
                res = await ApiListing.addListingLevel(
                    licenseId,
                    levelLink.listingID,
                    levelId,
                    DateUtil.formatDateString(startDate),
                    DateUtil.formatDateString(endDate)
                );
            }
            dispatch(updateListingSuccessAction(res));
            if (res.approval) {
                dispatch(approvalsListingUpdatedAction(res.approval));
            }
            props.onClose();
        } catch (err) {
            dispatch(snackMessageCaughtErrorAction(err));
        } finally {
            setProcessing(false);
        }
    };

    const handleStartDateChange = (date: Date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date) => {
        setEndDate(date);
    };

    const handleClose = () => {
        if (!processing) props.onClose();
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Premium Listing Level"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant={"outlined"}>
                                <InputLabel id="select-type-label">Level</InputLabel>
                                <Select
                                    label="Level"
                                    value={levelId}
                                    onChange={(e) => onLevelUpdate(e.target.value as number)}
                                >
                                    {levels.map((l) => (
                                        <MenuItem key={l.pkListingLevel} value={l.pkListingLevel}>
                                            {l.txtListingLevel}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                disablePast={!!!levelLink.isActive}
                                disabled={levelLink.isActive}
                                label="Start Date"
                                allowSameDateSelection
                                shouldDisableDate={(day) =>
                                    disabledDates.findIndex((dd) => isWithinInterval(day, dd)) >= 0
                                }
                                value={startDate}
                                onChange={handleStartDateChange}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(props) => <TextField fullWidth {...props} variant={"outlined"} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label="End Date"
                                allowSameDateSelection
                                shouldDisableDate={(day) => {
                                    if (disabledDates.findIndex((dd) => isWithinInterval(day, dd)) >= 0) return true;
                                    if (isSameDay(day, startDate)) return false;
                                    if (isBefore(day, startDate)) return true;
                                    let range = { start: startDate, end: day };
                                    return disabledDates.findIndex((dd) => areIntervalsOverlapping(range, dd)) >= 0;
                                }}
                                value={endDate}
                                onChange={handleEndDateChange}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(props) => <TextField fullWidth {...props} variant={"outlined"} />}
                            />
                        </Grid>
                    </Grid>
                    {processing && <LoadingDialog text="Saving changes" />}
                    {error && <Typography color="error">{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={processing} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSave} disabled={processing || error !== undefined} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddUpdateLevelAdminDialog;
