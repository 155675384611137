import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
    root: {
        "& code": {
            fontSize: "1.25em",
        },
    },
}));

interface GuideProps {
    markdownFile: string;
}

const Guide = (props: GuideProps) => {
    const classes = useStyles();

    const [markdown, setMarkdown] = useState<string | null>(null);

    useEffect(() => {
        fetch(props.markdownFile)
            .then((res) => {
                return res.text();
            })
            .then((markdown) => {
                setMarkdown(markdown);
            });
    }, [props.markdownFile]);

    if (!markdown) {
        return <React.Fragment />;
    }

    return (
        <ReactMarkdown
            className={classes.root}
            children={markdown}
            linkTarget={"_blank"}
            components={{
                h1: (props: any) => <Typography variant="h6" {...props} />,
                h2: (props: any) => <Typography variant="h6" {...props} />,
                h3: (props: any) => <Typography variant="h6" {...props} />,
                h4: (props: any) => <Typography variant="h6" {...props} />,
                h5: (props: any) => <Typography variant="h6" {...props} />,
                h6: (props: any) => <Typography variant="h6" {...props} />,
            }}
        />
    );
};

export default Guide;
