import React from "react";
import Guide from "./Forms/Guide";

export enum WizardStep {
    Details = "Details",
    Approval = "Approval",
    ContactInformation = "ContactInformation",
    Location = "Location",
    Description = "Description",
    Dates = "Dates",
    LinkedListings = "LinkedListings",
    Categories = "Categories",
    OpeningHours = "OpeningHours",
    Levels = "Levels",
    PremiumLevels = "PremiumLevels",
    Images = "Images",
    Videos = "Videos",

    // Documents = "Documents"
}

export const titleForWizardStep = (step: WizardStep): string => {
    switch (step) {
        case WizardStep.Details:
            return "Title and Type *";

        case WizardStep.Approval:
            return "Approval";

        case WizardStep.ContactInformation:
            return "Contacts";

        case WizardStep.Location:
            return "Location *";

        case WizardStep.Description:
            return "Description *";

        case WizardStep.Dates:
            return "Event Date *";

        case WizardStep.LinkedListings:
            return "Linked Listings";

        case WizardStep.Categories:
            return "Features *";

        case WizardStep.Levels:
            return "Listing Levels";

        case WizardStep.PremiumLevels:
            return "PREMIUM LISTINGS";
    
        case WizardStep.Images:
            return "Images *";

        case WizardStep.Videos:
            return "Videos";

        case WizardStep.OpeningHours:
            return "Opening Hours";

        // case WizardStep.Documents:
        //     return "Documents & Links"
        default:
            throw new Error("Invalid listing creation enum step");
    }
};

export const guideForWizardStep = (step: WizardStep): JSX.Element => {
    switch (step) {
        case WizardStep.Details:
            return <Guide markdownFile="/assets/guides/DetailsGuide.md" />;

        case WizardStep.ContactInformation:
            return <Guide markdownFile="/assets/guides/ContactInformationGuide.md" />;

        case WizardStep.Location:
            return <Guide markdownFile="/assets/guides/LocationGuide.md" />;

        case WizardStep.Description:
            return <Guide markdownFile="/assets/guides/DescriptionGuide.md" />;

        case WizardStep.Dates:
            return <Guide markdownFile="/assets/guides/DatesGuide.md" />;

        case WizardStep.LinkedListings:
            return <Guide markdownFile="/assets/guides/LinkedListingsGuide.md" />;

        case WizardStep.Categories:
            return <Guide markdownFile="/assets/guides/CategoriesAndFeaturesGuide.md" />;

        case WizardStep.Images:
            return <Guide markdownFile="/assets/guides/ImagesGuide.md" />;

        case WizardStep.Videos:
            return <Guide markdownFile="/assets/guides/VideosGuide.md" />;

        default:
            throw new Error("Invalid listing creation enum step");
    }
};
