import { LatLng } from "leaflet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import ApiMetadata from "../../../../Api/ApiMetadata";
import { ListingFullDTO, ListingFeatureDTO, FeaturesMetadataDTO } from "../../../../Api/Model";
import { snackMessageAction } from "../../../../Store/App/actions";
import { selectUser } from "../../../../Store/User/selectors";
import { listingModifiedAction, updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectListing, selectWizardConfig } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import { deepEqual } from "../../../../Util/Util";
import Loading from "../../../UI/Loading";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import { FormFeaturesValue, FeatureValue } from "../form-values";
import FeaturesForm from "./FeaturesForm";

interface Props {}

const WARNING_MESSAGE_TOO_MANY_FEATURES =
    "You have reached the maximum number of values for this listing. To select this value, first deselect a previously selected value";

const FeaturesStep = (props: Props) => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();

    const listing = useSelector(selectListing);
    const user = useSelector(selectUser);
    const allowEdit = useSelector(selectAllowEdit);
    const { allowOpeningHours } = useSelector(selectWizardConfig);
    const [origValues, setOrigValues] = useState<FormFeaturesValue[]>(undefined);
    const [values, setValues] = useState<FormFeaturesValue[]>(undefined);
    const [typeFeatures, setTypeFeatures] = useState<ListingFeatureDTO[]>(undefined);
    const [location, setLocation] = useState<LatLng>(undefined);
    const [featuresMetadata, setFeaturesMetadata] = useState<FeaturesMetadataDTO>(undefined);
    const [limitedFeatures, setLimitedFeatures] = useState<number[]>([]);

    useEffect(() => {
        if (featuresMetadata && listing) {
            let listMeta = featuresMetadata.features
                .filter((f) => f.featureName !== "Online Booking Link") //temp hack to not display online booking feature until is removed from database
                .sort((f1, f2) => {
                    let order = f1.displayOrder - f2.displayOrder;
                    if (order === 0) {
                        order = f1.featureName.localeCompare(f2.featureName);
                    }
                    return order;
                })
                .map((f) => {
                    if (f.values) {
                        f.values = f.values.sort((a, b) => {
                            let order = a.intSeqNo - b.intSeqNo;
                            if (order === 0) {
                                order = a.txtValue.localeCompare(b.txtValue);
                            }
                            return order;
                        });
                    }
                    return f;
                });
            let list: FormFeaturesValue[] = [];
            let orig: FormFeaturesValue[] = [];
            listMeta.forEach((f) => {
                let dto: FormFeaturesValue = {
                    pkFeature: f.pkFeature,
                    pkValues: [],
                };
                let current = listing.values.filter((v) => v.fkFeature === f.pkFeature);
                if (f.isGoogle) {
                    dto.googleValues = current.map((v) => ({ value: v.txtValue } as FeatureValue));
                } else if (f.isEnumerated) {
                    dto.pkValues = current.map((v) => v.fkValue);
                } else if (current.length > 0) {
                    dto.pkValues = [current[0].fkValue];
                    dto.value = current[0].txtValue || "";
                } else {
                    dto.value = "";
                }
                list.push(dto);
                orig.push({ ...dto });
            });
            setOrigValues(orig);
            setValues(list);
            setTypeFeatures(listMeta);
            setLimitedFeatures(listMeta.filter((f) => f.isEnumerated).map((f) => f.pkFeature));
        }
    }, [featuresMetadata, listing]);

    useEffect(() => {
        if (listing && user) {
            ApiMetadata.getListingMetadataFeatures(listing.details.fkListingType)
                .then((res) => setFeaturesMetadata(res))
                .catch((err) => {
                    // todo sort out the error
                });
            if (listing.details.latitude !== 0 && listing.details.longitude !== 0) {
                setLocation(new LatLng(listing.details.latitude, listing.details.longitude));
            }
        }
    }, [listing, user]);

    const formValid = values !== undefined;

    const onValuesChanged = (features: FormFeaturesValue[]) => {
        if (featuresMetadata.categoryLimit > 0 && limitedFeatures.length > 0) {
            let selected = features
                .filter((f) => limitedFeatures.includes(f.pkFeature))
                .map((f) => f.pkValues?.length || 0)
                .reduce((acc, cur) => acc + cur, 0);
            if (selected > featuresMetadata.categoryLimit) {
                dispatch(snackMessageAction(WARNING_MESSAGE_TOO_MANY_FEATURES, "warning", 10000));
                let selectedCurrent = values
                    .filter((f) => limitedFeatures.includes(f.pkFeature))
                    .map((f) => f.pkValues?.length || 0)
                    .reduce((acc, cur) => acc + cur, 0);
                if(selected > selectedCurrent) return;
            }
        }
        setValues(features);
    };

    const generatePromise = (): Promise<ListingFullDTO> => {
        if (formValid) {
            let promise: Promise<ListingFullDTO> = undefined;
            if (!deepEqual(origValues, values)) {
                promise = ApiListing.updateListingFeatures(listing.details.pkListing, values, params.licenseId).then(
                    (res) => {
                        setOrigValues([...values]);
                        return res;
                    }
                );
            }
            return promise;
        }
        return Promise.reject("Form is not valid");
    };

    const onWizardStepChange = (step: WizardStep) => {
        if (formValid) {
            let promise = generatePromise();
            dispatch(updateWizardStepAction(step, promise));
        }
    };

    const onSaveHook = (): Promise<any> => {
        return generatePromise();
    };

    useEffect(() => {
        if (values && origValues) {
            dispatch(listingModifiedAction(!deepEqual(origValues, values)));
        }
    }, [values, origValues, dispatch]);

    return (
        <>
            <WizardHeader disabled={!!!formValid} backToListingsHook={onSaveHook} />
            <WizardSidebar onSaveHook={onSaveHook} />
            <WizardMain>
                {values && typeFeatures ? (
                    <FeaturesForm
                        isAdmin={user?.userLevel === 2}
                        disabled={!allowEdit}
                        values={values}
                        typeFeatures={typeFeatures}
                        onChange={onValuesChanged}
                        location={location}
                    />
                ) : (
                    <Loading />
                )}
                <BackForwardButtons
                    onClickPrevious={() => onWizardStepChange(WizardStep.LinkedListings)}
                    onClickNext={() =>
                        onWizardStepChange(
                            allowOpeningHours ? WizardStep.OpeningHours : ScoopUtil.allowListingPremiumLevels(user, listing) ? WizardStep.PremiumLevels : ScoopUtil.allowListingLevels(listing) ? WizardStep.Levels : WizardStep.Images
                        )
                    }
                />
            </WizardMain>
            <WizardGuide step={WizardStep.Categories} />
        </>
    );
};

export default FeaturesStep;
