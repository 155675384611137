import { Box, Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { ListingFullDTO } from '../../../../Api/Model';
import DateUtil from '../../../../Util/DateUtil';
import ScoopUtil from '../../../../Util/ScoopUtil';

const useStyles = makeStyles((theme) => ({
    label: {
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            width: '180px',
        },
    },
    row: {
        marginBottom: '10px'
    }
}));

interface Props {
    listing: ListingFullDTO;
}

const ListingDetailsSection = (props: Props) => {
    const { listing  } = props;
    const { details } = listing;

return (<>
        <Grid item xs={12}>
            <Line label='Listing ID' value={details.pkListing} />
            <Line label='Listing Owner' value={details.userDisplayname}/>
            <Line label='Created date' value={DateUtil.formatDateFromString(details.dtCreated)}/>
            <Line label='Approval status' value={ScoopUtil.scoopStatusFromListingDetails(listing)}/>
            <Line label='Last approved date' value={DateUtil.formatDateFromString(details.dtApproved)}/>
            <Line label='Default listing level' value={details.defaultListingLevel}/>
            <Line label='Active listing level' value={details.txtListingLevel}/>
            <Line label='Last updated date' value={DateUtil.formatDateFromString(details.dtLastUpdated)}/>
        </Grid>
    </>
    )
};

const Line = (props: {label: string, value: string | number}) : JSX.Element => {
    const classes = useStyles();
    return <Box className={classes.row} display="flex"><div className={classes.label}>{props.label}:</div>{props.value}</Box>
}

export default ListingDetailsSection;