import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";
import { ListingDirectoryDTO, ListingTypeDTO } from "../../../../Api/Model";
import { FormDetailsValues } from "../form-values";

interface Props {
    disabled: boolean;
    types: ListingTypeDTO[];
    directories: ListingDirectoryDTO[];
    values: FormDetailsValues;
    onChange: (values: FormDetailsValues) => void;
}

const DetailsForm = (props: Props) => {
    const { disabled, types, directories, values, onChange } = props;
    const { title, typeId, directoryId } = values;

    const showTitleError = title.length <= 0;
    const showTypeError = typeId <= 0;
    const showDirectoryError = directoryId <= 0;

    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...values, title: e.target.value });
    };

    const onTypeChange = (e: SelectChangeEvent<Number>) => {
        const value = Number(e.target.value);
        onChange({ ...values, typeId: value });
    };

    const onDirectoryChange = (e: SelectChangeEvent<Number>) => {
        const value = Number(e.target.value);
        onChange({ ...values, directoryId: value, typeId: 0 });
    };

    return (
        <>
            <Grid item md={6} xs={12}>
                <FormControl disabled={disabled} error={showDirectoryError} variant="outlined" fullWidth>
                    <InputLabel id="select-directory">Directory</InputLabel>
                    <Select label="Select Directory" value={directoryId || 0} onChange={onDirectoryChange}>
                        {directories.map((directory) => {
                            return (
                                <MenuItem key={directory.pkListingTypeGroupId} value={directory.pkListingTypeGroupId}>
                                    {directory.listingTypeGroupName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {showDirectoryError ? <FormHelperText>Please select a directory</FormHelperText> : null}
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
                <FormControl disabled={disabled} error={showTypeError} variant="outlined" fullWidth>
                    <InputLabel id="select-type-label">Listing Type</InputLabel>
                    <Select label="Select type" value={values.typeId} onChange={onTypeChange}>
                        {types
                            .filter((t) => t.pkListingTypeGroup === directoryId)
                            .map((type) => {
                                return (
                                    <MenuItem
                                        key={type.pkListingType + "-" + type.txtTypeName}
                                        value={type.pkListingType}
                                    >
                                        {type.txtTypeName}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {showTypeError ? <FormHelperText>Please select a type</FormHelperText> : null}
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    disabled={disabled}
                    error={showTitleError}
                    helperText={showTitleError ? "Please enter a title for your listing" : null}
                    label="Listing Title"
                    placeholder="Listing Title"
                    value={values.title}
                    onChange={onTitleChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
        </>
    );
};

export default DetailsForm;
