import React from 'react';
import InfoDialog from './InfoDialog';


interface Props {
  onClose: () => void;
}

const ListingActivatedDialog = (props: Props) => {
  const { onClose } = props;
  return (
    <InfoDialog
      message={'Thanks for submitting your listing. Your listing has been sent to the localista content team for approval. New listings are reviewed daily and you will be notified by email.'}
      onClose={onClose}
    />
  );
}

export default ListingActivatedDialog;