import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import ApiAnalytics from "../../Api/ApiAnalytics";
import { AnalyticsDTO } from "../../Api/Model";
import Loading from "../UI/Loading";
import AnalyticsDashboard from "./Analytics/AnalyticsDashboard";

interface Props {
    listingId: number;
    licenseId?: number;
    onClose: () => void;
}

const AnalyticsDialog = (props: Props) => {
    const { listingId, licenseId, onClose } = props;
    const [analytics, setAnalytics] = useState<AnalyticsDTO>(undefined);
    const [error, setError] = useState<string>(undefined);

    useEffect(() => {
        ApiAnalytics.getListingsAnalytics(listingId, licenseId)
            .then((res) => {
                setAnalytics(res);
            })
            .catch((err) => {
                setError(err.data || err.message || "Server error");
            });
    }, [listingId, licenseId]);

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Listing Analytics"}</DialogTitle>
            <DialogContent>
                {!!!analytics && !!!error && <Loading text="Fetching analytics data from server" />}
                {!!!analytics && error && <Typography>{error}</Typography>}
                {analytics && <AnalyticsDashboard analytics={analytics} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AnalyticsDialog;
