import DatePicker from "@mui/lab/DatePicker";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import { LatLng } from "leaflet";
import React, { useState } from "react";
import { ListingFeatureDTO } from "../../../../Api/Model";
import { deepCopy } from "../../../../Util/Util";
import AutocompleteGooglePlaces from "../../../UI/AutocompleteGooglePlaces";
import { FeatureValue, FormFeaturesValue } from "../form-values";

const useStyles = makeStyles((theme) => ({
    multiSelectLabel: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        paddingBottom: "6px",
        marginTop: "16px",
    },
}));

const onTextfieldUpdate = (values: FormFeaturesValue[], pkFeature: number, value: string): FormFeaturesValue[] => {
    let copy = deepCopy(values).map((f) => {
        if (f.pkFeature === pkFeature) {
            f.value = value;
        }
        return f;
    });
    return copy;
};

const onEnumeratedUpdate = (
    values: FormFeaturesValue[],
    pkFeature: number,
    pkValue: number,
    checked: boolean
): FormFeaturesValue[] => {
    let copy = deepCopy(values);
    let index = copy.findIndex((f) => f.pkFeature === pkFeature);
    if (index >= 0) {
        let ids = [];
        if (copy[index].pkValues) {
            ids = [...copy[index].pkValues];
        }
        if (checked && !ids.includes(pkValue)) {
            ids.push(pkValue);
        }
        if (!!!checked && ids.includes(pkValue)) {
            ids = ids.filter((i) => i !== pkValue);
        }
        if (ids.length > 0) {
            copy[index].pkValues = ids;
        } else {
            copy[index].pkValues = [];
        }
    }
    return copy;
};

const onEnumeratedSingleUpdate = (
    values: FormFeaturesValue[],
    pkFeature: number,
    pkValue?: number
): FormFeaturesValue[] => {
    let copy = deepCopy(values);
    let index = copy.findIndex((f) => f.pkFeature === pkFeature);
    if (index >= 0) {
        copy[index].pkValues = [pkValue];
    } else {
        copy.push({
            pkFeature: pkFeature,
            pkValues: [pkValue],
        });
    }
    return copy;
};

const onAddGooglePlace = (
    values: FormFeaturesValue[],
    pkFeature: number,
    value: string,
    manually: boolean
): FormFeaturesValue[] => {
    let copy = deepCopy(values);
    let index = copy.findIndex((f) => f.pkFeature === pkFeature);
    let gv: FeatureValue = { value: value, sourceGoogle: !manually };
    if (index >= 0) {
        copy[index].googleValues.push(gv);
    } else {
        copy.push({
            pkFeature: pkFeature,
            pkValues: [],
            googleValues: [gv],
        });
    }
    return copy;
};

const onRemoveGooglePlace = (values: FormFeaturesValue[], pkFeature: number, value: string): FormFeaturesValue[] => {
    let copy = [...values];
    let index = copy.findIndex((f) => f.pkFeature === pkFeature);
    if (index >= 0) {
        copy[index].googleValues = copy[index].googleValues.filter((gv) => gv.value !== value);
    }
    return copy;
};

interface Props {
    disabled: boolean;
    location?: LatLng;
    feature: ListingFeatureDTO;
    values: FormFeaturesValue[];
    onChange: (features: FormFeaturesValue[]) => void;
}

const FeatureInput = (props: Props) => {
    const { feature, values, onChange, location } = props;
    const classes = useStyles();
    const [manually, setManually] = useState(false);
    const [googleValue, setGoogleValue] = useState("");
    let resetAutocomplete = null;
    const value = values.find((ff) => ff.pkFeature === feature.pkFeature);
    const disabled = props.disabled || feature.isVenueLocation;

    const onAddGoogle = () => {
        onChange(onAddGooglePlace(values, feature.pkFeature, googleValue, manually));
        if (resetAutocomplete) resetAutocomplete();
        setGoogleValue("");
    };

    if (feature.isGoogle) {
        let autocompleteDisabled = !!!feature.multipleGoogleValues && value.googleValues?.length > 0;
        return (
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <InputLabel>{feature.featureName}</InputLabel>
                    {feature.helpNotes && <FormHelperText>{feature.helpNotes}</FormHelperText>}
                </Grid>
                <Grid item xs={10}>
                    {manually ? (
                        <TextField
                            fullWidth
                            disabled={disabled || autocompleteDisabled}
                            onChange={(e) => setGoogleValue(e.target.value)}
                            value={googleValue}
                        />
                    ) : (
                        <AutocompleteGooglePlaces
                            fullWidth
                            disabled={disabled || autocompleteDisabled}
                            passResetState={(fun) => (resetAutocomplete = fun)}
                            label={""}
                            onChange={(place) => setGoogleValue(place?.name || "")}
                            location={location}
                            radius={100000}
                        />
                    )}
                </Grid>
                <Grid item xs={2} alignSelf="center" textAlign="center">
                    <Button
                        disabled={googleValue.length === 0}
                        onClick={onAddGoogle}
                        variant="contained"
                        color="primary"
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={disabled || autocompleteDisabled}
                                checked={manually}
                                color="primary"
                                onChange={(e) => setManually(!manually)}
                                name={"Select to enter manually"}
                            />
                        }
                        label={"Select to enter manually"}
                    />
                </Grid>
                {value?.googleValues.map((gv) => (
                    <Grid item container xs={12} minHeight={50} justifyContent="center" alignContent={"center"}>
                        <Grid item xs={10}>
                            <Typography key={gv.value}>{gv.value}</Typography>
                        </Grid>
                        <Grid item xs={2} alignSelf="center" textAlign="center">
                            <Button
                                disabled={disabled}
                                onClick={() => onChange(onRemoveGooglePlace(values, feature.pkFeature, gv.value))}
                                variant="outlined"
                                size="small"
                            >
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    } else if (feature.isEnumerated && feature.allowMultiple) {
        return (
            <Grid item container xs={12}>
                <Grid item xs={12} className={classes.multiSelectLabel}>
                    <InputLabel>{feature.featureName}</InputLabel>
                    {feature.helpNotes && <FormHelperText>{feature.helpNotes}</FormHelperText>}
                </Grid>
                {feature.values.map((v) => (
                    <Grid key={`mfi_${v.pkValue}`} item xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={value?.pkValues.includes(v.pkValue)}
                                    color="primary"
                                    onChange={(e) =>
                                        onChange(
                                            onEnumeratedUpdate(values, feature.pkFeature, v.pkValue, e.target.checked)
                                        )
                                    }
                                    name={v.txtValue}
                                />
                            }
                            label={v.txtValue}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    } else if (feature.isEnumerated && !!!feature.allowMultiple) {
        var selected: number = 0;
        if (value?.pkValues?.length > 0) {
            selected = value.pkValues[0];
        }
        return (
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <InputLabel>{feature.featureName}</InputLabel>
                    {feature.helpNotes && <FormHelperText>{feature.helpNotes}</FormHelperText>}
                </Grid>
                <Grid item xs={12}>
                    <Select
                        disabled={disabled}
                        fullWidth
                        variant="outlined"
                        value={selected}
                        onChange={(e) =>
                            onChange(onEnumeratedSingleUpdate(values, feature.pkFeature, e.target.value as number))
                        }
                    >
                        <MenuItem value={0}>
                            <em>Select</em>
                        </MenuItem>
                        {feature.values.map((v) => (
                            <MenuItem key={`mmi_${v.pkValue}`} value={v.pkValue}>
                                {v.txtValue}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        );
    } else if (feature.isDateOnly) {
        return (
            <Grid item xs={12}>
                <DatePicker
                    label={feature.featureName}
                    inputFormat={"dd MMM yyyy"}
                    value={value?.value}
                    onChange={(newValue: Date) =>
                        onChange(onTextfieldUpdate(values, feature.pkFeature, format(newValue, "dd MMM yyyy")))
                    }
                    renderInput={(props) => <TextField fullWidth {...props} />}
                />
            </Grid>
        );
    } else {
        const type = feature.isNumeralOnly ? "number" : "text";
        const prefix = feature.prefix ? <InputAdornment position="start">{feature.prefix}</InputAdornment> : undefined;
        const sufix = feature.suffix ? <InputAdornment position="end">{feature.suffix}</InputAdornment> : undefined;
        return (
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: prefix,
                        endAdornment: sufix,
                    }}
                    type={type}
                    disabled={disabled}
                    variant="outlined"
                    label={feature.featureName}
                    value={value?.value}
                    fullWidth
                    onChange={(e) => onChange(onTextfieldUpdate(values, feature.pkFeature, e.target.value))}
                    helperText={feature.helpNotes}
                />
            </Grid>
        );
    }
};

export default FeatureInput;
