import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApiListing from "../../../../Api/ApiListing";
import { ImageDTO, ListingFullDTO } from "../../../../Api/Model";
import { updateListingSuccessAction } from "../../../../Store/Wizard/actions";
import { selectAllowEdit, selectListing } from "../../../../Store/Wizard/selectors";
import LoadingDialog from "../../../UI/LoadingDialog";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import AddUpdateImage from "./AddUpdateImage";
import ImageCard from "./ImageCard";
import SelectImage from "./SelectImage";

interface ImagesFormProps {
    onEditModeChange: (editMode: boolean) => void;
}

const ImagesForm = (props: ImagesFormProps) => {
    const { onEditModeChange } = props;
    let params = useParams<{ id: any; licenseId: any }>();
    const dispatch = useDispatch();
    const listing = useSelector(selectListing);
    const allowEdit = useSelector(selectAllowEdit);

    const [selectedFile, setSelectedFile] = useState<File>(undefined);
    const [selectedImage, setSelectedImage] = useState<ImageDTO>(undefined);

    const [saving, setSaving] = useState(false);

    const onUpdateFinish = () => {
        setSelectedFile(undefined);
        setSelectedImage(undefined);
    };

    const executePromise = (promise: Promise<ListingFullDTO>) => {
        setSaving(true);
        promise
            .then((res) => {
                dispatch(updateListingSuccessAction(res));
            })
            .catch((err) => console.log(err))
            .finally(() => setSaving(false));
    };

    const onDeleteImage = (pkImage: number) => {
        executePromise(ApiListing.deleteImage(listing.details.pkListing, pkImage, params.licenseId));
    };

    const onEditImage = (image: ImageDTO) => {
        setSelectedImage(image);
    };

    const onUp = (image: ImageDTO) => {
        executePromise(ApiListing.updateImageOrder(listing.details.pkListing, image.pkImage, true, params.licenseId));
    };

    const onDown = (image: ImageDTO) => {
        executePromise(ApiListing.updateImageOrder(listing.details.pkListing, image.pkImage, false, params.licenseId));
    };

    const onPrimaryClick = (image: ImageDTO) => {
        executePromise(
            ApiListing.updateImage(listing.details.pkListing, image.txtCaption, image.txtCredit, true, image.pkImage, params.licenseId)
        );
    };


    const onShowImagesFirstClick = (e: any, checked: boolean) => {
        executePromise(ApiListing.updateVideoPreference(listing.details.pkListing, checked, params.licenseId));
    };



    useEffect(() => {
        onEditModeChange(selectedFile !== undefined || selectedImage !== undefined);
    }, [selectedFile, selectedImage, onEditModeChange]);

    if (selectedFile || selectedImage) {
        return (
            <AddUpdateImage
                pkListing={listing.details.pkListing}
                file={selectedFile}
                image={selectedImage}
                onFinish={onUpdateFinish}
            />
        );
    }

    return (
        <>
            <FormGrid>
                <SectionGrid title={"Primary Image"}>
                    <Grid item xs>
                        <FormControl variant="outlined" fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={listing?.details?.bitShowImagesFirst}
                                        color="primary"
                                        onChange={onShowImagesFirstClick}
                                        name={"showImagesFirst"}
                                    />
                                }
                                label={"Display primary image before video"}
                            />
                        </FormControl>
                    </Grid>
                    {listing?.images
                        .sort((a, b) => a.imagePosition - b.imagePosition)
                        .map((image, index) => (
                            <Grid key={image.pkImage} item xs={12} md={image.isPrimary ? 12 : 6}>
                                <ImageCard
                                    disabled={!allowEdit}
                                    image={image}
                                    primary={image.isPrimary}
                                    onDeleteClick={() => onDeleteImage(image.pkImage)}
                                    onEditClick={() => onEditImage(image)}
                                    onUpClick={index > 1 ? () => onUp(image) : undefined}
                                    onDownClick={
                                        index > 0 && index < listing?.images.length - 1
                                            ? () => onDown(image)
                                            : undefined
                                    }
                                    onPrimaryClick={() => onPrimaryClick(image)}
                                />
                            </Grid>
                        ))}
                    {allowEdit && (
                        <Grid item xs={12} md={listing?.images?.length > 0 ? 6 : 12}>
                            <SelectImage
                                minHeight={900}
                                minWidth={1200}
                                onFileSelected={(file) => {
                                    setSelectedFile(file);
                                }}
                                onError={alert}
                            />
                        </Grid>
                    )}
                </SectionGrid>
            </FormGrid>
            {saving && <LoadingDialog text={"Processing updates"} />}
        </>
    );
};

export default ImagesForm;
