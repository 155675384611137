import { AppState } from '../root-reducer';

export const selectUsers = (state: AppState) => state.usersReducer.users;

export const selectUsersLoading = (state: AppState) => state.usersReducer.loading;

export const selectUsersError = (state: AppState) => state.usersReducer.error;

export const selectUsersTableSetting = (state: AppState) => state.usersReducer.tableSetting;

export const selectUsersFilter = (state: AppState) => state.usersReducer.filter;

export const selectSelectedUser = (state: AppState) => state.usersReducer.selected;