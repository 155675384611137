const local = {
    ENV: " (local) ",
    API_URL: "http://localhost:7071/api/v1",
    BUCKET_URL: "https://scoopwebsitetest.blob.core.windows.net",
    SENTRY: "",
    WIDGET_SERVER: "localhost",
    CHARGEBEE_SITE: "localista-test",
};

const test = {
    ENV: " (test) ",
    API_URL: "https://localistaapidev.azurewebsites.net/api/v1",
    API_URL_OR: "https://api-dev-2.localista.com.au/api/v1",
    BUCKET_URL: "https://scoopwebsitetest.blob.core.windows.net",
    SENTRY: "https://e4ed1442e5bc4f08a54b8147ad48c006@o501206.ingest.sentry.io/5582329",
    WIDGET_SERVER: "dev",
    CHARGEBEE_SITE: "localista-test",
};

const prod = {
    ENV: "",
    API_URL: "https://api.localista.com.au/api/v1",
    BUCKET_URL: "https://scoopwebsite.blob.core.windows.net",
    SENTRY: "https://849423f9ec274e5987d4d98fb5a73d57@o501206.ingest.sentry.io/5583400",
    WIDGET_SERVER: "prod",
    CHARGEBEE_SITE: "localista",
};

const preProd = {
    ENV: "",
    API_URL: "https://api-pre-prod.localista.com.au/api/v1",
    BUCKET_URL: "https://scoopwebsite.blob.core.windows.net",
    SENTRY: "https://849423f9ec274e5987d4d98fb5a73d57@o501206.ingest.sentry.io/5583400",
    WIDGET_SERVER: "pre-prod",
    CHARGEBEE_SITE: "localista",
};

const config =
    process.env.REACT_APP_STAGE === "prod"
        ? prod
        : process.env.REACT_APP_STAGE === "pre-prod"
        ? preProd
        : process.env.REACT_APP_STAGE === "test"
        ? test
        : local;

const merged = {
    MAP_GOOGLE_SATELLITE: "https://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    MAP_GOOGLE_STREET: "https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    MAP_GOOGLE_HYBRID: "https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
    GOOGLE_API_KEY: "AIzaSyBdLZyL-io5uGdVH1on57bMWOAjrQNnC-E",
    // Add common config values here
    ...config,
};

export default merged;
