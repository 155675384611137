import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWizardStepAction } from "../../../../Store/Wizard/actions";
import { selectListing, selectWizardConfig } from "../../../../Store/Wizard/selectors";
import ScoopUtil from "../../../../Util/ScoopUtil";
import WizardGuide from "../../WizardGuide";
import WizardHeader from "../../WizardHeader";
import WizardMain from "../../WizardMain";
import WizardSidebar from "../../WizardSidebar";
import { WizardStep } from "../../WizardSteps";
import BackForwardButtons from "../BackForwardButtons";
import ImagesForm from "./ImagesForm";
import { selectUser } from "../../../../Store/User/selectors";

const ImagesStep = () => {
    const listing = useSelector(selectListing);
    const user = useSelector(selectUser);
    const { allowOpeningHours } = useSelector(selectWizardConfig);

    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();

    const onWizardStepChange = (step: WizardStep) => {
        if (!!!editMode) {
            dispatch(updateWizardStepAction(step));
        }
    };

    const onSaveHook = (): Promise<any> => {
        return undefined;
    };

    return (
        <>
            <WizardHeader disabled={editMode} backToListingsHook={onSaveHook} />
            <WizardSidebar disableAll={editMode} onSaveHook={onSaveHook} />
            <WizardMain>
                <ImagesForm onEditModeChange={setEditMode} />
                <BackForwardButtons
                    disabled={editMode}
                    onClickPrevious={() =>
                        onWizardStepChange(
                            ScoopUtil.allowListingPremiumLevels(user, listing) ? WizardStep.PremiumLevels : ScoopUtil.allowListingLevels(listing) ? WizardStep.Levels : allowOpeningHours ? WizardStep.OpeningHours : WizardStep.Categories
                        )
                    }
                    onClickNext={() => onWizardStepChange(WizardStep.Videos)}
                />
            </WizardMain>
            <WizardGuide step={WizardStep.Images} />
        </>
    );
};

export default ImagesStep;
