import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { ClickAwayListener, Link } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserDTO } from '../../../Api/Model';
import { actionUserSelected, actionUsersTableSettingUpdated } from '../../../Store/Users/actions';
import { selectUsersTableSetting } from '../../../Store/Users/selectors';
import DateUtil from '../../../Util/DateUtil';
import EnhancedTable, { HeadCell, TableSetting } from '../../UI/EnhancedTable';
import UsersActionButton from './UsersActionButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cell: {
            padding: '0px 10px'
        },
        cellListingName: {
            cursor: 'pointer',
            fontWeight: 'bold'
        },
        cellActive: {
            cursor: 'pointer'
        },
        cellDate: {
            whiteSpace: 'nowrap'
        }
    }),
);

interface Props {
    list: UserDTO[];
}

const headCells: HeadCell[] = [
    { id: 'userName', sortable: true, disablePadding: false, label: 'User login', align: 'left' },
    { id: 'userType', sortable: true, disablePadding: false, label: 'User Type', align: 'left', minWidth: 120 },
    { id: 'displayName', sortable: true, disablePadding: false, label: 'Display Name', align: 'left' },
    { id: 'company', sortable: true, disablePadding: false, label: 'Company', align: 'left' },
    { id: 'clientName', sortable: true, disablePadding: false, label: 'License group', align: 'left' },
    { id: 'lastLogin', sortable: true, disablePadding: false, label: 'Last login', align: 'left', minWidth: 120 },
    { id: 'listings', sortable: true, disablePadding: false, label: 'Active listings', align: 'center'},
    { id: 'active', disablePadding: false, label: 'Active' },
    { id: 'actions', disablePadding: false, label: 'Actions' }
];


const DataTable = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { list } = props;
    const [opened, setOpened] = useState<number>(undefined);
    const tableSetting = useSelector(selectUsersTableSetting);
    const setTableSetting = (setting: TableSetting) => dispatch(actionUsersTableSettingUpdated(setting));

    const onEdit = async (user: UserDTO) => {
        dispatch(actionUserSelected(user));
    }

    const mapRow = (user: UserDTO, index: number): JSX.Element => {

        return (
            <TableRow hover tabIndex={-1} key={user.id}>
                <TableCell onClick={() => onEdit(user)} className={`${classes.cell} ${classes.cellListingName}`}>
                    <Link style={{ color: 'blue' }}>{user.userName}</Link>
                </TableCell>
                <TableCell className={`${classes.cell}`}>{user.userType}</TableCell>
                <TableCell className={`${classes.cell}`}>{user.displayName}</TableCell>
                <TableCell className={`${classes.cell}`} >{user.company}</TableCell>
                <TableCell className={`${classes.cell}`}>{user.clientName}</TableCell>
                <TableCell padding='none' align='center' className={`${classes.cell} ${classes.cellDate}`}>{DateUtil.formatDateFromString(user.lastLogin)}</TableCell>
                <TableCell className={`${classes.cell}`}>{user.listings}</TableCell>
                <TableCell className={`${classes.cell} ${classes.cellActive}`} align="center">{user.active ? <CheckIcon htmlColor="green" /> : <ClearIcon htmlColor="red" />}</TableCell>
                <TableCell className={`${classes.cell}`}>
                    <UsersActionButton opened={opened} onOpen={setOpened} user={user} />
                </TableCell>
            </TableRow>
        );
    }

    const handleClickAway = () => {
        if (opened) setOpened(undefined)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <EnhancedTable
                    headCells={headCells}
                    rows={list || []}
                    mapRow={mapRow}
                    tableSetting={tableSetting}
                    onTableSettingChange={setTableSetting}
                />
            </div>
        </ClickAwayListener>
    );
}

export default DataTable;