import { Button, Grid } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiListing from '../../../Api/ApiListing';
import { ListingLevelDTO, ListingTypeDTO, ListingWebsiteDTO } from '../../../Api/Model';
import { selectListingLevels, selectTypes } from '../../../Store/App/selectors';
import { fetchMyWebsiteListingsAction } from '../../../Store/Dashboard/actions';
import { selectMyWebsiteListings, selectMyWebsiteListingsLoading, selectMyWebsiteSelectedLicense } from '../../../Store/Dashboard/selectors';
import LoadingDialog from '../../UI/LoadingDialog';
import HeaderActions from '../HeaderActions';
import HeaderContainer from '../HeaderContainer';
import HeaderTitle from '../HeaderTitle';
import DataTable from './DataTable';
import Filters from './Filters';

const MyWebsite = () => {

    const dispatch = useDispatch();
    const listingLevels = useSelector(selectListingLevels);
    const loading = useSelector(selectMyWebsiteListingsLoading);
    const listings = useSelector(selectMyWebsiteListings);
    const licenseId = useSelector(selectMyWebsiteSelectedLicense);

    const types = useSelector(selectTypes);

    const [availableTypes, setAvailableTypes ] = useState<ListingTypeDTO[]>([]);

    const [list, setList] = useState<ListingWebsiteDTO[]>([]);
    const [filtered, setFiltered] = useState<ListingWebsiteDTO[]>([]);

    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if(listings && listingLevels) {
            let mapped = listings.map(listing => {
                let listingLevel: ListingLevelDTO = listingLevels.filter(l => l.pkListingLevel === listing.fkListingLevel).pop();
                let clientListingLevel: ListingLevelDTO = listingLevels.filter(l => l.pkListingLevel === listing.fkClientListingLevel).pop();
                listing.clientListingLevel = clientListingLevel ? clientListingLevel.intPriority : 0;
                listing.listingLevel = listingLevel ? listingLevel.intPriority : 0;
                return listing;
            });
            setList(mapped);
        }

    }, [listings, listingLevels])

    useEffect(() => {
        if(listings && types) {
            let fkTypes = listings.map(l => l.fkListingType);
            setAvailableTypes(types.filter(lt => fkTypes.includes(lt.pkListingType)))

        } else {
            setAvailableTypes([]);
        }
    },[listings, types])

    const onExcludeAll = async (exclude: boolean, ids: number[]) => {
        setProcessing(true);
        try {
            await ApiListing.updateListingsExclusion(exclude, ids);
            dispatch(fetchMyWebsiteListingsAction(licenseId));
        } catch (err) {
            //todo error handeling
        } finally {
            setProcessing(false)
        }
    }

    const refresh = () => {
        dispatch(fetchMyWebsiteListingsAction(licenseId));
    }

    return (
        <>
            <HeaderContainer>
                <HeaderTitle title={'My Website'} markdownHelp={'/assets/help/MyWebsite.md'}/>
                <HeaderActions>
                    <Button variant='contained' color="primary" onClick={refresh} disabled={loading}><RefreshIcon/> Refresh</Button>
                </HeaderActions>
            </HeaderContainer>
            <Grid container spacing={1}>
                <Filters
                    types={availableTypes}
                    list={list}
                    onFilterUpdate={setFiltered}
                />
                {loading && <Grid item xs={12}><LinearProgress /></Grid>}
                <Grid item xs={12}>
                    {filtered && <DataTable list={filtered} onExcludeAll={onExcludeAll}/>}
                </Grid>
            </Grid>
            {processing && <LoadingDialog text="Processing request" />}
        </>
    )
};

export default MyWebsite;