import { format } from "date-fns";
export default class DateUtil {
  static formatDateFromString(value?: string): string {
    if (value) return this.formatDate(new Date(value));
    return "";
  }

  static formatDate(date?: Date): string {
    if (date) {
      return format(date, "dd MMM yyyy");
    }
    return "";
  }

  static resetToMidnight(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static formatDateString(date: Date): string {
    return format(date, "yyyy-MM-dd");
  }

  static parseTime(time: string): Date {
    if (time.length === 5) {
      return new Date(`1970-01-01T${time}`);
    } else {
      return new Date(`1970-01-01T0${time}`);
    }
  }

  static formatTime(time: Date): string {
    let res = format(time, "HH:mm");
    if (res.startsWith("0")) {
      return res.substring(1);
    }
    return res;
  }

}
