import { Grid } from '@mui/material';
import React from 'react';
import FloatingCard from './Forms/FloatingCard';
import { guideForWizardStep, WizardStep } from './WizardSteps';

interface Props {
    step?: WizardStep;
}

const WizardGuide = (props: Props) => {

    return (
        <Grid item md={4} xs={12}>
            {props.step && <FloatingCard>
                {guideForWizardStep(props.step)}
            </FloatingCard>}
        </Grid>
    )
}

export default WizardGuide;