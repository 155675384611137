import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import { ListingLevelLinkDTO } from "../../../../Api/Model";
import ActionButton, { QuickAction } from "../../../UI/ActionButton";

interface Props {
    levelLink: ListingLevelLinkDTO;
    opened?: number;
    onOpen: (linkID?: number) => void;
    onCancel: (levelLink: ListingLevelLinkDTO) => void;
    onEdit: (levelLink: ListingLevelLinkDTO) => void;
    onShowTransactions: (levelLink: ListingLevelLinkDTO) => void;
}

const AdminActionButton = (props: Props) => {
    const { levelLink, opened, onCancel, onEdit, onShowTransactions } = props;
    const open = opened === levelLink.linkID;

    const isChargebee = levelLink.chargebeeInvoiceID?.length > 0 || levelLink.chargebeeSubscriptionID?.length > 0;
    const isEditable = !isChargebee && levelLink.status !== "past";

    const toggle = () => {
        props.onOpen(open ? undefined : levelLink.linkID);
    };

    if(!isChargebee && !isEditable) return null;

    return (
        <ActionButton open={open} onToggle={toggle}>
            {isChargebee && (
                <QuickAction icon={<EditIcon />} label={"Show details"} onClick={() => onShowTransactions(levelLink)} />
            )}
            {isEditable && <QuickAction icon={<EditIcon />} label={"Edit"} onClick={() => onEdit(levelLink)} />}
            {isEditable && <QuickAction icon={<CancelIcon />} label={"Cancel"} onClick={() => onCancel(levelLink)} />}
        </ActionButton>
    );
};

export default AdminActionButton;
