import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ApiListing from '../../../../Api/ApiListing';
import { ListingFullDTO, ListingTypeDTO } from '../../../../Api/Model';
import { selectMetadataPublic, selectRegions, selectTypes } from '../../../../Store/App/selectors';
import { listingModifiedAction, updateWizardStepAction } from '../../../../Store/Wizard/actions';
import { selectAllowEdit, selectListing } from '../../../../Store/Wizard/selectors';
import { deepEqual } from '../../../../Util/Util';
import WizardGuide from '../../WizardGuide';
import WizardHeader from '../../WizardHeader';
import WizardMain from '../../WizardMain';
import WizardSidebar from '../../WizardSidebar';
import { WizardStep } from '../../WizardSteps';
import BackForwardButtons from '../BackForwardButtons';
import { FormLocationValues } from '../form-values';
import LocationForm from './LocationForm';

export const WORD_LIMIT_ERROR_DIRECTIONS = 500;


const mapFormValues = (listing: ListingFullDTO) : FormLocationValues => {
    return {
        streetAddress: listing.details.txtAddress || "",
        location: listing.details.txtLocation || "",
        postcode: listing.details.txtPostCode.toString() || "",
        suburb: listing.details.txtSuburb || "",
        city: listing.details.txtCity || "",
        noMapSearch: listing.details.bitNoMapSearch,
        noMapProfile: listing.details.bitNoMapProfile,
        latitude: listing.details.latitude || 0,
        longitude: listing.details.longitude || 0,
        stateId: listing.details.fkState,
        regionId: listing.details.fkRegion,
        venue: listing.details.txtVenueLocation || "",
        venueIsGoogle: true
    }
}

const LocationStep = () => {
    const dispatch = useDispatch();
    let params = useParams<{ id: any; licenseId: any }>();

    const listing = useSelector(selectListing);
    const metadataPublic = useSelector(selectMetadataPublic);
    const types = useSelector(selectTypes);
    const regions = useSelector(selectRegions);
    const allowEdit = useSelector(selectAllowEdit);

    const [origValues, setOrigValues] = useState<FormLocationValues>(undefined);
    const [values, setValues] = useState<FormLocationValues>(undefined);
    const [type, setType] = useState<ListingTypeDTO>(undefined);


    const generatePromise = (): Promise<ListingFullDTO> => {
            let promise: Promise<ListingFullDTO> = undefined;
            if (!deepEqual(values, origValues)) {
                const {
                    regionId, streetAddress, postcode, suburb, city, stateId, latitude, longitude,
                    noMapProfile, noMapSearch, location, venue, venueIsGoogle
                } = values;

                const googleMapUrl = `https://www.google.com/maps/?ll=${latitude},${longitude}`
                promise = ApiListing.updateListingLocation(
                    listing.details.pkListing, regionId, streetAddress, postcode, suburb, city, stateId,
                    googleMapUrl, latitude, longitude, noMapProfile, noMapSearch, location, venue, venueIsGoogle, params.licenseId
                ).then(res => {
                    setOrigValues({...values});
                    return res;
                });
            }
            return promise;
    }

    const onWizardStepChange = (step: WizardStep) => {
        let promise = generatePromise();
        dispatch(updateWizardStepAction(step, promise));
    }

    useEffect(() => {
        if (values && origValues) {
            dispatch(listingModifiedAction(!deepEqual(values, origValues)));
        }
    }, [values, origValues, dispatch])

    useEffect(() => {
        if(listing && types && regions) {
            const v = mapFormValues(listing);
            setOrigValues(v);
            setValues({...v});
            setType(types.find((t) => t.pkListingType === listing.details.fkListingType))
        }
    }, [listing, types, regions]);

    const onSaveHook = (): Promise<any> => {
        return generatePromise();
    }

    return (<>
        <WizardHeader
            backToListingsHook={onSaveHook}
        />
        <WizardSidebar
            onSaveHook={onSaveHook}
        />
        <WizardMain>
            {values && regions && metadataPublic && type && <LocationForm
                disabled={!allowEdit}
                regions={regions}
                states={metadataPublic.states}
                values={values}
                type={type}
                onChange={setValues}
            />}

            <BackForwardButtons
                onClickPrevious={() => onWizardStepChange(WizardStep.ContactInformation)}
                onClickNext={() => onWizardStepChange(WizardStep.Description)}
            />
        </WizardMain>
        <WizardGuide step={WizardStep.Location} />
    </>

    )
};

export default LocationStep;