import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiUsers from '../../Api/ApiUsers';
import { LoginDTO } from '../../Api/Model';
import { loginSuccess } from '../../Store/User/actions';

const useStyles = makeStyles((theme) => ({
    textfield: {
    },
    loginButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        margin: '0 auto',
        display: 'block'
    },
    loadingSpinner: {

    },
    createAccountButton: {
        textTransform: 'none',
        padding: 0,
        margin: 0,
    },
    registerMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    createAccountMessage: {
        textDecoration: 'underline',
        marginLeft: theme.spacing(1)
    }
}));

interface LoginProps {
    onClickRegister: () => void;
    onClickForgotPassword: () => void;
}

const Login = (props: LoginProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState<string>(undefined);
    const [processing, setProcessing] = useState(false);

    const login = async () => {
        setProcessing(true);
        let dto: LoginDTO = undefined;
        try {
            dto = await ApiUsers.login(email, password);
        } catch (err) {
            let message = err.data || err.message || 'Server error';
            if(message === 'email-not-exist') {
                message = 'There is no User Account for this email.'
            } else if (message === 'password-incorrect') {
                message = 'This password is incorrect. You can recover your password using the link below.'
            }
            setLoginError(message);
        }
        setProcessing(false);
        if(dto) {
            dispatch(loginSuccess(dto));
        }
    }

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography align="center" color="textPrimary" paragraph>Login</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        autoComplete="email"
                        disabled={processing}
                        className={classes.textfield}
                        value={email}
                        onChange={(e) => setEmail(e.target.value as string)}
                        id="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        autoComplete="password"
                        disabled={processing}
                        className={classes.textfield}
                        value={password}
                        onChange={(e) => setPassword(e.target.value as string)}
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth

                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        loginError ?
                            <Typography align="center" color="error">{loginError}</Typography>
                            : null
                    }

                    <Button
                        disabled={processing || email.length < 1 || password.length < 1}
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.loginButton}
                        onClick={login}>
                        {processing ?
                            <CircularProgress className={classes.loadingSpinner} size={20} />
                            :
                            <Typography variant="button">Login</Typography>
                        }
                    </Button>

                    <div className={classes.registerMessage}>
                        <Typography noWrap variant="subtitle2">Not a member yet?</Typography>
                        <ButtonBase disableRipple={true} onClick={props.onClickRegister}>
                            <Typography className={classes.createAccountMessage} noWrap variant="subtitle2">
                                Create your account
                    </Typography>
                        </ButtonBase>
                    </div>

                    <div className={classes.registerMessage}>
                        <Typography noWrap variant="subtitle2">Forgot your password?</Typography>
                        <ButtonBase disableRipple={true} onClick={props.onClickForgotPassword}>
                            <Typography className={classes.createAccountMessage} noWrap variant="subtitle2">
                                Recover Password
                    </Typography>
                        </ButtonBase>
                    </div>

                </Grid>
            </Grid>
    )
};


export default Login;