import React from "react";
import { FormControlLabel, FormLabel, MenuItem, Radio, Select, SelectChangeEvent } from "@mui/material";
import Grid from "@mui/material/Grid";
import ScoopUtil from "../../../../Util/ScoopUtil";

interface Props {
    disabled: boolean;
    monthlyExactDay: number;
    monthlyInterval: number;
    occurenceDays: number;
    onChange: (monthlyExactDay: number, monthlyInterval: number, occurenceDays: number) => void;
}

const RecurringDatesMonthly = (props: Props) => {
    const { disabled, monthlyExactDay, monthlyInterval, occurenceDays, onChange } = props;

    const onMonthlyExactDayChange = (e: SelectChangeEvent<number>) => {
        let value = e.target.value as number;
        onChange(value, monthlyInterval, occurenceDays);
    };

    const onMonthlyIntervalChange = (e: SelectChangeEvent<number>) => {
        let value = e.target.value as number;
        onChange(monthlyExactDay, value, occurenceDays);
    };

    const onOccurrenceDaysChange = (e: SelectChangeEvent<number>) => {
        let value = e.target.value as number;
        onChange(monthlyExactDay, monthlyInterval, value);
    };

    return (
        <>
            <Grid item xs={12}>
                <FormLabel component="legend">Repeats</FormLabel>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={4} md={4}>
                    <FormControlLabel
                        disabled={disabled}
                        checked={monthlyExactDay > 0}
                        onClick={() => onChange(1, 0, 0)}
                        control={<Radio color="primary" />}
                        label="On exact date"
                    />
                </Grid>
                <Grid item xs={8} md={4}>
                    <Select disabled={disabled} fullWidth value={monthlyExactDay} onChange={onMonthlyExactDayChange}>
                        <MenuItem value={0}>Not selected</MenuItem>
                        {Array.from(Array(31).keys()).map((v) => (
                            <MenuItem key={v} value={v + 1}>
                                {ScoopUtil.getOrdinal(v + 1)}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        disabled={disabled}
                        checked={monthlyExactDay === 0}
                        onClick={() => onChange(0, 1, 1)}
                        control={<Radio color="primary" />}
                        label="Every"
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <Select disabled={disabled} fullWidth value={monthlyInterval} onChange={onMonthlyIntervalChange}>
                        <MenuItem value={0}>Not selected</MenuItem>
                        <MenuItem value={1}>First</MenuItem>
                        <MenuItem value={2}>Second</MenuItem>
                        <MenuItem value={4}>Third</MenuItem>
                        <MenuItem value={8}>Fourth</MenuItem>
                        <MenuItem value={16}>Last</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Select disabled={disabled} fullWidth value={occurenceDays} onChange={onOccurrenceDaysChange}>
                        <MenuItem value={0}>Not selected</MenuItem>
                        <MenuItem value={1}>Mon</MenuItem>
                        <MenuItem value={2}>Tue</MenuItem>
                        <MenuItem value={4}>Wed</MenuItem>
                        <MenuItem value={8}>Thu</MenuItem>
                        <MenuItem value={16}>Fri</MenuItem>
                        <MenuItem value={32}>Sat</MenuItem>
                        <MenuItem value={64}>Sun</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </>
    );
};

export default RecurringDatesMonthly;
