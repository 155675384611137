import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSnackMessage } from '../../Store/App/selectors';

interface Props {
}

const ScoopSnackMessage = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const snackMessage = useSelector(selectSnackMessage);

    useEffect(() => {
        if(snackMessage) {
            enqueueSnackbar(snackMessage.text, {variant: snackMessage.variant, autoHideDuration: snackMessage.autoHideDuration})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snackMessage]);
    
    return (<></>);
}

export default ScoopSnackMessage;

