import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import FormGrid from './FormGrid';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        margin: theme.spacing(2, 0, 2, 0)
    },
    nextButtonContainer: {

    },
    nextButton: {
        float: 'right',
    },
    previousButtonContainer: {

    },
    previousButton: {

    }
}));

interface BackForwardButtonsProps {
    onClickNext?: () => void;
    onClickPrevious?: () => void;
    disabled?: boolean;
}

const BackForwardButtons = (props: BackForwardButtonsProps) => {
    const classes = useStyles();

    return (
        <FormGrid>
            <Grid item container xs={12}>
                <Grid item xs={6} className={classes.previousButtonContainer}>
                    {props.onClickPrevious && <Button
                        disabled={props.disabled}
                        className={classes.previousButton}
                        color="primary"
                        variant="contained"
                        startIcon={<ArrowBackwardIcon />}
                        onClick={() => props.onClickPrevious()}>
                        Previous
                        </Button>}
                </Grid>


                {props.onClickNext &&
                    <Grid item xs={6} className={classes.nextButtonContainer}>
                        <Button
                            disabled={props.disabled}
                            className={classes.nextButton}
                            color="primary"
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => props.onClickNext()}>
                            Next
                            </Button>
                    </Grid>
                }

            </Grid >

        </FormGrid>
    )
};

export default BackForwardButtons;