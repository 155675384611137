import { MetadataPublicDTO, StateDTO } from "../Api/Model";

export interface CountryWithStates {
    id: number;
    name: string;
    states: StateDTO[];
}

export default class MetadataUtil {

    public static mergeCountriesAndStates(metadata: MetadataPublicDTO): CountryWithStates[] {
        if(!!!metadata.countries) return [];
        let countriesIds = metadata.states.map(s => s.fkCountryID).filter((v, i, a) => a.indexOf(v) === i);
        let countriesWithStates = countriesIds.map(c => {
            return {
                id: c,
                name: metadata.states.find(s => s.fkCountryID === c).countryName,
                states: metadata.states.filter(s => s.fkCountryID === c)
            }
        });
        let sortedCountries = countriesWithStates.concat(metadata.countries.filter(c => !countriesIds.includes(c.countryID)).map(c => {
            return {
                id: c.countryID,
                name: c.name,
                states: []
            }
        })).sort((a, b) => a.name.localeCompare(b.name));
        return sortedCountries;
    }
}