import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './theme';
import store from './Store/store';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import config from './config';
import Maintenance from './Maintenance';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


Sentry.init({
    denyUrls:[
        'http://localhost:3000'
    ],
    dsn: config.SENTRY,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });


ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <App />
                    {/* <Maintenance/> */}
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
