import { ListingFullDTO, PremiumPlanDTO } from '../../Api/Model';
import { WizardConfig } from '../../Components/Wizard/Wizard';
import { WizardStep } from '../../Components/Wizard/WizardSteps';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';


interface WizardState {
    wizardStep: WizardStep;
    wizardConfig: WizardConfig;

    listing?: ListingFullDTO;
    loading: boolean;
    loadingError?: Error;

    updating: boolean;

    modified: boolean;
    valid: boolean;
    uploadingImages: number;
    allowEdit: boolean;
    showActivatedDialog: boolean;

    premiumPlans: PremiumPlanDTO[];

};

const initialState: WizardState = {
    wizardStep: WizardStep.Details,
    wizardConfig: { allowEvents: false, allowOpeningHours: false},
    loading: false,
    modified: false,
    valid: false,
    updating: false,
    uploadingImages: 0,
    showActivatedDialog: false,
    allowEdit: false,
    premiumPlans: [],
};

const reducer = (state = initialState, action: Action): WizardState => {
    switch (action.type) {
        case at.WIZARD_STEP_UPDATED:
            return {
                ...state,
                wizardStep: action.payload.wizardStep
            }
        case at.WIZARD_CONFIG_UPDATED:
            return {
                ...state,
                wizardConfig: action.payload.config
            }
        case at.SHOW_ACTIVATED_DIALOG:
            return {
                ...state,
                showActivatedDialog: action.payload.showActivatedDialog
            }

        case at.FETCH_LISTING:
            return {
                ...state,
                loading: true,
                listing: undefined,
                loadingError: undefined,
                allowEdit: false,
                premiumPlans: []
            }
        case at.FETCH_LISTING_SUCCESS:
            return {
                ...state,
                loading: false,
                listing: action.payload.listing,
                valid: action.payload.valid,
                loadingError: undefined,
                modified: false,
                allowEdit: action.payload.allowEdit
            }
        case at.FETCH_LISTING_ERROR:
            return {
                ...state,
                loading: false,
                listing: undefined,
                loadingError: action.payload.error,
                allowEdit: false
            }

        case at.UPDATE_LISTING:
            return {
                ...state,
                updating: true,
            }
        case at.UPDATE_LISTING_SUCCESS:
            return {
                ...state,
                updating: false,
                listing: action.payload.listing,
                valid: action.payload.valid,
                allowEdit: action.payload.allowEdit,
                modified: false
            }
        case at.UPDATE_LISTING_ERROR:
            return {
                ...state,
                updating: false,
            }

        case at.LISTING_MODIFIED:
            return {
                ...state,
                modified: action.payload.modified,
            }

        case at.UPLOADING_IMAGES_INCREASE:
            return {
                ...state,
                uploadingImages: state.uploadingImages + 1
            }
        case at.UPLOADING_IMAGES_DECREASE:
            return {
                ...state,
                uploadingImages: state.uploadingImages > 0 ? state.uploadingImages - 1 : 0
            }
        case at.PREMIUM_PLANS_UPDATED:
            return {
                ...state,
                premiumPlans: action.payload
            }

        default:
            return state;
    }
}
export default reducer;