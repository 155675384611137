import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "../config";

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.common.white,
    },
}));

const WidgetPage = () => {
    const classes = useStyles();

    let params = useParams<{ license: any; region: any; type: any }>();

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://scoopwidget.azureedge.net/scoopwidget.v2.3.7.js";
        script.async = true;

        document.body.appendChild(script);
    });

    

    return (
        <Container className={classes.container}>
            <div id="sc-widget-target">
                <div id="sc-listings-target">
                    <div
                        data-component="SCListings"
                        data-prop-server={config.WIDGET_SERVER}
                        data-prop-license={params.license}
                        data-prop-region={params.region}
                        data-prop-type={params.type}
                        data-prop-cache="true"
                        data-prop-cache-metadata="true"
                        data-prop-widget-view="grid"
                        data-prop-widget-mode="discover"
                        data-prop-max-rows="10"
                        data-prop-max-rows-mobile="20"
                        data-prop-write-seo="false"
                        data-prop-login="false"
                        data-prop-url-write="false"
                        data-prop-url-type="simple"
                    ></div>
                </div>
            </div>
        </Container>
    );
};

export default WidgetPage;
