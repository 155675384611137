export const actionTypes = {

    DASHBOARD_FETCH_LISTINGS: 'DASHBOARD/FETCH_LISTINGS',
    DASHBOARD_FETCH_LISTINGS_SUCCESS: 'DASHBOARD/FETCH_LISTINGS_SUCCESS',
    DASHBOARD_FETCH_LISTINGS_ERROR: 'DASHBOARD/FETCH_LISTINGS_ERROR',
    DASHBOARD_LISTINGS_FILTER_UPDATED: 'DASHBOARD/LISTINGS_FILTER_UPDATED',
    DASHBOARD_LISTINGS_TABLE_SETTING_UPDATED: 'DASHBOARD/LISTINGS_TABLE_SETTING_UPDATED',

    DASHBOARD_LISTING_DELETED: 'DASHBOARD/LISTING_DELETED',
    DASHBOARD_LISTING_UPDATED: 'DASHBOARD/LISTING_UPDATED',

    DASHBOARD_FETCH_APPROVALS_LISTINGS: 'DASHBOARD/FETCH_APPROVALS_LISTINGS',
    DASHBOARD_FETCH_APPROVALS_LISTINGS_SUCCESS: 'DASHBOARD/FETCH_APPROVALS_LISTINGS_SUCCESS',
    DASHBOARD_FETCH_APPROVALS_LISTINGS_ERROR: 'DASHBOARD/FETCH_APPROVALS_LISTINGS_ERROR',
    DASHBOARD_APPROVALS_LISTING_UPDATED: 'DASHBOARD/APPROVALS_LISTING_UPDATED',
    DASHBOARD_APPROVALS_LISTING_ADDED: 'DASHBOARD/APPROVALS_LISTING_ADDED',
    DASHBOARD_APPROVALS_FILTER_UPDATED: 'DASHBOARD/APPROVALS_FILTER_UPDATED',
    DASHBOARD_APPROVALS_TABLE_SETTING_UPDATED: 'DASHBOARD/APPROVALS_TABLE_SETTING_UPDATED',

    DASHBOARD_FETCH_MY_WEBSITE_LISTINGS: 'DASHBOARD/FETCH_MY_WEBSITE_LISTINGS',
    DASHBOARD_FETCH_MY_WEBSITE_LISTINGS_SUCCESS: 'DASHBOARD/FETCH_MY_WEBSITE_LISTINGS_SUCCESS',
    DASHBOARD_FETCH_MY_WEBSITE_LISTINGS_ERROR: 'DASHBOARD/FETCH_MY_WEBSITE_LISTINGS_ERROR',
    DASHBOARD_MY_WEBSITE_LISTING_UPDATED: 'DASHBOARD/MY_WEBSITE_LISTING_UPDATED'

};