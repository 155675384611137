import { Box, Container, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { selectApprovalLicenses, selectContentLicenses, selectUser } from "../../Store/User/selectors";
import TabPanel from "../UI/TabPanel";
import MyAnalytics from "./Analytics/MyAnalytics";
import Approvals from "./Approvals/Approvals";
import MyListings from "./Listings/MyListings";
import MyDetails from "./MyDetails/MyDetails";
import MyWebsite from "./MyWebsite/MyWebsite";
import Users from "./Users/Users";

const useStyles = makeStyles((theme) => ({
    tabsContainer: {
        backgroundColor: theme.palette.common.white,
        height: "44px",
        [theme.breakpoints.up("sm")]: {
            height: "64px",
            paddingTop: "8px",
        },
    },
    tab: {
        fontSize: "0.75rem",
        fontWeight: 300,
        [theme.breakpoints.up("sm")]: {
            padding: "0px 45px",
            fontSize: "1rem",
        },
        [theme.breakpoints.up("md")]: {
            padding: "0px 60px",
        },
    },
    tabSelected: {
        color: "#000000!important",
        fontWeight: 600,
    },
}));

interface DashboardProps {}

const Dashboard = (props: DashboardProps) => {
    const history = useHistory();
    const { tab } = useParams<{ tab: string }>();
    const [selected, setSelected] = useState<string>("loading");
    const classes = useStyles();
    const theme = useTheme();
    const widthXS = useMediaQuery(theme.breakpoints.down("sm"));
    const approvalLicenses = useSelector(selectApprovalLicenses);
    const contentLicenses = useSelector(selectContentLicenses);
    const authUser = useSelector(selectUser);

    const [showApprovals, setShowApprovals] = useState(false);
    const [showMyWebsite, setShowMyWebsite] = useState(false);
    const [showUsers, setShowUsers] = useState(false);

    useEffect(() => {
        setShowUsers(authUser?.userLevel === 2);
    }, [authUser]);

    useEffect(() => {
        setSelected(tab || "");
    }, [tab]);

    useEffect(() => {
        setShowApprovals(approvalLicenses?.length > 0);
    }, [approvalLicenses]);

    useEffect(() => {
        setShowMyWebsite(contentLicenses?.length > 0);
    }, [contentLicenses]);

    const onTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        history.push(`/${newValue}`);
    };

    return (
        <>
            <Tabs
                className={classes.tabsContainer}
                centered
                value={selected}
                indicatorColor="primary"
                textColor="primary"
                onChange={onTabChange}
                variant={widthXS ? "fullWidth" : "standard"}
            >
                <Tab style={{ display: "none" }} value={"loading"} />
                <Tab
                    value={""}
                    className={`${classes.tab} ${selected === "" ? classes.tabSelected : ""}`}
                    label={"My Listings"}
                />
                {showMyWebsite && (
                    <Tab
                        value={"my-website"}
                        className={`${classes.tab} ${selected === "my-website" ? classes.tabSelected : ""}`}
                        label={"My Website"}
                    />
                )}
                {showApprovals && (
                    <Tab
                        value={"approvals"}
                        className={`${classes.tab} ${selected === "approvals" ? classes.tabSelected : ""}`}
                        label={"Approvals"}
                    />
                )}
                {showUsers && (
                    <Tab
                        value={"users"}
                        className={`${classes.tab} ${selected === "users" ? classes.tabSelected : ""}`}
                        label={"Users"}
                    />
                )}
                {showUsers && (
                    <Tab
                        value={"analytics"}
                        className={`${classes.tab} ${selected === "analytics" ? classes.tabSelected : ""}`}
                        label={"Analytics"}
                    />
                )}
                {/* {showUsers && (
                    <Tab
                        value={"network-analytics"}
                        className={`${classes.tab} ${selected === "network-analytics" ? classes.tabSelected : ""}`}
                        label={"Network Analytics"}
                    />
                )} */}
                <Tab
                    value={"my-details"}
                    className={`${classes.tab} ${selected === "my-details" ? classes.tabSelected : ""}`}
                    label={"My Details"}
                />
            </Tabs>
            <Container>
                <Box>
                    <TabPanel style={{ display: "none" }}  value={selected} index={"loading"}>
                        <MyListings />
                    </TabPanel>
                    <TabPanel value={selected} index={""}>
                        <MyListings />
                    </TabPanel>
                    {showMyWebsite && (
                        <TabPanel value={selected} index={"my-website"}>
                            <MyWebsite />
                        </TabPanel>
                    )}
                    {showApprovals && (
                        <TabPanel value={selected} index={"approvals"}>
                            <Approvals />
                        </TabPanel>
                    )}
                    <TabPanel value={selected} index={"my-details"}>
                        <MyDetails />
                    </TabPanel>
                    {showUsers && (
                        <TabPanel value={selected} index={"users"}>
                            <Users />
                        </TabPanel>
                    )}
                    {showUsers && (
                        <TabPanel value={selected} index={"analytics"}>
                            <MyAnalytics />
                        </TabPanel>
                    )}
{/* 
                    {showUsers && (
                        <TabPanel value={selected} index={"network-analytics"}>
                            <NetworkAnalytics />
                        </TabPanel>
                    )} */}

                </Box>
            </Container>
        </>
    );
};

export default Dashboard;
