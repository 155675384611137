import { getValidToken } from "../Store/LocalStorage/UserStorage";
import Api from "./Api";
import { AnalyticsDTO } from "./Model";

export default class ApiAnalytics extends Api {

    public static getListingsAnalytics(listingId: number, licenseId?: number): Promise<AnalyticsDTO> {
        const token = getValidToken();
        if (token) {
            const params = {
                listingId,
                licenseId
            };
            return this.axios
                .get(`/admin/analytics`, { headers: { Authorization: "Bearer " + token }, params: params })
                .then((res: any) => {
                    return res.data;
                });
        } else {
            return Promise.reject("Not logged in");
        }
    }

}
