import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiUsers from "../../../Api/ApiUsers";
import { UserDTO } from "../../../Api/Model";
import { actionUserSelected, actionUserUpdated } from "../../../Store/Users/actions";
import ActionButton, { QuickAction } from "../../UI/ActionButton";
import SendEmailToUserDialog from "./SendEmailToUserDialog";


interface Props {
    user: UserDTO;
    opened?: number;
    onOpen: (userId?: number) => void;
}

const UsersActionButton = (props: Props) => {
    const { user } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const open = props.opened === user.id;

    // const [deleteOpen, setDeleteOpen] = useState(false);
    const [emailOpen, setEmailOpen] = useState(false);

    const onEdit = () => {
        dispatch(actionUserSelected(user));
        props.onOpen(undefined);
    };

    const toggle = () => {
        props.onOpen(open ? undefined : user.id);
    };

    const onActiveChange = async () => {
        try {
            let updated = await ApiUsers.updateUserStatusByAdmin(user.id, !user.active);
            dispatch(actionUserUpdated(updated));
            props.onOpen(undefined);
        } catch (err) {
            //todo
        }
    };

    const onAddListing = () => {
        history.push(`/wizard?userId=${user.id}`);
    }

    const onEmailMessageClick = () => {
        setEmailOpen(true);
        props.onOpen(undefined);
    }

    return (
        <>
            <ActionButton open={open} onToggle={toggle}>
                <QuickAction
                    label={user.active ? "Make inactive" : "Make active"}
                    icon={user.active ? <CloseIcon /> : <CheckIcon />}
                    onClick={onActiveChange}
                />
                <QuickAction icon={<EditIcon />} label={"Add listing"} onClick={onAddListing} />
                <QuickAction icon={<EditIcon />} label={"Edit user"} onClick={onEdit} />
                {/* <QuickAction icon={<DeleteIcon />} label={"Delete user"} onClick={() => setDeleteOpen(true)} /> */}
                <QuickAction icon={<EmailIcon />} label={"Email user"} onClick={onEmailMessageClick} />
            </ActionButton>

            {/* {deleteOpen && <DeleteUserDialog user={user} onClose={() => setDeleteOpen(false)} />} */}
            {emailOpen && <SendEmailToUserDialog user={user} onClose={() => setEmailOpen(false)}/>}
        </>
    );
};

export default UsersActionButton;
