import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import ScoopUtil from "../../../../Util/ScoopUtil";
import { FormDescriptionValues } from "../form-values";
import FormGrid from "../FormGrid";
import SectionGrid from "../SectionGrid";
import {
    CHARACTERS_LIMIT_ERROR_ATTRIBUTION,
    CHARACTERS_LIMIT_ERROR_TEASER,
    WORD_LIMIT_ERROR_DESCRIPTION,
    WORD_LIMIT_WARNING_DESCRIPTION,
} from "./DescriptionStep";

const emojiRegex = require("emoji-regex");
const regex = emojiRegex();

interface Props {
    disabled: boolean;
    values: FormDescriptionValues;
    onChange: (values: FormDescriptionValues) => void;
    onFormValidChange: (valid: boolean) => void;
    isAdmin: boolean;
}

const DescriptionForm = (props: Props) => {
    const { isAdmin, disabled, values, onChange, onFormValidChange } = props;
    const { teaser, description, attribution } = values;

    const [teaserError, setTeaserError] = useState(false);
    const [teaserHelp, setTeaserHelp] = useState<string>("");
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionWarning, setDescriptionWarning] = useState(false);
    const [descriptionHelp, setDescriptionHelp] = useState<string>("");
    const [attributionError, setAttributionError] = useState(false);
    const [attributionHelp, setAttributionHelp] = useState<string>("");

    useEffect(() => {
        if (teaser.length < 1) {
            setTeaserError(true);
            setTeaserHelp("Teaser is required");
        } else if (teaser.length > CHARACTERS_LIMIT_ERROR_TEASER) {
            setTeaserError(true);
            setTeaserHelp(`${teaser.length - CHARACTERS_LIMIT_ERROR_TEASER} characters over the limit`);
        } else {
            setTeaserError(false);
            setTeaserHelp(`${CHARACTERS_LIMIT_ERROR_TEASER - teaser.length} characters left`);
        }
        if (teaser.length > 0) {
            if (teaser.match(regex)) {
                setTeaserError(true);
                setTeaserHelp(`Teaser can not include emojis characters`);
            } else if(ScoopUtil.isHtmlText(teaser)) {
                setTeaserError(true);
                setTeaserHelp(`Teaser can not include html`);
            }
        }
    }, [teaser]);

    useEffect(() => {
        var wordCount = ScoopUtil.wordCount(description);
        if (description.length === 0) {
            setDescriptionWarning(false);
            setDescriptionError(false);
            setDescriptionHelp(`${WORD_LIMIT_WARNING_DESCRIPTION} words left`);
        } else if (wordCount > WORD_LIMIT_WARNING_DESCRIPTION && wordCount <= WORD_LIMIT_ERROR_DESCRIPTION) {
            setDescriptionError(false);
            setDescriptionWarning(true);
            setDescriptionHelp(`${wordCount - WORD_LIMIT_WARNING_DESCRIPTION} words over the recommended limit`);
        } else if (wordCount > WORD_LIMIT_ERROR_DESCRIPTION) {
            setDescriptionWarning(true);
            setDescriptionError(true);
            setDescriptionHelp(`${wordCount - WORD_LIMIT_ERROR_DESCRIPTION} words over the allowed limit`);
        } else {
            setDescriptionWarning(false);
            setDescriptionError(false);
            setDescriptionHelp(`${WORD_LIMIT_WARNING_DESCRIPTION - wordCount} words left`);
        }
        if(description.length > 0) {
            if (description.match(regex)) {
                setDescriptionError(true);
                setDescriptionHelp(`Description can not include emojis characters`);
            } else if(ScoopUtil.isHtmlText(description)) {
                setDescriptionError(true);
                setDescriptionHelp(`Description can not include html`);
            }
        }
    }, [description]);

    useEffect(() => {
        if (attribution.length > CHARACTERS_LIMIT_ERROR_ATTRIBUTION) {
            setAttributionError(true);
            setAttributionHelp(`${attribution.length - CHARACTERS_LIMIT_ERROR_ATTRIBUTION} characters over the limit`);
        } else {
            setAttributionError(false);
            setAttributionHelp(`${CHARACTERS_LIMIT_ERROR_ATTRIBUTION - attribution.length} characters left`);
        }
    }, [attribution]);

    useEffect(() => {
        onFormValidChange(!teaserError && !descriptionError && !attributionError);
    }, [attributionError, descriptionError, onFormValidChange, teaserError]);

    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const copy = { ...values };
        copy[e.target.id] = e.target.value;
        onChange(copy);
    };

    return (
        <FormGrid>
            <SectionGrid title={"Descriptions and Teasers"}>
                <Grid item container xs={12}>
                    <TextField
                        disabled={disabled}
                        name="teaser"
                        id="teaser"
                        label="Teaser / Overview"
                        placeholder="A short attention grabbing description (around 30 words)"
                        multiline
                        rows={3}
                        value={teaser}
                        onChange={onTextFieldChange}
                        variant="outlined"
                        fullWidth
                        helperText={teaserHelp}
                        error={teaserError}
                    />
                </Grid>

                <Grid item container xs={12}>
                    <TextField
                        disabled={disabled}
                        name="description"
                        id="description"
                        label="Detailed Description"
                        placeholder="A detailed description that appears on the full-page profile (around 200 words)"
                        multiline
                        rows={8}
                        value={description}
                        onChange={onTextFieldChange}
                        variant="outlined"
                        fullWidth
                        helperText={descriptionHelp}
                        error={descriptionError || descriptionWarning}
                    />
                </Grid>

                {isAdmin && <Grid item container xs={12}>
                    <TextField
                        disabled={disabled}
                        name="attribution"
                        id="attribution"
                        label="Attribution"
                        placeholder="This field is only required when the original source of content needs to be referenced"
                        value={attribution}
                        multiline
                        rows={3}
                        onChange={onTextFieldChange}
                        variant="outlined"
                        fullWidth
                        helperText={attributionHelp}
                        error={attributionError}
                    />
                </Grid>}
            </SectionGrid>
        </FormGrid>
    );
};

export default DescriptionForm;
